import { useState } from "react";
import { styled, useTheme } from "styled-components";
import convertBytesToSize from "../../../../utils/convertBytesToSize.js";

import { Button } from "@mui/material";

// Icons
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
// import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import RequestService from "../../../../api/requests/index.js";
import { useAuth } from "../../../../contexts/AuthContext.js";
import { nanoid } from "nanoid";
import DeleteDocumentModal from "../../Modals/DeleteDocumentModal.js";
import { useRequest } from "../RequestView.js";

const MenuBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
`;

const ActionMenu = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: auto;
  width: 100%;
`;
const ActionMenuItem = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  border-radius: 5px;
  font-size: 10px;
  color: ${(props) =>
    props.color === "primary"
      ? props.theme.palette.primary.main
      : props.color === "error"
      ? props.theme.palette.error.main
      : props.theme.palette.text.primary};
  &:hover {
    text-decoration: underline;
  }
  & svg {
    font-size: 12px;
    margin-right: 5px;
  }
`;
const ActionMenuLabel = styled.div``;

const ProgressOuter = styled.div`
  width: 100%;
  height: 5px;
  background-color: ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const ProgressInner = styled.div`
  width: ${({ progress }) => progress}%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 5px;
`;

const ProgressBar = styled(({ className, progress }) => {
  return (
    <div className={className}>
      <ProgressOuter>
        <ProgressInner progress={progress}></ProgressInner>
      </ProgressOuter>
    </div>
  );
})`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const Documents = styled.div`
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  margin-top: 10px;
`;

const DocumentsContent = styled(({ className, details }) => {
  const { requestID, tenantSlug } = useParams();
  const { isEditable } = useRequest();
  const { currentUser } = useAuth();
  const [uploadingFiles, setUploadingFiles] = useState([]); // [{item_id, progress}]
  const queryClient = useQueryClient();

  const formTenant = currentUser.tenants.find(
    (tenant) => tenant.tenant_slug === tenantSlug
  );

  const { data: files } = useQuery({
    queryKey: ["request:files", { request_id: requestID }],
    queryFn: () => RequestService.getDocuments({ request_id: requestID }),
    select: (data) => {
      if (data?.data) {
        let newData = [...data.data];
        uploadingFiles.forEach((file) => {
          const index = newData.findIndex(
            (item) => item.item_id === file.item_id
          );
          if (index === -1) {
            newData.push(file);
          }
        });
        return newData;
      } else {
        return [];
      }
    },
  });

  const onFileUpload = async (file) => {
    queryClient.refetchQueries(["request:files", { request_id: requestID }]);
  };

  const handleFileUpload = async (event) => {
    return new Promise((resolve, reject) => {
      const file = event.target.files[0];
      const fileID = nanoid(20);

      const newFile = {
        item_id: fileID,
        size: file.size,
        filename: file.name,
        type: file.type,
        ext: file.name.split(".").pop().toLowerCase(),
        client_modified: new Date(file.lastModified).toISOString(),
        converted_size: convertBytesToSize(file.size),
      };

      queryClient.setQueryData(
        ["request:files", { request_id: requestID }],
        (oldData) => {
          const newData = Object.assign({}, oldData);
          newData.data.push({ ...newFile, uploading: true });
          return newData;
        }
      );

      setUploadingFiles((oldData) => {
        oldData.push({ ...newFile, uploading: true });
        return oldData;
      });

      //Get File Data from reader
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const fileData = reader.result.split(",")[1]; // get base64 data from reader
        RequestService.uploadDocument(
          {
            tenant: formTenant.tenant_uuid,
            request_id: requestID,
            filePath: `${requestID}/${file.name}`,
            ...newFile,
            fileData,
          },
          {
            onUploadProgress: (progressEvent) => {
              const progressPct = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );

              queryClient.setQueryData(
                ["request:files", { request_id: requestID }],
                (oldData) => {
                  const newData = Object.assign({}, oldData);
                  const index = newData.data.findIndex(
                    (item) => item.item_id === fileID
                  );
                  newData.data[index].progress = progressPct;
                  return newData;
                }
              );
            },
          }
        )
          .catch((err) => {
            console.log(err);
            reject(err);
          })
          .then((res) => {
            const newFile = {
              // ...res,
              link: res.url,
              item_id: fileID,
              size: file.size,
              filename: file.name,
              type: file.type,
              ext: file.name.split(".").pop().toLowerCase(),
              client_modified: new Date(file.lastModified).toISOString(),
              converted_size: convertBytesToSize(file.size),
              md5: res.md5,
              sha1: res.sha1,
              sha256: res.sha256,
            };
            onFileUpload(newFile);
            resolve(newFile);
          });
      };
    });
  };

  return (
    <div className={className}>
      <MenuBar>
        {isEditable && (
          <>
            <input
              accept="*"
              style={{ display: "none" }}
              id="add-docs-button"
              multiple={false}
              type="file"
              onChange={handleFileUpload}
            />
            <label htmlFor="add-docs-button">
              <Button
                variant="contained"
                color="primary"
                size="small"
                component="span"
                style={{
                  minWidth: "fit-content",
                  fontSize: 11,
                  padding: "3px 6px",
                }}
              >
                + Add Documents
              </Button>
            </label>
          </>
        )}
      </MenuBar>
      {files && (
        <Documents>
          {files.map((file) => (
            <DocumentItem item={file} />
          ))}
          {files.length === 0 && (
            <div style={{ textAlign: "center", width: "100%" }}>
              No documents uploaded
            </div>
          )}
        </Documents>
      )}
    </div>
  );
})`
  max-width: 900px;
  margin: auto;
`;

const DocumentItem = styled(({ className, item }) => {
  const { requestID } = useParams();
  const { isEditable } = useRequest();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onDelete = () => {
    RequestService.deleteDocument({
      request_id: requestID,
      item_id: item.item_id,
    }).then(() => {
      queryClient.refetchQueries(["request:files", { request_id: requestID }]);
    });
  };

  return (
    <div className={className}>
      <div className="doc-name" title={item.filename}>
        {item.filename}
      </div>
      <div
        style={{
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <div className="doc-type">
          {item.ext.replace(".", "").toUpperCase()}
        </div>
        <div style={{ margin: "0px 5px" }}>-</div>
        <div className="doc-size">{convertBytesToSize(item.size)}</div>
      </div>
      {item.uploading === true ? (
        <ActionMenu>
          <ProgressBar progress={item.progress} />
        </ActionMenu>
      ) : (
        <ActionMenu>
          <a href={item.link} download={item.filename}>
            <ActionMenuItem>
              <FileDownloadOutlinedIcon
                style={{ color: theme.palette.primary.main }}
              />
              <ActionMenuLabel>Download</ActionMenuLabel>
            </ActionMenuItem>
          </a>
          {/* <ActionMenuItem onClick={() => setShowDeleteModal(true)}>
            <VisibilityOutlinedIcon
              style={{ color: theme.palette.primary.main }}
            />
            <ActionMenuLabel>View</ActionMenuLabel>
          </ActionMenuItem> */}
          {isEditable && (
            <ActionMenuItem onClick={() => setShowDeleteModal(true)}>
              <DeleteOutlineOutlinedIcon
                style={{ color: theme.palette.error.main }}
              />
              <ActionMenuLabel>Delete</ActionMenuLabel>
            </ActionMenuItem>
          )}
        </ActionMenu>
      )}
      <DeleteDocumentModal
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        onSubmit={() => onDelete()}
        defaultFormData={item}
      />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  padding: 15px;
  min-width: 275px;
  max-width: 300px;
  height: 135px;

  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  & .doc-name {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
    overflow: hidden;
    max-width: 100%;

    // Add 2 line clamp
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    word-break: break-all;

    text-align: center;
  }

  & .doc-type {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & .doc-size {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & .doc-link {
    font-size: 14px;
    margin: auto;
    margin-top: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: fit-content;
    color: ${({ theme }) => theme.palette.primary.main};
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

export default DocumentsContent;
