import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { AuthService } from "../../api/auth/index.js";
import { useAuth } from "../../contexts/AuthContext";
import { Button, CssBaseline, useTheme } from "@mui/material";
import logo from "../../monolith_logo.png"; // Tell Webpack this JS file uses this image
import GlobalStyle from "../../components/GlobalStyle.js";
import { useQuery } from "@tanstack/react-query";
import TaskButton from "../../components/UI/TaskButton.js";
import styled from "styled-components";
import { httpPost } from "../../api/connection.js";

const Login = styled(({ className }) => {
  const theme = useTheme();
  const [currentView, setCurrentView] = useState(0);
  const [ssoLogin, setSsoLogin] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  // Get Search params
  const emailInputeRef = useRef();
  const loginForm = useRef(null);
  const resetForm = useRef(null);

  const { login, setSessionData } = useAuth();

  const navigate = useNavigate();

  const handleLogin = useCallback(async () => {
    if (loginForm.current.instance.validate().isValid) {
      const { email, password } = {
        ...loginForm.current.instance.option().formData,
      };

      // Check sso and redirect if not on premises
      const domain = email.split("@")[1];

      // const { sso_url } = await httpPost("/auth/sso", { domain, app: "relay" });
      const { sso_url } = await fetch("/api/auth/sso", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ domain, app: "relay" }),
      }).then((res) => res.json());

      if (sso_url) {
        window.location.href = sso_url;
        return;
      }

      // Otherwise, login normally
      login(email, password).then((result) => {
        if (!result.success) {
          setLoginError(true);
          loginForm.current &&
            loginForm.current.instance
              .getEditor("email")
              .option("isValid", false);
          loginForm.current &&
            loginForm.current.instance
              .getEditor("password")
              .option("isValid", false);
        } else {
          const searchParams = new URLSearchParams(window.location.search);
          setLoginError(false);
          setSessionData(result.user);

          // Redirect to the page the user was trying to access
          const redirect = searchParams.get("redirect");
          if (redirect) {
            navigate(redirect);
          } else navigate("/");
        }
      });
    }
  }, []);

  const handleReset = () => {
    if (resetForm.current.instance.validate().isValid) {
      const { email } = {
        ...resetForm.current.instance.option().formData,
      };

      AuthService.resetPasswordRequest({ email: email });
      setRequestSent(true);
    }
  };

  const handleSignUp = () => {
    const searchParams = new URLSearchParams(window.location.search);
    // check if redirect query is in url
    const redirect = searchParams.get("redirect");
    if (redirect) {
      navigate(
        "/signup?redirect=" + redirect + "&slug=" + redirect.replace("/", "")
      );
    } else navigate("/signup");
  };

  const checkEmailForSSO = useCallback(async (e) => {
    const domain = e?.target?.value?.split("@")[1];
    //check if input is an email address
    if (domain && domain.includes(".")) {
      // const { sso_url } = await httpPost("/auth/sso", { domain });
      const { sso_url } = await fetch("/api/auth/sso", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ domain, app: "relay" }),
      }).then((res) => res.json());

      if (sso_url) {
        setSsoLogin(true);
      } else setSsoLogin(false);
    } else {
      if (ssoLogin) setSsoLogin(false);
    }
  }, []);

  const { isLoading, data: sessionResponse } = useQuery({
    queryKey: ["check-session"],
    queryFn: () => AuthService.checkSession(),
  });

  useEffect(() => {
    //login when enter is pressed
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.target.blur();
        handleLogin();
      }
    };

    document.addEventListener("keydown", handleKeyDown, false);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, []);

  if (isLoading) return <></>;

  if (sessionResponse?.userInfo?.email) {
    navigate("/");
    return null;
  }

  return (
    <div className={className}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "calc(100vh - 200px)",
          alignItems: "center",
        }}
      >
        <CssBaseline />
        <GlobalStyle />
        <div className="login-container">
          <div>
            {currentView === 0 && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 25,
                    marginTop: 25,
                    textAlign: "center",
                  }}
                >
                  <div>
                    {!ssoLogin ? (
                      <h1>Login to Relay</h1>
                    ) : (
                      <h1>Single Sign-on Enabled</h1>
                    )}
                    <div className="message">
                      Access Relay to create and manage requests for digital
                      evidence.
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: loginError ? "flex" : "none",
                    justifyContent: "center",
                    color: "red",
                  }}
                >
                  Incorrect email/password
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px 0px",
                  }}
                >
                  <Form colCount={1} ref={loginForm} width="100%">
                    <SimpleItem
                      dataField="email"
                      label={{ text: "Email", visible: false }}
                      editorOptions={{
                        mode: "email",
                        placeholder: "Email",
                        onInitialized: (e) =>
                          (emailInputeRef.current = e.component),
                        onInput: (e) => checkEmailForSSO(e.event),
                      }}
                      validationRules={[
                        {
                          type: "required",
                          message: "Email is required",
                        },
                        // {
                        //   type: "email",
                        //   message: "Email is invalid",
                        // },
                      ]}
                    />
                    <SimpleItem
                      dataField="password"
                      label={{ text: "Password", visible: false }}
                      editorOptions={{
                        mode: "password",
                        placeholder: "Password",
                      }}
                      visible={!ssoLogin}
                      validationRules={[
                        {
                          type: "required",
                          message: "Password is required",
                        },
                      ]}
                    />
                  </Form>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px 0px",
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    fullWidth
                    style={{ marginTop: 20 }}
                    onClick={handleLogin}
                  >
                    Log In
                  </Button>
                </div>
                {!ssoLogin && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "20px 0px",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <TaskButton
                      style={{ marginTop: 10, marginBottom: 5, padding: 8 }}
                      onClick={handleSignUp}
                    >
                      Sign up for a Relay account
                    </TaskButton>
                    <TaskButton
                      style={{ marginTop: 10, marginBottom: 10, padding: 8 }}
                      onClick={(e) => {
                        setCurrentView(1);
                      }}
                    >
                      Forgot Your Password?
                    </TaskButton>
                  </div>
                )}
              </>
            )}
            {currentView === 1 && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: theme.palette.text.secondary,
                    fontSize: "large",
                    marginTop: 25,
                    marginBottom: 25,
                  }}
                >
                  Reset Your Password
                </div>
                <div
                  style={{
                    display: requestSent ? "flex" : "none",
                    justifyContent: "center",
                    // color: "limegreen",
                    margin: "20px 0px",
                    textAlign: "center",
                    color: theme.palette.primary.main,
                  }}
                >
                  An email with password reset instructions is on the way.
                </div>
                {!requestSent && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "20px 0px",
                      }}
                    >
                      <Form colCount={1} ref={resetForm} width="100%">
                        <SimpleItem
                          dataField="email"
                          label={{ text: "Email", visible: false }}
                          isRequired={true}
                          editorOptions={{
                            mode: "email",
                            placeholder: "Email",
                          }}
                        />
                      </Form>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "20px 0px",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        fullWidth
                        style={{
                          marginTop: 20,
                        }}
                        onClick={handleReset}
                      >
                        Reset Password
                      </Button>
                    </div>
                  </>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px 0px",
                  }}
                >
                  <TaskButton
                    style={{ marginTop: 10, marginBottom: 10, padding: 8 }}
                    onClick={(e) => {
                      setCurrentView(0);
                    }}
                  >
                    Back to Login
                  </TaskButton>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: 50,
            marginBottom: 25,
            fontSize: "small",
            position: "absolute",
            bottom: 0,
          }}
        >
          <div
            style={{
              textAlign: "center",
              color: theme.palette.text.secondary,
              marginBottom: 10,
            }}
          >
            Powered By
          </div>
          <a
            href="https://monolithforensics.com"
            target="_blank"
            rel="noreferrer"
          >
            <img src={logo} height="65" alt="Monolith Forensics Logo"></img>
          </a>
        </div>
      </div>
    </div>
  );
})`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.palette.background.default};

  .login-container {
    width: 500px;
    padding: 20px 60px;
    height: auto;
    border-radius: 5px;
    background-color: ${(props) => props.theme.palette.background.alt};
    border: 1px solid ${(props) => props.theme.palette.divider};
    box-shadow: none !important;
  }

  .message {
    margin-bottom: 10px;
    color: ${(props) => props.theme.palette.text.secondary};
    font-size: 0.85rem;
  }
`;

export default Login;
