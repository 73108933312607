import styled from "styled-components";
import { useAuth } from "../../contexts/AuthContext.js";
import { Outlet, useParams } from "react-router-dom";

import { useEffect } from "react";
import LoggingAPI from "../../api/logging/index.js";

const CaseDrives = styled(({ className }) => {
  const { case_uuid, tenantSlug } = useParams();
  const { currentUser } = useAuth();

  const monolith_tenant = currentUser.tenants.find(
    (tenant) => tenant.tenant_slug === tenantSlug
  )?.monolith_tenant;

  useEffect(() => {
    if (case_uuid) {
      LoggingAPI.createLog({
        case_uuid,
        message: `Opened case drive in Relay`,
        event: "case_drive:access",
        object_type: "case_drive",
        object_uuid: case_uuid,
        monolith_tenant,
      });
    }
  }, [case_uuid, monolith_tenant]);

  return (
    <div className={className}>
      <Outlet />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
  height: 0px;
`;

export default CaseDrives;
