// make deep copy of objects and remove unique keys for copy functions
export const prepareCopyData = (item) => {
  let copy = JSON.parse(JSON.stringify(item));
  delete copy.item_id;
  delete copy.uuid;
  delete copy.request_id;

  // prepare custom fields for edit form
  if (item.custom_fields) {
    item.custom_fields.forEach((field) => {
      item[`custom_field_${field.field_id}`] = field.value;
    });
  }
  return copy;
};
