import styled from "styled-components";
import RequestService from "../../../api/requests/index.js";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet-async";
import TaskButton from "../../../Monolith-UI/TaskButton.js";
import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";
import RequestOverview from "./RequestOverview.js";
import BreadCrumbs from "../../../components/BreadCrumbs/index.js";
import { createContext, useContext } from "react";

const RequestContext = createContext();

const RequestProvider = ({ children, details }) => {
  return (
    <RequestContext.Provider
      value={{
        details,
        isEditable: !details?.case_info, // editable before associated with a case
      }}
    >
      {children}
    </RequestContext.Provider>
  );
};

export const useRequest = () => {
  const context = useContext(RequestContext);
  if (context === undefined) {
    throw new Error("useRequest must be used within a RequestProvider");
  }
  return context;
};

const PageHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
`;

const PageTitle = styled.div`
  margin: 0;
`;

const Title = styled.h2`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: baseline;
  gap: 10px;
`;

const SubTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const PageContent = styled.div``;

const RequestView = styled(({ className }) => {
  const { requestID, tenantSlug } = useParams();

  const { data: requestInfo } = useQuery({
    queryKey: ["request", { request_id: requestID, data_only: true }],
    queryFn: () =>
      RequestService.getRequests({ request_id: requestID, data_only: true }),
    select: (data) => data[0],
    refetchOnWindowFocus: false,
  });

  if (!requestInfo) return null;

  return (
    <RequestProvider details={requestInfo}>
      <div className={className}>
        <Helmet>
          <title>Request Details | {String(requestInfo.request_name)}</title>
        </Helmet>
        <PageContent>
          <PageHeader>
            <Link to={-1}>
              <TaskButton title={"Back to Requests"} variant="outlined">
                <KeyboardReturnOutlinedIcon style={{ fontSize: 20 }} />
              </TaskButton>
            </Link>
            <PageTitle>
              <Title>
                Request Details
                <SubTitle>
                  {requestInfo.request_name
                    ? `${requestInfo.request_name}`
                    : ""}
                </SubTitle>
              </Title>
              <SubTitle>ID {requestInfo.uuid}</SubTitle>
            </PageTitle>
          </PageHeader>
          <div style={{ margin: "15px 0px" }}>
            <BreadCrumbs
              list={[
                {
                  name: "My Requests",
                  path: "/my-requests",
                },
                {
                  name: `${requestInfo.request_name || requestID}`,
                  path: `/requests/${tenantSlug}/${requestID}`,
                },
              ]}
            />
          </div>
          <RequestOverview details={requestInfo} />
        </PageContent>
      </div>
    </RequestProvider>
  );
})``;

export default RequestView;
