import { Outlet } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import GlobalStyle from "../components/GlobalStyle";

const Root = () => {
  return (
    <div
      style={{
        maxWidth: "520px",
        margin: "0 auto",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        minHeight: "100%",
        flexDirection: "column",
      }}
    ></div>
  );
};

const AuthLayout = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
      <Outlet />
    </Root>
  );
};

export default AuthLayout;
