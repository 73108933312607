import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  CalendarContainer,
  CalendarHeader,
  CalendarGrid,
  CalendarMonth,
  CalendarDay,
  CalendarDate,
  HighlightedCalendarDate,
  TodayCalendarDate,
  TimeContainer,
  MainContainer,
  TimePickerContainer,
  TimeHourSelect,
  TimeHeader,
  TimeMinuteSelect,
  TimeItem,
  TimeItemActive,
  NoValueButton,
} from "./CalendarStyles";
import calendar, {
  isDate,
  isSameDay,
  isSameMonth,
  getDateISO,
  getNextMonth,
  getPreviousMonth,
  WEEK_DAYS,
  CALENDAR_MONTHS,
  HOURS24,
  HOURS12,
  MINUTES,
} from "./calendarHelpers";
import moment from "moment";

export default function Calendar({
  date = new Date(),
  onDateChanged = () => {},
  hourFormat = "24",
  includeTime = true,
}) {
  const [dateState, setDateState] = useState({
    current: new Date(),
    month: 0,
    year: 0,
    hours: 0,
    minutes: 0,
  });
  const [today, setToday] = useState(new Date());
  let pressureTimer, pressureTimeout, dayTimeout;

  useEffect(() => {
    addDateToState(date);
  }, []);

  const addDateToState = (date) => {
    const isDateObject = isDate(date);
    const _date = isDateObject ? date : new Date();
    setDateState({
      current: isDateObject ? date : null,
      month: +_date.getMonth() + 1,
      year: _date.getFullYear(),
      hours: _date.getHours(),
      minutes: _date.getMinutes(),
    });
  };

  const getCalendarDates = () => {
    const { current, month, year } = dateState;
    const calendarMonth = month || +current?.getMonth() + 1;
    const calendarYear = year || current?.getFullYear();
    return calendar(calendarMonth, calendarYear);
  };

  //new start

  const renderMonthAndYear = () => {
    const { month, year } = dateState;

    // Resolve the month name from the CALENDAR_MONTHS object map
    const monthname =
      Object.keys(CALENDAR_MONTHS)[Math.max(0, Math.min(month - 1, 11))];
    return (
      <CalendarHeader>
        <ArrowLeftIcon
          onMouseDown={handlePrevious}
          onMouseUp={clearPressureTimer}
          title="Previous Month"
        />

        <CalendarMonth>
          {monthname} {year}
        </CalendarMonth>

        <ArrowRightIcon
          onMouseDown={handleNext}
          onMouseUp={clearPressureTimer}
          title="Next Month"
        />
      </CalendarHeader>
    );
  };
  // Render the label for day of the week
  // This method is used as a map callback as seen in render()
  const renderDayLabel = (day, index) => {
    // Resolve the day of the week label from the WEEK_DAYS object map
    const daylabel = WEEK_DAYS[day];

    return (
      <CalendarDay key={daylabel} index={index}>
        {daylabel}
      </CalendarDay>
    );
  };
  // Render a calendar date as returned from the calendar builder function
  // This method is used as a map callback as seen in render()
  const renderCalendarDate = (date, index) => {
    const { current, month, year } = dateState;
    const _date = new Date(date.join("-"));
    // Check if calendar date is same day as today
    const isToday = isSameDay(_date, today);

    // Check if calendar date is same day as currently selected date
    const isCurrent = current && isSameDay(_date, current);

    // Check if calendar date is in the same month as the state month and year
    const inMonth =
      month && year && isSameMonth(_date, new Date([year, month, 1].join("-")));
    // The click handler
    const onClick = gotoDate(_date);
    const props = { index, inMonth, onClick, title: _date.toDateString() };
    // Conditionally render a styled date component
    const DateComponent = isCurrent
      ? HighlightedCalendarDate
      : isToday
      ? TodayCalendarDate
      : CalendarDate;
    return (
      <DateComponent key={getDateISO(_date)} {...props}>
        {_date.getDate()}
      </DateComponent>
    );
  };

  const renderHours = () => {
    const { hours } = dateState;
    const hoursArray = hourFormat === "24" ? HOURS24 : HOURS12;
    return hoursArray.map((hour, index) => {
      const HourComponent =
        parseInt(hour.value) === hours ? TimeItemActive : TimeItem;
      return (
        <HourComponent
          key={hour.value}
          value={hour.value}
          onClick={(e) => {
            const newTime = moment(dateState.current)
              .hours(hour.value)
              .toDate();
            setDateState({
              ...dateState,
              hours: parseInt(hour.value),
              current: newTime,
            });
            onDateChanged(newTime);
          }}
        >
          {/* {`${hour.label}${
            hourFormat === "24" ? "" : index < 11 ? " AM" : " PM"
          }`} */}
          {hour.label}
        </HourComponent>
      );
    });
  };

  const renderMinutes = () => {
    const { minutes } = dateState;
    return MINUTES.map((minute) => {
      const MinuteComponent =
        parseInt(minute) === minutes ? TimeItemActive : TimeItem;
      return (
        <MinuteComponent
          key={minute}
          value={minute}
          onClick={(e) => {
            const newTime = moment(dateState.current).minutes(minute).toDate();
            setDateState({
              ...dateState,
              minutes: parseInt(minute),
              current: newTime,
            });
            onDateChanged(newTime);
          }}
        >
          {minute}
        </MinuteComponent>
      );
    });
  };

  // new 2
  const gotoDate = (date) => (evt) => {
    evt && evt.preventDefault();
    const { current } = dateState;

    // Set Hours and Minutes
    const newTime = moment(date)
      .hours(includeTime ? dateState.hours : 0)
      .minutes(includeTime ? dateState.minutes : 0)
      .toDate();

    !(current && isSameDay(newTime, current)) && addDateToState(newTime);
    onDateChanged(newTime);
  };

  const gotoPreviousMonth = () => {
    const { month, year } = dateState;
    // this.setState(getPreviousMonth(month, year));
    const previousMonth = getPreviousMonth(month, year);
    setDateState({
      month: previousMonth.month,
      year: previousMonth.year,
      current: dateState.current,
      hours: dateState.hours,
      minutes: dateState.minutes,
    });
  };

  const gotoNextMonth = () => {
    const { month, year } = dateState;
    //this.setState(getNextMonth(month, year));
    const nextMonth = getNextMonth(month, year);
    setDateState({
      month: nextMonth.month,
      year: nextMonth.year,
      current: dateState.current,
      hours: dateState.hours,
      minutes: dateState.minutes,
    });
  };
  const gotoPreviousYear = () => {
    const { year } = dateState;
    setDateState({
      month: dateState.month,
      year: year - 1,
      current: dateState.current,
      hours: dateState.hours,
      minutes: dateState.minutes,
    });
  };
  const gotoNextYear = () => {
    const { year } = dateState;
    setDateState({
      month: dateState.month,
      year: year + 1,
      current: dateState.current,
      hours: dateState.hours,
      minutes: dateState.minutes,
    });
  };
  const handlePressure = (fn) => {
    if (typeof fn === "function") {
      fn();
      pressureTimeout = setTimeout(() => {
        pressureTimer = setInterval(fn, 100);
      }, 500);
    }
  };
  const clearPressureTimer = () => {
    pressureTimer && clearInterval(pressureTimer);
    pressureTimeout && clearTimeout(pressureTimeout);
  };
  const handlePrevious = (evt) => {
    evt && evt.preventDefault();
    const fn = evt.shiftKey ? gotoPreviousYear : gotoPreviousMonth;
    fn();
    // handlePressure(fn);
  };
  const handleNext = (evt) => {
    evt && evt.preventDefault();
    const fn = evt.shiftKey ? gotoNextYear : gotoNextMonth;
    fn();
    // handlePressure(fn);
  };

  // lifecycle methods
  useEffect(() => {
    const now = new Date();
    const tomorrow = new Date().setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000;
    const ms = tomorrow - now;
    dayTimeout = setTimeout(() => {
      setToday(new Date());
      clearDayTimeout();
    }, ms);
    return () => {
      clearPressureTimer();
      clearDayTimeout();
    };
  }, []);

  const clearDayTimeout = () => {
    dayTimeout && clearTimeout(dayTimeout);
  };

  return (
    <MainContainer>
      <CalendarContainer>
        {renderMonthAndYear()}
        <CalendarGrid>
          <Fragment>{Object.keys(WEEK_DAYS).map(renderDayLabel)}</Fragment>
          <Fragment>{getCalendarDates().map(renderCalendarDate)}</Fragment>
        </CalendarGrid>
        <div style={{ textAlign: "center", marginTop: 3 }}>
          <NoValueButton onClick={() => onDateChanged(null)}>
            Clear Date
          </NoValueButton>
        </div>
      </CalendarContainer>
      {includeTime && (
        <TimeContainer>
          <TimeHeader>Select Time</TimeHeader>
          <TimePickerContainer>
            <TimeHourSelect>{renderHours()}</TimeHourSelect>
            <TimeMinuteSelect>{renderMinutes()}</TimeMinuteSelect>
          </TimePickerContainer>
        </TimeContainer>
      )}
    </MainContainer>
  );
}

Calendar.displayName = "Calendar";

Calendar.propTypes = {
  date: PropTypes.instanceOf(Date),
  onDateChanged: PropTypes.func,
};
