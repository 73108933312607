import styled from "styled-components";
import { useState } from "react";
import { Tooltip } from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const SectionHeader = styled(
  ({
    className,
    title,
    description,
    onClick = () => {},
    dropDownArrow,
    hover,
    iconBtn,
    onIconClick,
  }) => {
    const [open, setOpen] = useState(true);

    const handleClick = () => {
      setOpen(!open);
      onClick();
    };

    return (
      <div className={className} onClick={handleClick}>
        <Tooltip title={description} enterDelay={500} placement="right" arrow>
          <div className="title">{title}</div>
        </Tooltip>
        {dropDownArrow ? (
          open ? (
            <KeyboardArrowDownIcon className="title-icon" />
          ) : (
            <KeyboardArrowUpIcon className="title-icon" />
          )
        ) : null}
      </div>
    );
  }
)`
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 15px;
  color: ${(props) => props.theme.palette.text.secondary};
  cursor: pointer;
  border-radius: 5px;
  padding: ${(props) => (props.dropDownArrow ? "4px" : "0px")};
  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
    background-color: ${(props) =>
      props.dropDownArrow ? props.theme.palette.action.hover : "transparent"};
  }
  transition: all 0.1s ease-in-out;
  .title {
    font-size: 1.1rem;
    margin-right: 5px;
    font-weight: 600;
  }
  .title-icon {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 5px;
    font-size: 12px;
    cursor: pointer;
    margin-left: auto;
    color: ${(props) => props.theme.palette.text.primary};

    svg {
      color: ${(props) => props.theme.palette.primary.main};
      font-size: 1rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default SectionHeader;
