import { Activity } from "react-feather";

const pagesSection = [
  // {
  //   href: "/dashboard",
  //   icon: Grid,
  //   title: "Dashboard",
  // },
  {
    href: "/my-requests",
    icon: Activity,
    title: "My Requests",
  },
  // {
  //   href: "/submit-requests",
  //   open: true,
  //   icon: ForwardToInboxOutlinedIcon,
  //   title: "Submit a Request",
  //   children: [],
  // },
  // {
  //   href: "/users",
  //   open: true,
  //   icon: GroupIcon,
  //   title: "Users",
  // },
  // {
  //   href: "/settings",
  //   icon: SettingsOutlinedIcon,
  //   title: "Settings",
  // },
];

const navItems = [
  {
    title: "My Workspace",
    pages: pagesSection,
  },
  {
    title: "Forensic Partners",
    pages: [],
  },
];

export default navItems;
