import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import styled from "styled-components";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled.span`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-weight: 700;
`;

const FooterSubText = styled.span`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  font-weight: 700;
  display: block;
  padding: 1px;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
`;

const FooterLink = styled.span`
  font-ize: "small";
  cursor: "pointer";
  color: ${(props) => props.theme.palette.primary.main};
  margin-right: 10;
  font-weight: 700;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const SidebarFooter = ({ ...rest }) => {
  const { currentUser, logout } = useAuth();

  return (
    <Footer {...rest}>
      {!!currentUser && (
        <>
          <FooterText variant="body2">{currentUser.full_name}</FooterText>
          <FooterSubText variant="caption">
            <div>{currentUser?.email}</div>
            {process.env.REACT_APP_API_MODE !== "on-premises" && (
              <div>{currentUser?.workspace?.workspace || ""}</div>
            )}
          </FooterSubText>
        </>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          marginTop: 5,
        }}
      >
        <FooterLink
          onClick={(event) => {
            logout();
          }}
        >
          Log Out
        </FooterLink>
        <Link to={`/users/${currentUser.email}/user-details`}>
          <FooterLink>Profile</FooterLink>
        </Link>
      </div>
    </Footer>
  );
};

export default SidebarFooter;
