import styled from "styled-components";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import { useEffect, useState } from "react";

const EvidencePhotosList = styled(({ className, evidenceInfo }) => {
  if (
    !evidenceInfo.evidence_photos ||
    evidenceInfo.evidence_photos.length === 0
  )
    return (
      <div style={{ marginTop: 10, marginBottom: 20 }}>No evidence photos</div>
    );

  return (
    <div className={className}>
      {evidenceInfo?.evidence_photos?.map((item, index) => {
        return <EvidencePhotoItem key={item.image_id} item={item} />;
      })}
    </div>
  );
})`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  margin-top: 10px;
`;

const EvidencePhotoItem = styled(({ className, item }) => {
  const [open, setOpen] = useState(false);

  const handleView = () => setOpen(!open);

  return (
    <>
      <div className={className}>
        <Overlay item={item} handleView={handleView} />
        <FullScreenImageViewer
          item={item}
          open={open}
          handleClose={() => setOpen(false)}
        />
        <div className="photo-item">
          <img
            className="photo"
            src={item.image_full_path}
            alt="evidence photograph"
          />
          <div className="photo-name">{item.image_path}</div>
        </div>
      </div>
    </>
  );
})`
  position: relative;
  .photo-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    &:hover {
      border-color: ${({ theme }) => theme.palette.divider};
    }
    .photo {
      max-height: 100px;
      object-fit: cover;
      image-orienation: from-image;
    }
    .photo-name {
      margin-top: 10px;
      font-size: 10px;
      color: ${({ theme }) => theme.palette.text.secondary};
    }
  }
`;

const Overlay = styled(({ className, item, handleView }) => {
  return (
    <div className={className}>
      <PhotoButtons item={item} handleView={handleView} />
    </div>
  );
})`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;
  &:hover {
    background-color: #00000060;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    & .photo-buttons {
      opacity: 1;
    }
  }
`;

const PhotoButtons = styled(({ className, item, handleView }) => {
  return (
    <div className={className + " photo-buttons"}>
      <a href={item.image_full_path} download>
        <div className="photo-button">
          <FileDownloadOutlinedIcon />
        </div>
      </a>
      <div className="photo-button" onClick={handleView}>
        <OpenInFullOutlinedIcon />
      </div>
    </div>
  );
})`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  margin: 10px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  .photo-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    // margin-left: 5px;
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.palette.divider};
    }
  }
  & svg {
    font-size: 15px;
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const FullScreenImageViewer = styled(
  ({ className, item, open, handleClose }) => {
    if (!open) return null;

    //CLose on outside click
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          event.target === document.getElementById("full-screen-image-viewer")
        ) {
          handleClose();
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [open]);

    return (
      <div className={className} id="full-screen-image-viewer">
        <div className="image-viewer">
          <img
            className="image"
            src={item.image_full_path}
            alt="evidence photograph"
          />
        </div>
      </div>
    );
  }
)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  display: flex;
  align-items: center;
  justify-content: center;
  .image-viewer {
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 600px;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    .image {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
`;

export default EvidencePhotosList;
