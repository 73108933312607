import { useEffect, useRef } from "react";
import styled from "styled-components";
import RequestService from "../../../../api/requests/index.js";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import moment from "moment";
import CommentEditor from "../../../../components/CommentEditor.js";
import TiptapViewer from "../../../../components/TiptapViewer.js";

const OverviewContent = styled(({ className, details }) => {
  const { requestID } = useParams();
  const queryClient = useQueryClient();

  const onSubmit = ({ html, mentions }) => {
    RequestService.createComment({
      request_id: requestID,
      object_type: "request",
      comment: html,
      mentions,
    }).then((res) => {
      queryClient.refetchQueries([
        "request:comments",
        { request_id: requestID, data_only: true },
      ]);
    });
  };

  const getUserMentionList = () => {
    const mentionItems = [];

    if (details?.requestor)
      mentionItems.push({
        full_name: details?.requestor?.full_name,
        email: details?.requestor?.email,
      });
    if (details?.case_info?.case_lead)
      mentionItems.push({
        full_name: details?.case_info?.case_lead?.full_name,
        email: details?.case_info?.case_lead?.email,
      });

    if (details?.relay_tenant?.tenant_email) {
      mentionItems.push({
        full_name: details?.relay_tenant?.org,
        email: details?.relay_tenant?.tenant_email,
      });
    }

    // remove duplocates
    const uniqueMentionItems = mentionItems.filter(
      (mention, index, self) =>
        index === self.findIndex((m) => m.email === mention.email)
    );

    return uniqueMentionItems;
  };

  return (
    <div className={className}>
      <div className="description-header">Request Description</div>
      <div className="description-container">
        <div>{details.description || "No Description"}</div>
      </div>
      <CommentSection />
      <CommentSectionTitle>Add Comments</CommentSectionTitle>
      <CommentEditor onSubmit={onSubmit} mentionItems={getUserMentionList()} />
    </div>
  );
})`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 750px;
  margin: 0 auto;
  .description-container {
    padding: 15px;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 5px;
    white-space: pre-wrap;
  }
  & .description-header {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 12px;
    font-weight: 500;
    cursor: default;
    // margin-bottom: 10px;
  }
`;

const CommentSectionTitle = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 12px;
  font-weight: 500;
  cursor: default;
  margin-top: 10px;
`;

const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  white-space: pre-wrap;
  background-color: ${({ theme }) => theme.palette.background.alt};
  max-height: 500px;
  overflow-y: auto;
`;

const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CommentHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: baseline;
  cursor: pointer;
  user-select: none;
`;
const CommentHeaderName = styled.span`
  font-weight: 700;
  font-size: 12px;
`;
const CommentHeaderDate = styled.span`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 10px;
`;

const CommentFooter = styled.div``;

const CommentDivderLeft = styled.div`
  flex: 1;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
`;

const CommentDividerRight = styled.div`
  flex: 1;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
`;

const CommentDivider = styled(({ className, children }) => {
  return (
    <div className={className}>
      <CommentDivderLeft />
      <div style={{ padding: "0 10px" }}>{children}</div>
      <CommentDividerRight />
    </div>
  );
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  // margin: 5px 0;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.text.secondary};
  // font-weight: 500;
`;

const CommentSection = styled(({ className }) => {
  const { requestID } = useParams();
  const commentsContainerRef = useRef(null);

  const { data } = useQuery({
    queryKey: ["request:comments", { request_id: requestID, data_only: true }],
    queryFn: () =>
      RequestService.getComments({ request_id: requestID, data_only: true }),
    keepPreviousData: true,
    refetchInterval: 10000, // 10 seconds
  });

  useEffect(() => {
    //Scroll to bottom of comments container
    if (commentsContainerRef.current) {
      setTimeout(() => {
        commentsContainerRef.current.scrollTop = 9999999;
      }, 0);
    }
  }, [data]);

  if (!data) return null;

  return (
    <div className={className}>
      <CommentSectionTitle>Comments</CommentSectionTitle>
      <CommentsContainer ref={commentsContainerRef}>
        {data.map((comment, index) => {
          const addDivider =
            moment(comment.created_on).format("YYYY-MM-DD") !==
            moment(data?.[index - 1]?.created_on).format("YYYY-MM-DD");

          const title = `${comment?.user?.full_name || comment?.user?.email}${
            comment?.user?.org ? `\n` + comment?.user?.org : ""
          }\n${comment?.user?.email}\n${moment(comment.created_on).format(
            "lll"
          )}`;
          return (
            <CommentContainer>
              {addDivider && (
                <CommentDivider>
                  {moment(comment.created_on).format("MMMM DD, YYYY")}
                </CommentDivider>
              )}
              <CommentHeader title={title}>
                <CommentHeaderName>
                  {comment?.user?.full_name || comment?.user?.email}
                </CommentHeaderName>
                <CommentHeaderDate>
                  {moment(comment.created_on).format("h:mm A")}
                </CommentHeaderDate>
              </CommentHeader>
              <TiptapViewer defaultValue={comment.comment} />
              <CommentFooter></CommentFooter>
            </CommentContainer>
          );
        })}
        {data.length === 0 && <div>No Comments</div>}
      </CommentsContainer>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

export default OverviewContent;
