import styled from "styled-components";
import Loader from "../../components/Loader.js";
import { useEffect, useRef, useState } from "react";

const PDFViewer = styled(({ className, file }) => {
  const [isLoading, setIsLoading] = useState(true);
  const viewerRef = useRef(null);

  const renderPDF = async () => {
    const pdfjsLib = await import("pdfjs-dist/webpack");
    const pdfjsViewer = await import("pdfjs-dist/web/pdf_viewer.js");
    import("pdfjs-dist/web/pdf_viewer.css");

    const container = viewerRef.current;
    const CMAP_URL = "../../node_modules/pdfjs-dist/cmaps/";
    const CMAP_PACKED = true;

    const DEFAULT_URL = file.url;

    const ENABLE_XFA = true;
    const SEARCH_FOR = ""; // try "Mozilla";

    const SANDBOX_BUNDLE_SRC =
      "../../node_modules/pdfjs-dist/build/pdf.sandbox.js";

    const eventBus = new pdfjsViewer.EventBus();

    // (Optionally) enable hyperlinks within PDF files.
    const pdfLinkService = new pdfjsViewer.PDFLinkService({
      eventBus,
    });

    // (Optionally) enable find controller.
    const pdfFindController = new pdfjsViewer.PDFFindController({
      eventBus,
      linkService: pdfLinkService,
    });

    // (Optionally) enable scripting support.
    const pdfScriptingManager = new pdfjsViewer.PDFScriptingManager({
      eventBus,
      sandboxBundleSrc: SANDBOX_BUNDLE_SRC,
    });

    const pdfViewer = new pdfjsViewer.PDFViewer({
      container,
      eventBus,
      linkService: pdfLinkService,
      findController: pdfFindController,
      //   scriptingManager: pdfScriptingManager,
      textLayerMode: 2,
    });
    pdfLinkService.setViewer(pdfViewer);
    // pdfScriptingManager.setViewer(pdfViewer);

    eventBus.on("pagesinit", function () {
      // We can use pdfViewer now, e.g. let's change default scale.
      pdfViewer.currentScaleValue = "page-width";

      // We can try searching for things.
      if (SEARCH_FOR) {
        eventBus.dispatch("find", { type: "", query: SEARCH_FOR });
      }
    });

    // Loading document.
    const loadingTask = pdfjsLib.getDocument({
      url: DEFAULT_URL,
      cMapUrl: CMAP_URL,
      cMapPacked: CMAP_PACKED,
      enableXfa: ENABLE_XFA,
    });

    const pdfDocument = await loadingTask.promise;
    // Document loaded, specifying document for the viewer and
    // the (optional) linkService.
    pdfViewer.setDocument(pdfDocument);

    pdfLinkService.setDocument(pdfDocument, null);

    setIsLoading(false);
  };

  useEffect(() => {
    const viewer = viewerRef.current;
    if (file?.url) renderPDF();

    return () => {
      setIsLoading(true);
      viewer.querySelector("#viewer").innerHTML = "";
    };
  }, [file]);

  return (
    <>
      {isLoading && <Loader message="Loading Document..." />}
      <div className={className}>
        <div id="viewerContainer" ref={viewerRef}>
          <div id="viewer" className="pdfViewer"></div>
        </div>
      </div>
    </>
  );
})`
  display: flex;
  justify-content: center;

  #viewerContainer {
    overflow: auto;
    position: absolute;
    max-width: 8.5in;
    width: 100%;
    height: calc(100vh - 50px);
  }
`;

export default PDFViewer;
