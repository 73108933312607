import { useState } from "react";
import { NavLink } from "react-router-dom";
import { rgba, darken } from "polished";

import { Chip } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import styled from "styled-components";

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 5px 0;

  padding-left: ${(props) => (props.depth ? props.depth * 56 : 28)}px;
  padding-right: 16px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: ${(props) => darken(0.05, props.theme.sidebar.active)};
    color: ${(props) => props.theme.sidebar.color};
  }

  &.${(props) => props.activeclassname} {
    background-color: ${(props) => props.theme.sidebar.active};
    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const Title = styled.div`
  margin: 0;
  color: ${(props) => (props.depth > 0 ? "#ccc" : "#eee")};
  font-weight: ${(props) => (props.depth > 0 ? "normal" : "600")};
  font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  padding: 2.5px 10px;
  user-select: none;
`;

const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const SidebarNavListItem = styled(
  ({
    className,
    title,
    href,
    depth = 0,
    children,
    icon: Icon,
    badge,
    open: openProp = true,
  }) => {
    const [open, setOpen] = useState(true);

    const handleToggle = () => {
      setOpen((state) => !state);
    };

    return (
      <div className={className}>
        {href ? (
          <NavLink
            to={href}
            end
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <Item depth={depth} onClick={handleToggle}>
              {Icon && <Icon />}
              <Title depth={depth}>
                {title}
                {badge && <Badge label={badge} />}
              </Title>
              {!!children ? (
                open ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )
              ) : null}
            </Item>
          </NavLink>
        ) : (
          <Item depth={depth} onClick={handleToggle}>
            {Icon && <Icon />}
            <Title depth={depth}>
              {title}
              {badge && <Badge label={badge} />}
            </Title>
            {!!children ? open ? <ExpandLessIcon /> : <ExpandMoreIcon /> : null}
          </Item>
        )}
        {!!children ? open ? <>{children} </> : null : null}
      </div>
    );
  }
)`
  .active div {
    background-color: ${(props) => props.theme.sidebar.active};
    color: ${(props) => props.theme.sidebar.color};
  }
`;

export default SidebarNavListItem;
