/* eslint-disable linebreak-style */
// const isDevelopment = process.env.NODE_ENV !== 'production';
// import fs from 'fs';
// import path from 'path';
// import https from 'https';
// import { showToast } from '../..';

import axios from "axios";
import { AuthService } from "./auth/index.js";
// set axios credetials to true
axios.defaults.withCredentials = true;

let URL = null;

if (process.env.REACT_APP_API_MODE === "on-premises") {
  axios.defaults.headers.common["x-relay-api-host"] = `${window.origin}/api`;
}

export async function getBaseURL() {
  if (!URL) await setBaseURL();
  return URL;
}

export async function setBaseURL() {
  URL = `${window.origin}/api/relay`;
  axios.defaults.headers.common["x-relay-origin"] = window.origin;
}

export async function initBaseURL() {
  axios.defaults.headers.common["x-relay-origin"] = window.origin;
  return `${window.origin}/api/relay`;
}

initBaseURL().then((result) => {
  axios.defaults.headers.common["x-relay-origin"] = window.origin;
  axios.defaults.headers.common["x-relay-api-host"] = result;
  URL = result;
});

export async function httpGet(api, params = {}, options = {}) {
  return axios
    .get(`${options.baseUrl || URL || (await getBaseURL())}${api}`, {
      params,
    })
    .then(handleRepsonse)
    .catch(handleError);
}

export async function httpPost(api, body, options = {}) {
  const reqOptions = {
    method: "POST",
    url: `${URL || (await getBaseURL())}${api}`,
    headers: {
      "content-type": "application/json",
    },
    data: JSON.stringify(body),
    onUploadProgress: options.onUploadProgress,
  };

  return axios(reqOptions).then(handleRepsonse).catch(handleError);
}

export async function httpPatch(api, body) {
  const options = {
    method: "PATCH",
    url: `${URL || (await getBaseURL())}${api}`,
    headers: {
      "content-type": "application/json",
    },
    data: JSON.stringify(body),
  };

  return axios(options).then(handleRepsonse).catch(handleError);
}

export async function httpDelete(api, params) {
  return axios
    .delete(`${URL || (await getBaseURL())}${api}`, {
      params,
    })
    .then(handleRepsonse)
    .catch(handleError);
}

export async function httpFileDownload(url, options = {}) {
  return axios
    .get(url, { responseType: "blob" })
    .then(handleRepsonse)
    .catch(handleError);
}

const preventLogoutWhiteList = ["/check-session"];

const handleRepsonse = (response) => {
  if (response.status === 401) {
    console.log("401 unauthorized");
    // auto logout if 401 response returned from api
    AuthService.userLogout();
    window.location.reload(true);
  }

  return response.data;
};

const handleError = (error) => {
  if (
    error.response.status === 401 &&
    !preventLogoutWhiteList.includes(
      error.config.url.replace(error.config.headers["x-monolith-origin"], "")
    )
  ) {
    console.log("401 unauthorized");
    window.location.reload(true);
  }

  return null;
};
