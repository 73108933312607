import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../../contexts/AuthContext.js";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import CaseDrivesAPI from "../../api/CaseDrives/CaseDrives.js";
import { Column, DropDownMenu, Table } from "@monolith-forensics/monolith-ui";
import { HardDriveIcon, MoreVerticalIcon } from "lucide-react";
import moment from "moment";

const CaseDrivesTable = styled(({ className, onDriveSelect }) => {
  const { tenantSlug } = useParams();
  const { currentUser } = useAuth();

  const monolith_tenant = currentUser.tenants.find(
    (tenant) => tenant.tenant_slug === tenantSlug
  )?.monolith_tenant;

  const [query, setQuery] = useState({
    email: currentUser.email,
    sort: null,
    monolith_tenant,
  });

  const { data } = useQuery({
    queryKey: ["case-drives", "list", { ...query, monolith_tenant }],
    queryFn: () => CaseDrivesAPI.getCaseDrives(query),
    placeholderData: (data) => data || { data: [] },
    refetchOnWindowFocus: true,
  });

  const caseDrives = data?.data || [];

  return (
    <div className={className}>
      <Table
        data={caseDrives}
        keyValue="case_uuid"
        onRowDoubleClick={onDriveSelect}
      >
        <Column
          dataField="custom-action-1"
          width={50}
          render={(rowData) => {
            return (
              <div
                className="data-cell"
                style={{ maxWidth: 60, justifyContent: "center" }}
              >
                <DropDownMenu
                  data={[
                    {
                      label: "Open Case Drive",
                      value: "case-drive:open",
                    },
                  ]}
                  buttonProps={{
                    title: "Case Drive Actions",
                    size: "xxs",
                    style: { padding: "0px 4px" },
                    variant: "subtle",
                  }}
                  onItemSelect={(item) => onDriveSelect?.(rowData)}
                  dropDownProps={{
                    style: { width: 175, maxWidth: 400 },
                  }}
                >
                  <MoreVerticalIcon size={14} />
                </DropDownMenu>
              </div>
            );
          }}
        />
        <Column
          dataField="case_number"
          caption="Case Number"
          width={250}
          render={(rowData) => {
            return (
              <Link to={`/${tenantSlug}/case-drives/${rowData.case_uuid}`}>
                <div className="data-cell" title={rowData.case_number}>
                  <HardDriveIcon size={18} />
                  {rowData.case_number}
                </div>
              </Link>
            );
          }}
        />
        <Column dataField="case_ref" caption="Case Name" width={250} />
        <Column
          dataField="created_on"
          caption="Added On"
          render={(rowData) =>
            moment(rowData.created_on).format("MMMM DD, YYYY hh:mm A")
          }
        />
      </Table>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;

  cursor: default;
  user-select: none;
  font-size: 12px;

  .data-cell {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.primary.main};
    cursor: pointer;
  }
`;

export default CaseDrivesTable;
