import { Button } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { styled, useTheme } from "styled-components";
import AddPeopleModal from "../../Modals/AddPeopleModal.js";
import RequestService from "../../../../api/requests/index.js";
import { useQueryClient } from "@tanstack/react-query";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditPeopleModal from "../../Modals/EditPeopleModal.js";
import DeletePeopleModal from "../../Modals/DeletePeopleModal.js";
import { useRequest } from "../RequestView.js";

const MenuBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
`;

const Items = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ActionMenu = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  margin-top: auto;
  padding-top: 30px;
`;

const ActionMenuItem = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  margin-right: 12px;
  // padding: 3px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 10px;
  &:hover {
    text-decoration: underline;
  }
  & svg {
    font-size: 12px;
    margin-right: 5px;
  }
`;

const ActionMenuLabel = styled.div``;

const PeopleContent = styled(({ className, details }) => {
  const { requestID } = useParams();
  const { isEditable } = useRequest();
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();

  const relayPeople = details?.people || [];

  const addPeople = (data) => {
    RequestService.createPeople({
      ...data,
      request_id: requestID,
    }).then((res) => {
      queryClient.refetchQueries([
        "request",
        { request_id: requestID, data_only: true },
      ]);
    });
  };

  return (
    <div className={className}>
      <MenuBar>
        {isEditable && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{
              fontSize: "11px",
              padding: "3px 6px",
              // fontWeight: 700,
            }}
            onClick={() => setShowModal(true)}
          >
            + Add Contact
          </Button>
        )}
      </MenuBar>
      <Items>
        {relayPeople.length === 0 && (
          <div style={{ textAlign: "center", width: "100%" }}>
            No contacts added
          </div>
        )}
        {relayPeople.map((item) => (
          <PeopleItem key={item.item_id} item={item} />
        ))}
      </Items>
      {isEditable && (
        <AddPeopleModal
          open={showModal}
          handleClose={() => setShowModal(false)}
          onSubmit={addPeople}
        />
      )}
    </div>
  );
})`
  max-width: 900px;
  margin: 0 auto;
`;

const PeopleItem = styled(({ className, item }) => {
  const { requestID } = useParams();
  const { isEditable } = useRequest();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const queryClient = useQueryClient();
  const theme = useTheme();

  const handleEditPeople = () => setShowEditModal(true);
  const handleDeletePeople = () => setShowDeleteModal(true);

  const editPeople = (data) => {
    RequestService.updatePeople({
      ...data,
      request_id: requestID,
    }).then((res) => {
      queryClient.refetchQueries(["request", requestID]);
    });
  };

  const deletePeople = (data) => {
    RequestService.deletePeople({
      ...data,
      request_id: requestID,
    }).then((res) => {
      queryClient.refetchQueries(["request", requestID]);
    });
  };

  return (
    <div className={className}>
      <div className="contact-details-container">
        <div className="contact-name">{item.person_name}</div>
        {item.unique_id && (
          <div className="contact-email">{item.unique_id}</div>
        )}
        <div className="contact-type">{item.person_type}</div>
        {isEditable && (
          <ActionMenu>
            <ActionMenuItem onClick={handleEditPeople}>
              <EditOutlinedIcon style={{ color: theme.palette.primary.main }} />
              <ActionMenuLabel>Edit</ActionMenuLabel>
            </ActionMenuItem>
            <ActionMenuItem onClick={handleDeletePeople}>
              <DeleteOutlineOutlinedIcon
                style={{ color: theme.palette.error.main }}
              />
              <ActionMenuLabel>Delete</ActionMenuLabel>
            </ActionMenuItem>
          </ActionMenu>
        )}
      </div>
      <div>
        <div>
          <span className="contact-email">Email: </span>
          <span className="contact-description">{item.person_email}</span>
        </div>
        <div className="contact-header">Description</div>
        <div className="contact-description">
          {item?.description || "No description provided."}
        </div>
      </div>
      {isEditable && (
        <EditPeopleModal
          open={showEditModal}
          handleClose={() => setShowEditModal(false)}
          onSubmit={editPeople}
          defaultFormData={item}
        />
      )}
      {isEditable && (
        <DeletePeopleModal
          open={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          onSubmit={deletePeople}
          defaultFormData={item}
        />
      )}
    </div>
  );
})`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  padding: 20px;
  display: flex;
  width: 100%;

  transition: all 0.2s ease-in-out;

  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  & .contact-header {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  & .contact-details-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    min-width: 150px;
  }

  & .contact-description {
    flex-grow: 1;
    white-space: pre-wrap;
    text-align: justify;
    font-size: 0.75rem;
  }

  & .contact-name {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  & .contact-type {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & .contact-email {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & .action-menu {
    display: flex;
    align-items: center;
    align-content: center;
    margin-top: auto;
    padding-top: 30px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    // padding: 3px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 10px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 12px;
      margin-right: 5px;
    }
  }
`;

export default PeopleContent;
