import styled from "styled-components";

const TaskButton = styled(
  ({
    className,
    children,
    style = {},
    onClick = () => {},
    title = "",
    variant = "text",
    enabled = true,
  }) => {
    const _onClick = (e) => {
      // e.stopPropagation();
      if (enabled) onClick(e);
    };

    return (
      <div
        className={className}
        onClick={_onClick}
        style={{ ...style }}
        title={title}
      >
        {children}
      </div>
    );
  }
)`
  user-select: none;
  cursor: pointer;
  display: flex;
  alignitems: center;
  margin-right: 3px;
  border-radius: 4px;
  width: fit-content;
  min-width: max-content;
  padding: 2px;
  color: ${(props) => props.theme.palette.text.secondary};
  transition: all 0.15s ease-in-out;
  border: 1px solid
    ${(props) => {
      if (props.variant === "outlined") return props.theme.palette.divider;
      if (props.variant === "text") return "transparent";
      if (props.variant === "contained") return "transparent";
      return "transparent";
    }};
  background-color: ${(props) => {
    if (props.variant === "contained")
      return props.theme.palette.background.secondary;
    if (props.variant === "outlined") return "transparent";
    if (props.variant === "text") return "transparent";
    return "transparent";
  }};
  ${(props) =>
    props.enabled === undefined || props.enabled
      ? `&:hover {
    // background-color: ${props.theme.palette.action.hover};
    border: 1px solid ${props.theme.palette.primary.main};
  }`
      : ""}
`;

//   ((props) => {
//   return {
//     cursor: "pointer",
//     display: "flex",
//     alignItems: "center",
//     marginRight: 3,
//     borderRadius: 4,
//     width: "fit-content",
//     padding: 2,
//     color: props.theme.palette.text.secondary,
//     border: `1px solid ${
//       props.variant === "outlined"
//         ? props.theme.palette.divider
//         : props.variant === "text"
//         ? "transparent"
//         : props.variant === "contained"
//         ? "transparent"
//         : "transparent"
//     }`,
//     backgroundColor:
//       props.variant === "contained"
//         ? props.theme.palette.action.hover
//         : props.variant === "outlined"
//         ? "transparent"
//         : props.variant === "text"
//         ? "transparent"
//         : "transparent",
//     "&:hover": {
//       backgroundColor: props.theme.palette.action.hover,
//     },
//     ...props.style,
//   };
// });

export default TaskButton;
