import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";

const getEmailTargets = async (params) => httpGet(`/email-targets`, params);
const createEmailTarget = async (values) => httpPost(`/email-targets`, values);
const updateEmailTarget = async (target_id, values) =>
  httpPatch(`/email-targets/${target_id}`, values);
const deleteEmailTarget = async (target_id) =>
  httpDelete(`/email-targets/${target_id}`);

const getCaseTypes = async (tenantId) =>
  httpGet("/cases/types" + (tenantId ? `/${tenantId}` : ""));
const createCaseType = async (values) => httpPost("/cases/types", values);
const updateCaseType = async (caseTypeId, values) =>
  httpPatch(`/cases/types/${caseTypeId}`, values);
const deleteCaseType = async (caseTypeId) =>
  httpDelete(`/cases/types/${caseTypeId}`);

const getEvidenceTypes = async (tenantId) =>
  httpGet("/evidence/types" + (tenantId ? `/${tenantId}` : ""));
const createEvidenceType = async (values) =>
  httpPost("/evidence/types", values);
const updateEvidenceType = async (evidenceTypeId, values) =>
  httpPatch(`/evidence/types/${evidenceTypeId}`, values);
const deleteEvidenceType = async (evidenceTypeId) =>
  httpDelete(`/evidence/types/${evidenceTypeId}`);

const saveHowTo = async (values = {}) => httpPost(`/how-to`, values);
const getHowTo = async (tenantUuid) => httpGet(`/how-to/${tenantUuid}`);

const createCustomField = async (values) => httpPost("/custom-fields/", values);
const updateCustomField = async (customFieldId, values) =>
  httpPatch(`/custom-fields/${customFieldId}`, values);
const deleteCustomField = async (customFieldId) =>
  httpDelete(`/custom-fields/${customFieldId}`);

const SettingsService = {
  getEmailTargets,
  createEmailTarget,
  updateEmailTarget,
  deleteEmailTarget,
  getCaseTypes,
  createCaseType,
  updateCaseType,
  deleteCaseType,
  getEvidenceTypes,
  createEvidenceType,
  updateEvidenceType,
  deleteEvidenceType,
  saveHowTo,
  getHowTo,
  createCustomField,
  updateCustomField,
  deleteCustomField,
};

export default SettingsService;
