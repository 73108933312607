import { Modal } from "@mui/material";
import { EmptyItem, Form, SimpleItem } from "devextreme-react/ui/form.js";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "styled-components";
import { DevexEditors } from "../../../utils/devex-editors.js";
import ToolBarItems from "../../../components/ToolBarItems.js";
import { useQuery } from "@tanstack/react-query";
import MonolithEvidenceService from "../../../api/Monolith/Evidence/index.js";
import { useAuth } from "../../../contexts/AuthContext.js";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { dxTagBox } from "devextreme/ui/tag_box";
import shortUUID from "short-uuid";
import { useRequest } from "../RequestView/RequestView.js";

const AddEvidenceModal = ({ handleClose, open, onSubmit, copyData = {} }) => {
  const { currentUser } = useAuth();
  const { tenantSlug } = useParams();
  const [selectedItemType, setSelectedItemType] = useState(
    copyData.evidence_type || null
  );
  const [defaultFormData, setDefaultFormData] = useState(copyData);
  const theme = useTheme();
  const form = useRef(null);

  const { details } = useRequest();
  const contacts = details?.people || [];

  useEffect(() => {
    // Add contact_id for devExtreme SelectBox default value display
    if (copyData?.linked_contact) {
      setDefaultFormData((prevFormData) => ({
        ...prevFormData,
        contact_id: copyData.linked_contact.item_id,
        evidence_number: "",
      }));
    }
  }, [copyData]);

  const monolithTenant = currentUser.tenants.find(
    (tenant) => tenant.tenant_slug === tenantSlug
  );

  const { data } = useQuery({
    queryKey: [
      "evidence:elements",
      {
        monolith_tenant: monolithTenant.monolith_tenant,
        include_types: true,
        include_custom_fields: true,
      },
    ],
    queryFn: () =>
      MonolithEvidenceService.getEvidenceElements({
        monolith_tenant: monolithTenant.monolith_tenant,
        include_types: true,
        include_custom_fields: true,
      }),
    // enabled: !!currentUser,
  });

  const handleSubmit = () => {
    if (!form.current.instance.validate().isValid) return;

    const { customEvidenceFields } = data;

    let formData = { ...form.current.instance.option("formData") };

    // remove empty values from formData
    Object.keys(formData).forEach(
      (key) => !formData[key] && delete formData[key]
    );

    handleClose();

    const custom_fields = Object.keys(formData)
      .filter((key) => key.includes("custom_field_") && formData[key]) // filter out empty values
      .map((key) => {
        const id = parseInt(key.replace("custom_field_", ""));
        return {
          ...(customEvidenceFields.find((field) => field.field_id === id) ||
            {}),
          value: formData[key],
        };
      });

    // Prepare contact
    if (formData.contact_id) {
      const contact = contacts.find(
        (contact) => contact.item_id === formData.contact_id
      );
      formData.linked_contact = contact;
    }

    const uuid = shortUUID.generate();
    if (onSubmit)
      onSubmit({
        ...formData,
        item_id: uuid,
        custom_fields,
      });
  };

  if (!data) return null;

  const { evidenceTypes, customEvidenceFields } = data;

  const currentEvidenceTypeId = evidenceTypes?.find(
    (type) => type.evi_type === defaultFormData.evidence_type
  )?.evi_type_id;

  const customFields = customEvidenceFields
    .filter((field) => field.external_enabled)
    .filter((field) => {
      if (!field?.type_ids) return true;
      else if (field?.type_ids?.length === 0) return true;
      return field?.type_ids?.includes(currentEvidenceTypeId);
    });

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        setSelectedItemType(null);
        handleClose();
      }}
      disableEnforceFocus={true}
      style={{
        userSelect: "none",
        zIndex: 1400,
      }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 700,
          maxHeight: "90vh",
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 350px)",
          overflowY: "auto",
          padding: 20,
        }}
      >
        <div style={{ marginBottom: 20 }}>
          <div
            style={{
              marginBottom: 20,
              display: "flex",
              flexDirection: "row",
              gap: 5,
              alignItems: "center",
              fontSize: "1.25rem",
              fontWeight: 600,
            }}
          >
            <AddIcon color="primary" /> Add Evidence Item
          </div>
        </div>
        <div
          style={{
            maxHeight: 700,
            overflowY: "auto",
            paddingRight: 10,
            paddingLeft: 10,
          }}
        >
          <Form ref={form} colCount={2} defaultFormData={defaultFormData}>
            <SimpleItem
              dataField="evidence_number"
              label={{ text: "Evidence Number" }}
              editorOptions={{
                placeholder: "Enter Your Evidence Number",
              }}
            />
            <SimpleItem
              dataField="evidence_type"
              isRequired={true}
              label={{ text: "Evidence Type" }}
              editorType="dxSelectBox"
              editorOptions={{
                items: evidenceTypes,
                displayExpr: "evi_type",
                valueExpr: "evi_type",
                dropDownOptions: {
                  height: 250,
                },
                onSelectionChanged: (e) => {
                  setTimeout(() => setSelectedItemType(e.selectedItem), 5);
                },
              }}
            />
            <SimpleItem
              dataField="make"
              label={{ text: "Provider" }}
              editorOptions={{
                placeholder: "Apple, Google, Microsoft, etc...",
              }}
            />
            <SimpleItem
              dataField="model_number"
              label={{ text: "Item Name" }}
              editorOptions={{
                placeholder: "iPhone X, Galaxy S10, etc...",
              }}
            />
            <SimpleItem
              dataField="serial_number"
              label={{ text: "Unique ID" }}
              editorOptions={{
                placeholder: "Serial number, account name, etc.",
              }}
            />
            <SimpleItem
              dataField="contact_id"
              label={{ text: "Linked Contact" }}
              editorType="dxSelectBox"
              editorOptions={{
                dataSource: contacts,
                valueExpr: "item_id",
                displayExpr: "person_name",
                placeholder: "Select Contact...",
                dropDownOptions: {
                  maxHeight: 250,
                },
              }}
            />
            {customFields &&
              customFields.map((field) => {
                return (
                  <SimpleItem
                    key={`custom_field_${field.field_id}`}
                    dataField={`custom_field_${field.field_id}`}
                    editorType={DevexEditors[field.editor_type]}
                    // isRequired={attribute.is_required === 1}
                    label={{ text: field.field_name }}
                    isRequired={field.is_required === 1}
                    editorOptions={{
                      items: JSON.parse(field.options || "[]"),
                      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
                      useMaskBehavior: true,
                      //   displayFormat: getDateFormat(false, false),
                      showClearButton: field.editor_type !== "textBox",
                      pickerType: "calendar",
                      showDropDownButton: true,
                      multiline: false,
                      showSelectionControls:
                        field.editor_type === "tagBox" ? true : false,
                    }}
                  ></SimpleItem>
                );
              })}
            {<EmptyItem visible={customFields.length % 2 !== 0} />}
            <SimpleItem
              dataField="description"
              editorType="dxTextArea"
              colSpan={2}
              editorOptions={{
                height: 150,
                placeholder:
                  "Provide a brief description of this evidence and any relevant details.",
              }}
            />
          </Form>
          <ToolBarItems
            submitText="Add Evidence"
            onSubmit={handleSubmit}
            onCancel={() => {
              setSelectedItemType(null);
              handleClose();
            }}
            style={{ marginTop: 20 }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddEvidenceModal;
