import { createContext, useContext, useState } from "react";
import { AuthService } from "../api/auth/index";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import UserService from "../api/users/users.js";
// import SplashPage from "../components/SplashPage";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [authState, setAuthState] = useState(null); // loggingIn, loggingOut, loggedIn, loggedOut, null
  const [isDone, setIsDone] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isLoading } = useQuery({
    queryKey: ["session"],
    queryFn: () => AuthService.checkSession(),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onError: (err) => {
      console.log("There was an error fetching the user info", err);
    },
    onSettled: (data) => {
      if (data?.userInfo) {
        setSessionData(data.userInfo);
      } else setIsDone(true);
    },
  });

  const login = async (email, password) => {
    return AuthService.userLogin({
      email,
      password,
    })
      .then(async (result) => {
        if (result) setUserInfo(result.user);
        return result;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  };

  const logout = async () => {
    setAuthState("loggingOut");
    setUserInfo(null);
    await AuthService.userLogout();
    queryClient.clear();
    navigate("/login");
    // window.location.href = "/login";
  };

  const setSessionData = async (defaultUserInfo) => {
    if (!defaultUserInfo && !userInfo) throw new Error("No user info provided");

    const userData = defaultUserInfo || userInfo; // if defaultUserInfo is not provided, use the one from state
    const [user] = await UserService.getUsers({
      email: userData.email,
    });

    setUserInfo(user);
    setIsDone(true);
  };

  if (!isDone) return null;

  return (
    <AuthContext.Provider
      value={{
        currentUser: userInfo,
        isLoading: !isDone,
        isAuthenticated: !!userInfo,
        logout,
        login,
        setSessionData,
        authState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
