import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../../contexts/AuthContext.js";
import { useQuery } from "@tanstack/react-query";
import CaseDrivesAPI from "../../api/CaseDrives/CaseDrives.js";
import FileViewer from "../../Monolith-UI/FileViewer/FileViewer.js";

const FileViewerComponent = styled(
  ({ className, uuid, open, handleClose, handleFileDownload }) => {
    const { tenantSlug, case_uuid } = useParams();
    const { currentUser } = useAuth();

    const monolith_tenant = currentUser.tenants.find(
      (tenant) => tenant.tenant_slug === tenantSlug
    )?.monolith_tenant;

    const { data, isLoading } = useQuery({
      queryKey: [
        "shared-items",
        "list",
        {
          email: currentUser.email,
          object_id: uuid,
          case_uuid,
          monolith_tenant,
        },
      ],
      queryFn: ({ queryKey }) => CaseDrivesAPI.getSharedItems(queryKey[2]),
      enabled: !!open,
    });

    const { data: downloadInfo } = useQuery({
      queryKey: ["shared-items", "download", { monolith_tenant, uuid }],
      queryFn: ({ queryKey }) => CaseDrivesAPI.downloadFile(queryKey[2]),
      enabled: !!open,
    });

    const fileObject = data?.data?.[0] || {};
    const url = downloadInfo?.link || null;

    return (
      <div className={className}>
        <FileViewer
          loading={isLoading || !url}
          file={{
            file_name: fileObject?.shared_item?.name,
            url,
            file_size: fileObject?.shared_item?.size,
          }}
          open={open}
          handleClose={handleClose}
          handleFileDownload={handleFileDownload}
        />
      </div>
    );
  }
)``;

export default FileViewerComponent;
