import { createContext, useContext, useState } from "react";

const NewRequestContext = createContext();

const NewRequestProvider = ({ children }) => {
  const [formData, setFormData] = useState({});

  return (
    <NewRequestContext.Provider
      value={{
        formData,
        setFormData,
      }}
    >
      {children}
    </NewRequestContext.Provider>
  );
};

const useNewRequest = () => {
  const context = useContext(NewRequestContext);
  if (context === undefined) {
    throw new Error("useNewRequest must be used within a NewRequestProvider");
  }
  return context;
};

export { NewRequestProvider, useNewRequest };
