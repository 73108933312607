import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";
import TaskButton from "../../../Monolith-UI/TaskButton.js";

import KeyboardTabOutlinedIcon from "@mui/icons-material/KeyboardTabOutlined";
import SectionHeader from "../../../components/SectionHeader.js";
import DetailItem from "../../../components/DetailItem.js";
import moment from "moment";
import StatusIndicator from "../components/StatusIndicator.js";
import OverviewContent from "./sections/OverviewContent.js";
import EvidenceContent from "./sections/EvidenceContent.js";
import DocumentsContent from "./sections/DocumentsContent.js";
import PeopleContent from "./sections/PeopleContent.js";
import { useRequest } from "./RequestView.js";
import EditRequestModal from "../Modals/EditRequestModal.js";
import RequestService from "../../../api/requests/index.js";
import { useQueryClient } from "@tanstack/react-query";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const RequestOverview = styled(({ className, details }) => {
  return (
    <div className={className}>
      <SideContent details={details} />
      <MainContent details={details} />
    </div>
  );
})`
  // padding: 20px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  // height: 100%;
  min-height: calc(100vh - 200px);
`;

const SideContent = styled(({ className, details }) => {
  const { requestID } = useParams();

  const [open, setOpen] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const queryClient = useQueryClient();
  const { isEditable } = useRequest();

  const editRequest = (data) => {
    RequestService.updateRequest({
      ...data,
      request_id: details.uuid,
    }).then((res) => {
      queryClient.refetchQueries([
        "request",
        { request_id: requestID, data_only: true },
      ]);
    });
  };

  return (
    <div className={className + (open ? " expanded" : " collapsed")}>
      <div
        className={
          open
            ? "collapse-btn side-content-expanded"
            : "collapse-btn side-content-collapsed"
        }
      >
        <TaskButton
          variant="outlined"
          onClick={() => setOpen(!open)}
          style={{ marginLeft: "auto" }}
        >
          <KeyboardTabOutlinedIcon style={{ fontSize: 16 }} />
        </TaskButton>
      </div>
      {open && (
        <>
          {isEditable && (
            <div
              className="edit-request-btn"
              onClick={() => setShowEditModal(true)}
            >
              <EditOutlinedIcon /> Edit Request
            </div>
          )}
          <div style={{ marginBottom: 40 }}>
            <SectionHeader
              title="Request Status"
              description={"This is the current status of this request."}
            />
            <div
              style={{
                marginBottom: 15,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <StatusIndicator status={details.status} size="large" />
            </div>
          </div>
          <div style={{ marginBottom: 40 }}>
            <SectionHeader
              title="Requestor Info"
              description="Details related to the person who made this request."
            />
            <DetailItem
              label={"Name"}
              value={details?.requestor?.full_name || ""}
            />
            <DetailItem
              label={"Organization"}
              value={details?.requestor?.org || ""}
            />
            <DetailItem
              label={"Email"}
              value={details?.requestor?.email || ""}
            />
          </div>
          <div style={{ marginBottom: 40 }}>
            <SectionHeader
              title="Forensic Case Info"
              description="Details related to the forensic case associated with this request."
            />
            {details?.case_info ? (
              <>
                <DetailItem
                  label={"Case Name"}
                  value={details.case_info.case_ref}
                />
                <DetailItem
                  label={"Case Number"}
                  value={details.case_info.case_number}
                />
                <DetailItem
                  label={"Case Open Date"}
                  value={moment(details.case_info.case_open_date).format(
                    "MMMM, DD YYYY"
                  )}
                />
                <DetailItem
                  label={"Case Status"}
                  value={details.case_info.case_status}
                />
                <DetailItem
                  label={"Case Progress"}
                  value={details.case_info.progress}
                />
                <DetailItem
                  label={"Case Lead"}
                  value={
                    details?.case_info?.case_lead
                      ? `${details?.case_info?.case_lead?.full_name || ""}, ${
                          details?.case_info?.case_lead?.title || ""
                        }\n${
                          details?.case_info?.case_lead?.organization || ""
                        }\n${details?.case_info?.case_lead?.email || ""}`
                      : "No case lead assigned"
                  }
                />
              </>
            ) : (
              <div>A case has not been associated with this request.</div>
            )}
          </div>
          <div style={{ marginBottom: 40 }}>
            <SectionHeader
              title="Request Details"
              description="Specific details given by the requestor."
            />
            <DetailItem
              label={"Request Descriptor"}
              value={details.request_name}
            />
            <DetailItem
              label={"Case/Ref Number"}
              value={details.client_ref_number}
            />
            <DetailItem label={"Case Type"} value={details.case_type} />
            <DetailItem
              label={"Submitted On"}
              value={moment(details.created_at).format("MMMM, DD YYYY")}
            />
            <DetailItem
              label={"Submitted By"}
              value={`${details?.requestor?.full_name || ""}\n${
                details.requestor?.org || ""
              }\n${details.requestor?.email || ""}`}
            />
            <DetailItem
              label={"Submitted To"}
              value={details.relay_tenant.org}
            />
            <DetailItem label={"Request ID"} value={details.uuid} />
            {details?.custom_field_data?.map((field, index) => {
              if (field.editor_type === "dateBox")
                field.value = moment(field.value).format("MMMM, DD YYYY");

              return (
                <DetailItem
                  key={field.field_id}
                  label={field.field_name}
                  value={field.value}
                />
              );
            })}
          </div>
          {isEditable && (
            <EditRequestModal
              open={showEditModal}
              handleClose={() => setShowEditModal(false)}
              onSubmit={editRequest}
              defaultFormData={{
                client_ref_number: details.client_ref_number,
                case_type: details.case_type,
                custom_field_data: details.custom_field_data,
                description: details.description,
              }}
            />
          )}
        </>
      )}
    </div>
  );
})`
  position: relative;
  max-width: 425px;
  min-width: 425px;
  min-height: 350px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  // border-right: none;
  // border-radius: 4px 0px 0px 4px;
  border-radius: 4px;
  overflow-y: auto;
  overflow-x: auto;
  transition: all 0.1s ease-in-out;

  .edit-request-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    gap: 5px;
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 20px;
    width: fit-content;

    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.palette.text.primary};
    }

    svg {
      font-size: 1rem;
    }
  }

  &.collapsed {
    min-width: 50px;
    max-width: 50px;
  }

  & .collapse-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    align-content: center;
    // justify-content: space-between;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & .side-content-expanded {
    svg {
      transform: rotate(180deg);
    }
  }

  & .side-content-collapsed {
    right: 11px;
  }

  & .header {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 1.1rem;
    font-weight: 500;
    cursor: default;
  }
  & .header-container {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 20px 0px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    // margin-right: 12px;
    margin-left: auto;
    // padding: 3px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  & .detail-item {
    display: flex;
    align-items: baseline;
    align-content: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  & .detail-label {
    // font-weight: bold;
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 150px;
  }
  & .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
    width: 100%;
    white-space: pre-wrap;
  }
`;

const MainContent = styled(({ className, details }) => {
  const { inquiry_id } = useParams();
  const [currentTab, setCurrentTab] = useState(0);

  const buttonStyle = {
    minWidth: "150px",
    padding: "2px 5px",
  };

  const handleChange = (event, newGroup) => {
    if (newGroup !== null) {
      setCurrentTab(newGroup);
    }
  };

  const control = {
    value: currentTab,
    onChange: handleChange,
    exclusive: true,
  };

  useEffect(() => {
    setCurrentTab(0);
  }, [inquiry_id]);

  return (
    <div className={className}>
      <div className="toggle-menu">
        <ToggleButtonGroup size="small" {...control} style={{ margin: "auto" }}>
          <ToggleButton
            value={0}
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            Overview
          </ToggleButton>
          <ToggleButton
            value={1}
            size="small"
            disableRipple={true}
            style={buttonStyle}
            title="The following evidence items are associated with your request. If this
            request has been accepted by your forensic partner, then you should see
            the progress of that item."
          >
            <>My Evidence</>
          </ToggleButton>
          <ToggleButton
            value={2}
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            <>My Documents</>
          </ToggleButton>
          <ToggleButton
            value={3}
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            <>Contacts</>
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      {currentTab === 0 && <OverviewContent details={details} />}
      {currentTab === 1 && <EvidenceContent details={details} />}
      {currentTab === 2 && <DocumentsContent details={details} />}
      {currentTab === 3 && <PeopleContent details={details} />}
    </div>
  );
})`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px;
  flex-grow: 1;
  min-width: 0px;

  & .toggle-menu {
    margin: auto;
    margin-bottom: 25px;
    display: flex;
    overflow-x: auto;
  }
  & .header {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 1.1rem;
    font-weight: 500;
  }
  & .description {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 12px;
    margin-top: 10px;
  }
`;

export default RequestOverview;
