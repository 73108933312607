import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";

const getUsers = async (params) => httpGet(`/users`, params);
const createUser = async (values) => httpPost(`/users`, values);
const updateUser = async (values) => httpPatch(`/users`, values);
const deleteUser = async (user_email) => httpDelete(`/users/${user_email}`);
const requestTenantAccess = async (values) =>
  httpPost(`/users/request-tenant-access`, values);
const processUserInvite = async (values) => httpPost(`/process-invite`, values);
const processAccountSetup = async (values) =>
  httpPost(`/process-account-setup`, values);

const UserService = {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  requestTenantAccess,
  processUserInvite,
  processAccountSetup,
};

export default UserService;
