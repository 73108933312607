import styled from "styled-components";
import { Button, Typography, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import AddPeopleModal from "../Modals/AddPeopleModal.js";
import { useNewRequest } from "./NewRequestContext.js";
import { Edit } from "@mui/icons-material";
import EditPeopleModal from "../Modals/EditPeopleModal.js";

const People = styled(({ className }) => {
  const theme = useTheme();
  const { setFormData } = useNewRequest();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentPerson, setCurrentPerson] = useState(null);
  const [peopleList, setPeopleList] = useState([]);

  const addPerson = (item) => {
    setPeopleList((people) => [...people, item]);
  };

  const editPerson = (item) => {
    // replace current person object with updated person object
    setPeopleList((people) =>
      people.map((e) => (e.item_id === item.item_id ? item : e))
    );
  };

  const handleEditPerson = (item) => {
    setCurrentPerson(item);
    setShowEditModal(true);
  };

  const removePerson = (item) => {
    setPeopleList((people) => people.filter((e) => e.item_id !== item.item_id));

    // Clear linked contacts in evidence for removed person
    setFormData((prevData) => {
      const newEvidence = [...(prevData?.evidence || [])];

      newEvidence.forEach((evidence) => {
        if (evidence?.linked_contact?.item_id === item.item_id) {
          delete evidence.linked_contact;
        }
      });

      return {
        ...prevData,
        evidence: newEvidence,
      };
    });
  };

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      people: peopleList,
    }));
  }, [peopleList]);

  return (
    <div className={className}>
      <AddPeopleModal
        open={showAddModal}
        handleClose={() => setShowAddModal(false)}
        onSubmit={addPerson}
      />
      <div style={{ margin: "auto" }}>
        <Typography variant="h3" gutterBottom display="inline">
          Contacts
        </Typography>
        <div style={{ color: theme.palette.text.secondary, marginTop: 5 }}>
          Add people that are releated to this request and link them to evidence
          items they are associated with.
        </div>
        <div style={{ marginTop: 10, marginBottom: 20 }}>
          <div>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: 10 }}
              size="small"
              onClick={() => setShowAddModal(true)}
            >
              + Add Contact
            </Button>
          </div>
        </div>
        <PersonList
          peopleList={peopleList}
          onRemove={removePerson}
          onEdit={handleEditPerson}
        />
      </div>
      {currentPerson && (
        <EditPeopleModal
          open={showEditModal}
          handleClose={() => setShowEditModal(false)}
          onSubmit={editPerson}
          defaultFormData={currentPerson}
        />
      )}
    </div>
  );
})``;

const PersonItem = styled(({ className, item, onRemove, onEdit }) => {
  return (
    <div className={className}>
      <div className="left-section">
        <PersonOutlinedIcon />
      </div>
      <div className="middle-section">
        <div className="title">{item.person_name}</div>
        <div className="sub-title">{item.person_type}</div>
      </div>
      <div className="right-section">
        <div className="edit-btn" onClick={() => onEdit(item)}>
          <div className="btn-title">Edit Person</div>
          <Edit color="primary" />
        </div>
        <div className="remove-btn" onClick={() => onRemove(item)}>
          <div className="btn-title">Remove Contact</div>
          <ClearOutlinedIcon />
        </div>
      </div>
    </div>
  );
})`
  padding: 15px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 5px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;

  .left-section {
    display: flex;
  }

  .middle-section {
    .title {
      font-size: 12px;
      font-weight: 600;
    }
    .sub-title {
      color: ${(props) => props.theme.palette.text.secondary};
      font-size: 12px;
      font-weight: 600;
    }
  }

  .right-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
    margin-left: auto;
    user-select: none;

    svg {
      font-size: 14px;
    }

    .remove-btn {
      cursor: pointer;
      font-size: 10px;
      font-weight: 600;
      color: orangered;
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;

      &:hover svg,
      &:hover .btn-title {
        color: orangered;
        text-decoration: underline;
      }
    }
  }

  .edit-btn {
    cursor: pointer;
    font-size: 10px;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.text.primary};
    display: flex;
    align-items: center;
    gap: 5px;

    &:hover svg,
    &:hover .btn-title {
      color: ${({ theme }) => theme.palette.primary.main};
      text-decoration: underline;
    }
  }

  transition: all 0.1s ease-in-out;
  &:hover {
    border: 1px solid ${(props) => props.theme.palette.primary.main};
  }
`;

const PersonList = styled(({ className, peopleList, onRemove, onEdit }) => {
  return (
    <div className={className}>
      {peopleList.map((item) => (
        <PersonItem
          key={item.item_id}
          item={item}
          onRemove={onRemove}
          onEdit={onEdit}
        />
      ))}
    </div>
  );
})`
  & .view-btn {
    cursor: pointer;
    font-size: 10px;
    color: ${({ theme }) => theme.palette.text.secondary};
    display: flex;
    align-items: center;
    &:hover,
    &:hover svg,
    &:hover .view-btn-title {
      color: ${({ theme }) => theme.palette.primary.main};
      text-decoration: underline;
    }
  }
  & .view-btn-title {
    margin-right: 5px;
  }
`;

export default People;
