import styled from "styled-components";

const ImageViewer = styled(({ className, file }) => {
  return (
    <div className={className}>
      <img src={file.url} alt={file.file_name} />
    </div>
  );
})`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: calc(100vh - 25px);
  width: 100%;
  max-width: 8.5in;
  overflow: auto;
  margin: auto;

  & img {
    max-width: 100%;
    max-height: 80vh;
  }
`;

export default ImageViewer;
