import styled from "styled-components";
import { usePagination } from "../hooks/use-pagination.js";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const icons = {
  dots: <MoreHorizIcon style={{ fontSize: 14 }} />,
  next: <NavigateNextIcon style={{ fontSize: 14 }} />,
  prev: <NavigateNextIcon style={{ rotate: "180deg", fontSize: 14 }} />,
  first: <KeyboardDoubleArrowLeftIcon style={{ fontSize: 14 }} />,
  last: <KeyboardDoubleArrowRightIcon style={{ fontSize: 14 }} />,
};

const Pagination = styled(
  ({
    className,
    alignment = "center",
    page,
    siblings,
    total,
    onChange,
    initialPage,
    boundaries,
    disabled,
    withEdges,
    withControls,
  }) => {
    const { range, setPage, next, previous, active, first, last } =
      usePagination({
        page,
        siblings,
        total,
        onChange,
        initialPage,
        boundaries,
      });

    if (total <= 0) {
      return null;
    }

    const items = range.map((pageNumber, index) => (
      <Item
        key={index}
        page={pageNumber}
        active={pageNumber === active}
        tabIndex={pageNumber === "dots" ? -1 : 0}
        data-dots={pageNumber === "dots" || undefined}
        data-active={pageNumber === active || undefined}
        onClick={
          pageNumber !== "dots"
            ? () => {
                setPage(pageNumber);
              }
            : undefined
        }
        disabled={disabled}
      />
    ));

    return (
      <div className={className}>
        {withEdges && (
          <Item
            page="first"
            onClick={first}
            disabled={active === 1 || disabled}
          />
        )}
        {withControls && (
          <Item
            page="prev"
            onClick={previous}
            disabled={active === 1 || disabled}
          />
        )}
        {items}
        {withControls && (
          <Item
            page="next"
            onClick={next}
            disabled={active === total || disabled}
          />
        )}

        {withEdges && (
          <Item
            page="last"
            onClick={last}
            disabled={active === total || disabled}
          />
        )}
      </div>
    );
  }
)`
  display: flex;
  justify-content: ${(props) => props.alignment};
  align-items: center;
  user-select: none;
  font-size: 10px;
`;

const Item = styled(({ className, page, active, ...others }) => {
  const children = icons[page] || page;

  return (
    <div className={className + `${active ? " active" : ""}`} {...others}>
      {children}
    </div>
  );
})`
  min-width: 25px;
  padding: 5px;
  margin: 3px;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.active {
    border-color: ${(props) => props.theme.palette.primary.main};
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.action.hover};
  }
`;

Pagination.displayName = "Pagination";

export default Pagination;
