// import "@material-ui/lab/themeAugmentation";

import { createTheme as createMuiTheme } from "@mui/material/styles";
import variants from "./variants";
import typography from "./typography";
import breakpoints from "./breakpoints";
import components from "./components";
import shadows from "./shadows";
import { PaletteOptions } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

const createTheme = (name: String) => {
  let themeConfig = variants.find((variant) => variant.name === name);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = variants[0];
  }

  const componentsStyled = {
    ...components,
    MuiButton: {
      variants: [
        {
          props: { variant: "outlined-filled" },
          style: {
            border: "1px solid #CCC",
            color: themeConfig.palette.secondary.main,
            backgroundColor: themeConfig.palette.background.paper,
          },
        },
      ],
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          minWidth: 150,
          backgroundColor: themeConfig.palette.menu.background,
          border: `1px solid ${
            themeConfig.name === "DARK"
              ? "rgba(255, 255, 255, 0.12)"
              : "rgba(0, 0, 0, 0.12)"
          }`,
        },
      },
    },
  };

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      // @ts-ignore
      components: componentsStyled,
      typography: typography as TypographyOptions,
      // shadows: shadows,
      palette: themeConfig.palette as PaletteOptions,
      zIndex: {
        mobileStepper: 1800,
        fab: 1801,
        speedDial: 1802,
        appBar: 1803,
        modal: 1500,
        snackbar: 1806,
        tooltip: 1807,
      },
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
      scrollbar: themeConfig.scrollbar,
      mfBorder: themeConfig.mfBorder,
      mfTable: themeConfig.mfTable,
    }
  );
};

export default createTheme;
