import styled from "styled-components";

const DetailItem = styled(({ className, label, value }) => {
  return (
    <div className={className}>
      <div className="request-detail-label">{label}</div>
      <div className="request-detail-value">{value}</div>
    </div>
  );
})`
  display: flex;
  margin-bottom: 10px;
  .request-detail-label {
    font-size: 12px;
    color: ${(props) => props.theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 125px;
    width: 125px;
  }
  .request-detail-value {
    font-size: 12px;
    white-space: pre-line;
  }
`;

export default DetailItem;
