import styled from "styled-components";
import { Button, Typography } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TenantService from "../../api/tenants";
import UserInvitesAPI from "../../api/UserInvites/index.js";
import Loader from "../../components/Loader.js";
import { useAuth } from "../../contexts/AuthContext";
import HowToPresenter from "./components/Instructions";
import OrgHeader from "./components/OrgHeader";
import MonolithCaseService from "../../api/Monolith/Cases/index.js";

const RequestForm = styled(({ className }) => {
  const { currentUser } = useAuth();
  const { tenantSlug } = useParams();
  const queryClient = useQueryClient();

  const hasAccess = currentUser.tenants.find(
    (tenant) => tenant.tenant_slug === tenantSlug
  );

  const monolithTenant = currentUser.tenants.find(
    (tenant) => tenant.tenant_slug === tenantSlug
  );

  const navigate = useNavigate();

  const { isLoading, data } = useQuery({
    queryKey: ["orgInfo", tenantSlug],
    queryFn: () => TenantService.getOrgInfo({ slug: tenantSlug }),
  });

  const [orgInfo] = data || [];

  const handleSubmitRequest = () => {
    navigate(`/${tenantSlug}/new-request`);
  };

  if (!orgInfo) return <Loader />;

  // Prefetch Case field data once orgInfo is loaded
  // This helps with faster rendering of the request form once loaded
  queryClient.prefetchQuery(
    [
      "cases:elements",
      {
        monolith_tenant: monolithTenant?.monolith_tenant,
        include_types: true,
        include_custom_fields: true,
      },
    ],
    () =>
      MonolithCaseService.getCaseElements({
        monolith_tenant: monolithTenant.monolith_tenant,
        include_types: true,
        include_custom_fields: true,
      }),
    {
      enabled: !!monolithTenant && !isLoading,
    }
  );

  return (
    <div className={className}>
      <div className="content">
        {orgInfo && (
          <OrgHeader
            orgInfo={orgInfo}
            showMessage={false}
            showOrgName={false}
          />
        )}
        <h1>{orgInfo.org}</h1>

        {hasAccess && (
          <>
            <div className="message">
              Submit and manage requests for digital forensic services.
            </div>
            <div className="action-btns">
              <div className="view-request-btn">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleSubmitRequest}
                >
                  Create New Request
                </Button>
              </div>
            </div>
            <div className="new-request-box">
              <HowToPresenter orgInfo={orgInfo} />
            </div>
          </>
        )}
        {!hasAccess && <NoTenantAccess orgInfo={orgInfo} />}
      </div>
    </div>
  );
})`
  position: relative;
  // padding-top: 100px;
  .content {
    // light box shadow
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    margin: auto auto;
    border-radius: 5px;
    // width: 500px;
    height: auto;
    padding: 20px;
    padding-bottom: 40px;
    text-align: center;
    background-color: ${({ theme }) => theme.palette.background.alt};
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 5px;
    .message {
      color: ${({ theme }) => theme.palette.text.secondary};
      font-weight: 400;
      line-height: 1.5;
      font-size: 1rem;
    }
    .action-btns {
      margin-top: 40px;
    }
    .view-request-btn {
      margin-bottom: 20px;
    }
    .new-request-box {
      margin-top: 40px;
      padding: 20px 10px;
      border-radius: 5px;
      font-weight: 400;
      line-height: 1.5;
      //   font-size: 1rem;
      color: ${({ theme }) => theme.palette.text.primary};
      background-color: ${({ theme }) => theme.palette.background.default};
    }
  }
`;

const NoTenantAccess = styled(({ className, orgInfo }) => {
  const { currentUser } = useAuth();
  const { tenantSlug } = useParams();
  const [currentView, setCurrentView] = useState("requestAccess");

  const handleRequestAccess = () => {
    UserInvitesAPI.requestTenantAccess({
      tenant_uuid: orgInfo.uuid,
      email: currentUser.email,
    });
    setCurrentView("accessRequested");
  };

  const { isLoading, data: userInvites } = useQuery({
    queryKey: ["users:invites", { email: currentUser.email }],
    queryFn: () => UserInvitesAPI.getUserInvites({ email: currentUser.email }),
  });

  const hasInvite = userInvites?.find(
    (invite) => invite.tenant_slug === tenantSlug
  );

  if (isLoading) return <Loader />;

  return (
    <div className={className}>
      {currentView === "requestAccess" && (
        <>
          <div className="message">
            You do not have access to this organization's Relay services.
          </div>
          {hasInvite && (
            <div style={{ marginTop: 15 }}>
              <div>
                You have requested access to this tenant and will be notified
                when granted access.
              </div>
              <div>Contact {orgInfo?.tenant_email} about your access.</div>
            </div>
          )}
          {!hasInvite && (
            <div className="action-btns">
              <div className="view-request-btn">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleRequestAccess}
                >
                  Request Access
                </Button>
              </div>
            </div>
          )}
        </>
      )}
      {currentView === "accessRequested" && (
        <>
          <Typography variant="h2" gutterBottom display="inline">
            We sent your request to your Relay admin.
          </Typography>
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div className="message">
              You'll be notified once you are granted access.
            </div>
          </div>
        </>
      )}
    </div>
  );
})``;

export default RequestForm;
