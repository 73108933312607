import styled from "styled-components";
import { Button, CssBaseline, Typography } from "@mui/material";
import { Form, SimpleItem, EmptyItem } from "devextreme-react/ui/form";
import { useNavigate } from "react-router-dom";
import GlobalStyle from "../../components/GlobalStyle.js";
import Loader from "../../components/Loader.js";
import { useAuth } from "../../contexts/AuthContext.js";
import { useRef } from "react";
import UserService from "../../api/users/users.js";
import { useQuery } from "@tanstack/react-query";
import TenantService from "../../api/tenants/index.js";

const AccountSetup = styled(({ className }) => {
  const { currentUser } = useAuth();

  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get("redirect");
  const slug = searchParams.get("slug");

  const { data, isFetched } = useQuery({
    queryKey: ["user-signup", slug],
    queryFn: () => TenantService.getOrgInfo({ slug }),
    enabled: !!slug,
  });

  if (!currentUser && !isFetched) return <Loader />;

  const orgInfo = data?.[0];

  return (
    <div className={className}>
      <div className="content">
        <h1>Welcome to Relay!</h1>
        {orgInfo && (
          <div className="message">
            You're requesting access to submit forensic requests to{" "}
            <span>{orgInfo.org}</span>.
          </div>
        )}
        <div className="message">
          You're signing up as <span>{currentUser.email}</span>.
        </div>
        <div className="message">
          We need a few more details to get started.
        </div>
        <AccountDetailsForm
          inviteInfo={currentUser}
          redirect={redirect}
          orgInfo={orgInfo}
        />
      </div>
    </div>
  );
})`
  padding: 2rem;
  color: ${(props) => props.theme.palette.text.primary};
  display: flex;
  .content {
    padding: 2rem;
    padding-top: 1rem;
    padding-bottom: 3rem;
    margin: auto;
    background-color: ${(props) => props.theme.palette.background.alt};
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
  .message {
    font-weight: 400;
    line-height: 1.5;
    font-size: 1rem;
    margin-top: 10px;

    span {
      font-weight: 600;
      color: #2196f3;
    }
  }
`;

const AccountDetailsForm = styled(({ className, redirect, orgInfo = {} }) => {
  const { currentUser, setSessionData } = useAuth();
  const userForm = useRef(null);
  const orgForm = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (!userForm.current.instance.validate().isValid) return;
    if (!orgForm.current.instance.validate().isValid) return;

    const userFormData = userForm.current.instance.option("formData");
    const orgFormData = orgForm.current.instance.option("formData");

    UserService.processAccountSetup({
      ...userFormData,
      ...orgFormData,
      email: currentUser.email,
      tenant_uuid: orgInfo?.uuid || null,
    }).then((res) => {
      setSessionData({ email: currentUser.email }).then(() => {
        window.localStorage.removeItem("post-signup-data");
        navigate(`/${orgInfo?.slug || ""}`);
      });
    });
  };

  return (
    <div className={className}>
      <div style={{ marginTop: 40, marginBottom: 20, textAlign: "left" }}>
        <Typography variant="h3" gutterBottom display="inline">
          Whats your name?
        </Typography>
        <div className="sub-title">This will help with making requests.</div>
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            textAlign: "left",
          }}
        >
          <Form ref={userForm} colCount={2}>
            <SimpleItem
              dataField="first_name"
              isRequired={true}
              label={{ visible: false }}
              editorOptions={{ placeholder: "First Name" }}
            />
            <SimpleItem
              dataField="last_name"
              isRequired={true}
              label={{ visible: false }}
              editorOptions={{ placeholder: "Last Name" }}
            />
          </Form>
        </div>
      </div>
      <div style={{ marginTop: 40, textAlign: "left" }}>
        <Typography variant="h3" gutterBottom display="inline">
          Tell us about your organization.
        </Typography>
        <div className="sub-title">What agency or company do you work for?</div>
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            textAlign: "left",
          }}
        >
          <Form ref={orgForm} colCount={2}>
            <SimpleItem
              dataField="org"
              isRequired={true}
              label={{ visible: false }}
              editorOptions={{ placeholder: "Organization Name" }}
            />
            <EmptyItem />
            <SimpleItem
              dataField="address"
              isRequired={true}
              colSpan={2}
              label={{ visible: false }}
              editorOptions={{ placeholder: "Address" }}
            />
            <SimpleItem
              dataField="city"
              isRequired={true}
              label={{ visible: false }}
              editorOptions={{ placeholder: "City" }}
            />
            <SimpleItem
              dataField="state"
              isRequired={true}
              label={{ visible: false }}
              editorOptions={{ placeholder: "State/Province" }}
            />
            <SimpleItem
              dataField="zipcode"
              isRequired={true}
              label={{ visible: false }}
              editorOptions={{ placeholder: "Postal Code" }}
            />
            <SimpleItem
              dataField="phone"
              isRequired={true}
              label={{ visible: false }}
              editorOptions={{ placeholder: "Phone Number" }}
            />
          </Form>
        </div>
      </div>
      <div className="action-btns">
        <div className="view-request-btn">
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSubmit}
          >
            Complete Account Setup
          </Button>
        </div>
      </div>
    </div>
  );
})`
  .sub-title {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-weight: 400;
    line-height: 1.5;
  }
  .action-btns {
    display: flex;
    margin-top: 40px;
  }
  .view-request-btn {
    margin-left: auto;
  }
`;

export default AccountSetup;
