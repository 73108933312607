import styled from "styled-components";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import ItemBox from "../../../../components/ItemBox.js";

const AcquisitionList = styled(({ className, evidenceInfo }) => {
  const { requestID, tenantSlug, evidenceUUID } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClick = (uuid) => {
    // navigate(`/requests/${tenantSlug}/${requestID}/${uuid}`);
    setOpen(!open);
  };

  if (
    !evidenceInfo.acquisition_list ||
    evidenceInfo.acquisition_list.length === 0
  )
    return (
      <div style={{ marginTop: 10, marginBottom: 20 }}>No acquisitions</div>
    );

  return (
    <div className={className} style={{ marginTop: 10, marginBottom: 20 }}>
      {evidenceInfo.acquisition_list.map((item, index) => {
        return (
          <ItemBox
            key={index}
            title={item.acq_name}
            subTitle={
              <>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 5 }}>
                    Acquired {moment.utc(item.acquire_date).fromNow()}
                  </div>
                  {item.acquired_by && (
                    <>
                      <span>|</span>
                      <div style={{ marginLeft: 5 }}>
                        Acquired By {item.acquired_by}
                      </div>
                    </>
                  )}
                </div>
              </>
            }
            startIcon={<ContentCopyOutlinedIcon />}
            endIcon={
              <div className="view-btn" onClick={() => handleClick()}>
                <div className="view-btn-title">View Details</div>
                <ArrowDownwardOutlinedIcon
                  style={{
                    //rotate
                    transform: !open ? "rotate(-90deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease-in-out",
                  }}
                />
              </div>
            }
          >
            {open && <Details acquisition={item} />}
          </ItemBox>
        );
      })}
    </div>
  );
})`
  & .view-btn {
    cursor: pointer;
    font-size: 10px;
    color: ${({ theme }) => theme.palette.text.secondary};
    display: flex;
    align-items: center;
    &:hover,
    &:hover svg,
    &:hover .view-btn-title {
      color: ${({ theme }) => theme.palette.primary.main};
      text-decoration: underline;
    }
  }
  & .view-btn-title {
    margin-right: 5px;
  }
`;

const Details = styled(({ className, acquisition }) => {
  return (
    <div className={className}>
      <DetailItem label="Name" value={acquisition.acq_name} />
      <DetailItem
        label="Acquired By"
        value={`${acquisition.acquired_by}, ${acquisition.acquired_by_title}\n${acquisition.acquired_by_email}`}
      />
      <DetailItem
        label="Acquired Date"
        value={moment
          .utc(acquisition.acquire_date)
          .local()
          .format("MMMM DD, YYYY HH:mm:ss")}
      />
      <DetailItem
        label="Size"
        value={
          acquisition.size ? `${acquisition.size} ${acquisition.size_unit}` : ""
        }
      />
      <DetailItem label="Linked Contact" value={acquisition.linked_contact} />
      {acquisition.hash_1 && (
        <DetailItem
          label={acquisition.hash_1.split("::")[0]}
          value={acquisition.hash_1.split("::")[1]}
        />
      )}
      {acquisition.has_2 && (
        <DetailItem
          label={acquisition.hash_2.split("::")[0]}
          value={acquisition.hash_2.split("::")[1]}
        />
      )}
      <DetailItem label="Description" value={acquisition.acq_notes} />
    </div>
  );
})`
  margin-top: 25px;
`;

export const DetailItem = styled(({ className, label, value, ...props }) => {
  return (
    <div className={className} style={{ ...props?.style }}>
      <div className="request-detail-label">{label}</div>
      <div className="request-detail-value">{value}</div>
    </div>
  );
})`
  display: flex;
  margin-bottom: 8px;
  font-size: 10px;
  .request-detail-label {
    color: ${(props) => props.theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 100px;
  }
  .request-detail-value {
    color: ${(props) => props.theme.palette.text.primary};
    white-space: pre-line;
  }
`;

export default AcquisitionList;
