import styled from "styled-components";

const StatusIndicator = styled(({ className, status, size }) => {
  return (
    <div className={className + " " + status.toLowerCase()}>
      <div>{status}</div>
    </div>
  );
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 3px 6px;
  border-radius: 4px;
  font-size: ${(props) =>
    props.size === "small" ? "10px" : props.size === "large" ? "11px" : "10px"};
  font-weight: 600;
  color: white;
  background-color: ${(props) => props.theme.palette.background.paper};
  &.pending {
    color: white;
    background-color: ${(props) => props.theme.palette.warning.dark};
  }
  &.accepted {
    color: white;
    background-color: #1d741d;
  }
  &.declined {
    color: white;
    background-color: ${(props) => props.theme.palette.error.dark};
  }
`;

export default StatusIndicator;
