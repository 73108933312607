import { Modal } from "@mui/material";
import { useTheme } from "styled-components";
import ToolBarItems from "../../../components/ToolBarItems.js";
import { DetailItem } from "../EvidenceView/components/AcquisitionList.js";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const DeletePeopleModal = ({
  handleClose,
  open,
  onSubmit,
  defaultFormData,
}) => {
  const theme = useTheme();

  const handleSubmit = () => {
    handleClose();

    if (onSubmit)
      onSubmit({
        ...defaultFormData,
      });
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        handleClose();
      }}
      disableEnforceFocus={true}
      style={{
        userSelect: "none",
        zIndex: 1400,
      }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 500,
          maxHeight: "90vh",
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 250px)",
          overflowY: "auto",
          padding: 20,
        }}
      >
        <div style={{ marginBottom: 20 }}>
          <div
            style={{
              marginBottom: 20,
              display: "flex",
              flexDirection: "row",
              gap: 5,
              alignItems: "center",
              fontSize: "1.25rem",
              fontWeight: 600,
            }}
          >
            <DeleteOutlinedIcon color="error" /> Delete Contact
          </div>
        </div>
        <div style={{ marginBottom: 20 }}>
          Are you sure you want to delete this person from your request?
        </div>
        <div style={{ marginBottom: 20 }}>
          {defaultFormData.person_name && (
            <DetailItem
              label="Name"
              value={defaultFormData.person_name}
              style={{ fontSize: "0.75rem" }}
            />
          )}
          {defaultFormData.person_type && (
            <DetailItem
              label="Type"
              value={defaultFormData.person_type}
              style={{ fontSize: "0.75rem" }}
            />
          )}
          {defaultFormData.unique_id && (
            <DetailItem
              label="Unqiue ID"
              value={defaultFormData.unique_id}
              style={{ fontSize: "0.75rem" }}
            />
          )}
        </div>
        <ToolBarItems
          submitText="Delete Contact"
          submitColor="error"
          onSubmit={handleSubmit}
          onCancel={handleClose}
          style={{ marginTop: 20 }}
        />
      </div>
    </Modal>
  );
};

export default DeletePeopleModal;
