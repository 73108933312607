import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import { useState } from "react";

const ToolBarItems = ({
  submitText = "Submit",
  submitColor = "primary",
  cancelText = "Cancel",
  showCancel = true,
  showSubmit = true,
  onCancel = () => {},
  onSubmit = () => {},
  style = {},
}) => {
  // const location = useLocation();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div
        style={{
          marginTop: "auto",
          display: "flex",
          justifyContent: "flex-end",
          ...style,
        }}
      >
        {!loading && showCancel && (
          <Button
            style={{ marginRight: "10px" }}
            size="small"
            disabled={loading}
            onClick={onCancel}
          >
            {cancelText}
          </Button>
        )}
        {showSubmit && (
          <LoadingButton
            loading={loading}
            //   loadingPosition="start"
            //   startIcon={<SaveIcon />}
            variant="contained"
            color={submitColor}
            size="small"
            onClick={(event) => {
              setLoading(true);
              if (onSubmit(event) === true) {
                setLoading(false);
              } else {
                setLoading(false);
              }
            }}
          >
            {submitText}
          </LoadingButton>
        )}
      </div>
    </>
  );
};

export default ToolBarItems;
