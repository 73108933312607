import { Button, Divider, Typography, useTheme } from "@mui/material";
import { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate, useParams } from "react-router-dom";
import shortUUID from "short-uuid";
import RequestService from "../../../api/requests/index.js";
import Loader from "../../../components/Loader.js";
import { useAuth } from "../../../contexts/AuthContext.js";
import CaseDetails from "./CaseDetails.js";
import EvidenceItems from "./EvidenceItems.js";
import FileAttachments from "./FileAttachments.js";
import People from "./People.js";
import styled from "styled-components";
import PageHeader from "../../../components/PageHeader.js";
import TaskButton from "../../../Monolith-UI/TaskButton.js";

import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";
import { NewRequestProvider, useNewRequest } from "./NewRequestContext.js";

const TenantName = styled.span`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: 500;
  text-transform: uppercase;
`;

const NewRequest = styled(({ className }) => {
  const { currentUser } = useAuth();
  const { tenantSlug } = useParams();
  const [requestID] = useState(shortUUID.generate());

  const monolithTenant = currentUser.tenants.find(
    (tenant) => tenant.tenant_slug === tenantSlug
  );

  return (
    <div className={className}>
      <Helmet title="New Request" />
      <div>
        <div style={{ margin: "auto", maxWidth: 1000 }}>
          <PageHeader>
            <Link to={-1}>
              <TaskButton title={"Back to Previous Page"} variant="outlined">
                <KeyboardReturnOutlinedIcon style={{ fontSize: 20 }} />
              </TaskButton>
            </Link>
            <PageHeader.Title2>
              Submit a New Request
              <PageHeader.SubTitle>
                Submit a new request to the{" "}
                <TenantName>{monolithTenant.tenant_org}</TenantName> team.
              </PageHeader.SubTitle>
            </PageHeader.Title2>
          </PageHeader>
        </div>
      </div>

      <div
        style={{
          overflowY: "auto",
        }}
      >
        <NewRequestProvider>
          <NewRequestContent requestID={requestID} />
        </NewRequestProvider>
        <div style={{ height: 250 }}></div>
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
`;

const NewRequestContent = styled(({ className, requestID }) => {
  const { formData } = useNewRequest();
  const { currentUser } = useAuth();
  const { tenantSlug } = useParams();
  const theme = useTheme();
  // const getCaseDetails = useRef(() => {});
  const validateCaseDetails = useRef(() => {});
  // const getFiles = useRef(() => {});
  // const getEvidenceList = useRef(() => {});
  // const getPeopleList = useRef(() => {});
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const monolithTenant = currentUser.tenants.find(
    (tenant) => tenant.tenant_slug === tenantSlug
  );

  const handleSubmit = () => {
    if (validateCaseDetails.current().isValid) {
      const requestObject = {
        ...(formData?.caseDetails || {}),
        uuid: requestID,
        tenant_uuid: monolithTenant.tenant_uuid,
        files: formData?.files || [],
        evidence: formData?.evidence || [],
        people: formData?.people || [],
        tenant_id: monolithTenant.tenant_id,
        email_target: monolithTenant.tenant_email,
        tenant_slug: tenantSlug,
      };

      setIsSubmitting(true);

      RequestService.createFormSubmission(requestObject)
        .then((res) => {
          // If no response, show error message
          if (!res) {
            setIsSubmitting(false);
            alert(
              "Error submitting request. Please check your internet connection and try again."
            );
            return;
          }
          // show success snackbar message
          // redirect to requests page or show success page
          navigate(`submitted?requestID=${requestID}`);
        })
        .catch((err) => {
          console.error("Error submitting request", err);
          setIsSubmitting(false);
          alert(
            "Error submitting request. Please check your internet connection and try again."
          );
        });
    }
  };

  return (
    <div className={className}>
      {!isSubmitting && (
        <>
          <CaseDetails
            requestID={requestID}
            validateData={validateCaseDetails}
          ></CaseDetails>
          <Divider style={{ margin: "25px 0px" }} />
          <FileAttachments requestID={requestID} onUploading={setIsUploading} />
          <Divider style={{ margin: "25px 0px" }} />
          <>
            <EvidenceItems requestID={requestID} />
          </>
          <Divider style={{ margin: "25px 0px" }} />
          <>
            <People requestID={requestID} />
          </>
          <Divider style={{ margin: "25px 0px" }} />
          <div
            style={{
              textAlign: "center",
              backgroundColor: theme.palette.background.alt,
              padding: 20,
              borderRadius: 5,
              // marginBottom: 40,
            }}
          >
            <Typography variant="h3" gutterBottom display="inline">
              Submit Your Request
            </Typography>
            <div style={{ color: theme.palette.text.secondary, marginTop: 5 }}>
              If ready, submit your request to{" "}
              <TenantName>{monolithTenant.tenant_org}</TenantName>.
            </div>
            <div
              style={{
                marginTop: 15,
                marginBottom: 20,
                display: "flex",
                justifyContent: "center",
                // minHeight: 200,
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={handleSubmit}
                disabled={isUploading}
                style={{ margin: "20px auto auto auto" }}
              >
                Submit Request
              </Button>
            </div>
          </div>
        </>
      )}
      {isSubmitting && (
        <>
          <div
            style={{
              textAlign: "center",
              backgroundColor: theme.palette.background.alt,
              padding: 20,
              borderRadius: 5,
              marginBottom: 40,
            }}
          >
            <Typography variant="h3" gutterBottom display="inline">
              Submitting Request...
            </Typography>
            <div style={{ marginTop: 45 }}>
              <Loader />
            </div>
          </div>
        </>
      )}
    </div>
  );
})`
  margin: auto;
  max-width: 1000px;
  padding: 3rem 4rem;
  background-color: ${(props) => props.theme.palette.background.alt};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palette.divider};
`;

export default NewRequest;
