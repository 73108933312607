import { httpPost } from "../connection";

async function createLog(values = {}) {
  return await httpPost(`/logs`, values);
}

const LoggingAPI = {
  createLog,
};

export default LoggingAPI;
