import { useRef } from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";

export const StyledInput = styled.input`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  pointer-events: "all";
  user-select: "all";
  outline: none;

  color: ${(props) => props.theme.palette.text.primary};
  font-size: ${({ size }) =>
    size === "xs"
      ? "12px"
      : size === "sm"
      ? "14px"
      : size === "md"
      ? "16px"
      : size === "lg"
      ? "18px"
      : size === "xl"
      ? "20px"
      : "12px"};

  height: ${({ size }) =>
    size === "xs"
      ? "26px"
      : size === "sm"
      ? "30px"
      : size === "md"
      ? "36px"
      : size === "lg"
      ? "42px"
      : size === "xl"
      ? "50px"
      : "26px"};

  min-height: ${({ size }) =>
    size === "xs"
      ? "26px"
      : size === "sm"
      ? "30px"
      : size === "md"
      ? "36px"
      : size === "lg"
      ? "42px"
      : size === "xl"
      ? "50px"
      : "26px"};

  border-radius: 5px;
  border: 1px solid
    ${({ theme, variant }) => {
      switch (variant) {
        case "contained":
          return "transparent";
        case "filled":
          return "transparent";
        case "outlined":
          return "transparent";
        case "text":
          return "transparent";
        default:
          return theme.palette.input.border;
      }
    }};

  padding: ${({ size }) =>
    size === "xs"
      ? "0px 8px"
      : size === "sm"
      ? "0px 10px"
      : size === "md"
      ? "0px 12px"
      : size === "lg"
      ? "0px 14px"
      : size === "xl"
      ? "0px 16px"
      : "0px 8px"};

  width: ${({ width }) => {
    if (typeof width === "undefined") return "100%";
    if (width === null) return "100%";
    if (typeof width === "string") return width;
    if (typeof width === "number") return `${width}px`;
  }};

  background-color: ${({ theme, variant }) => {
    switch (variant) {
      case "contained":
        return theme.palette.background.secondary;
      case "filled":
        return theme.palette.background.secondary;
      case "outlined":
        return theme.palette.input.background;
      case "text":
        return "transparent";
      default:
        return theme.palette.background.secondary;
    }
  }};

  transition: border 0.1s ease-in-out;

  ::placeholder {
    color: ${(props) => props.theme.palette.input.placeholder};
  }
  // &:hover {
  //   border: 1px solid ${(props) => props.theme.palette.input.border};
  // }
  &:focus {
    border: 1px solid ${(props) => props.theme.palette.primary.main};
  }
`;

const Input = styled(
  ({
    className,
    fieldName = Date.now(),
    style = {},
    inputStyle = {},
    labelStyle = {},
    placeholder = "",
    labelName = null,
    readOnly = false,
    value = "",
    defaultValue = "",
    type = "text",
    inputRef = useRef(null),
    min = null,
    max = null,
    disabled = false,
    autoFocus = false,
    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
    onKeyPress = () => {},
    onKeyDown = () => {},
    showBorder = true,
  }) => {
    const [inputValue, setInputValue] = useState(
      !!defaultValue ? defaultValue : ""
    );

    const handleChange = (e) => {
      onChange({
        event: e,
        fieldName: fieldName,
        newValue: !!e.target.value ? e.target.value : null,
        previousValue: inputValue,
      });

      setInputValue(e.target.value);
    };

    const handleBlur = (e) => {
      onBlur({
        event: e,
        fieldName: fieldName,
        newValue: !!e.target.value ? e.target.value : null,
        previousValue: inputValue,
      });
    };

    useEffect(() => {
      setInputValue(defaultValue);
    }, [defaultValue]);

    return (
      <div className={className} style={{ ...style }}>
        {labelName && (
          <InputLabel
            labelName={labelName}
            style={{ ...labelStyle }}
          ></InputLabel>
        )}
        <input
          ref={inputRef}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={handleBlur}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          type={type}
          readOnly={readOnly}
          placeholder={placeholder}
          value={inputValue}
          autoFocus={autoFocus}
          min={min}
          max={999}
          // style={{ ...inputStyle }}
        ></input>
      </div>
    );
  }
)((props) => ({
  "& input": {
    fontSize: props?.inputStyle?.fontSize || 12,
    border:
      props?.inputStyle?.border ||
      `1px solid ${props.theme.palette.input.border}`,
    color: props.theme.palette.text.primary,
    outline: "none",
    padding: props?.inputStyle?.padding || "8px 8px",
    width: props?.inputStyle?.width || "100%",
    height: props?.inputStyle?.height || "100%",
    backgroundColor:
      props?.inputStyle?.backgroundColor ||
      props.theme.palette.input.background,
    borderRadius: props?.inputStyle?.borderRadius || 5,
    transition: "border 0.1s ease-in-out",
    "::placeholder": {
      color: props.theme.palette.input.placeholder,
    },
    "&:hover": {
      border:
        props?.inputStyle?.hover?.border ||
        "1px solid " + props.theme.palette.input.border,
    },
    "&:focus": {
      border:
        props?.inputStyle?.focus?.border ||
        `1px solid ${props.theme.palette.primary.main}`,
    },
  },
}));

const test = `
  // width: 100%;
  input {
    font-size: ${(props) => props?.inputStyle?.fontSize || "13px"};
    border: ${(props) =>
      props?.inputStyle?.border ||
      "1px solid " + props.theme.palette.input.border};
    color: ${(props) => props.theme.palette.text.primary};
    outline: none;
    padding: ${(props) => props?.inputStyle?.padding || "8px 8px"};
    width: ${(props) => props?.inputStyle?.width || "100%"};
    background-color: ${(props) =>
      props?.inputStyle?.backgroundColor ||
      props.theme.palette.input.background};
    border-radius: 5px;
    transition: border 0.1s ease-in-out;
    ::placeholder {
      color: ${(props) => props.theme.palette.input.placeholder};
    }
    &:hover {
      border: ${(props) =>
        props?.inputStyle?.hover?.border ||
        "1px solid " + props.theme.palette.input.border};
    }
    &:focus {
      border: 1px solid ${(props) => props.theme.palette.primary.main};
    }
  }
`;

Input.displayName = "Input";

export const InputLabel = styled(
  ({ className, style = {}, labelName = "" }) => {
    return (
      <div className={className} style={{ ...style }}>
        {labelName}
      </div>
    );
  }
)`
  color: ${(props) => props.theme.palette.text.secondary};
  margin-bottom: 5px;
`;

InputLabel.displayName = "InputLabel";

Input.StyledInput = StyledInput;

export default Input;
