import { Button, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext.js";
import { useEffect, useState } from "react";
import styled from "styled-components";
import UserInvitesAPI from "../api/UserInvites/index.js";
import { useQuery } from "@tanstack/react-query";
import Loader from "./Loader.js";

const GrantUserAccess = styled(({ className }) => {
  const { tenantSlug, inviteUUID } = useParams();
  const { currentUser } = useAuth();
  const [currentView, setCurrentView] = useState();

  const { isLoading, data } = useQuery({
    queryKey: ["UserInvites:grant-user-access", { invite_uuid: inviteUUID }],
    queryFn: () => UserInvitesAPI.getUserInvites({ invite_uuid: inviteUUID }),
  });

  const [userInvite] = data || [];

  const handleGrantAccess = () => {
    UserInvitesAPI.grantUserAccess({ invite_uuid: inviteUUID });
    setCurrentView("accessGranted");
  };

  const handleDenyAccess = () => {
    UserInvitesAPI.denyUserAccess({ invite_uuid: inviteUUID });
    setCurrentView("accessDenied");
  };

  useEffect(() => {
    if (!isLoading && !userInvite) setCurrentView("requestDoesNotExist");
    else if (!isLoading && userInvite) setCurrentView("grantAccess");
  }, [isLoading]);

  if (isLoading) return <Loader />;

  return (
    <div className={className}>
      <div className="content">
        <>
          {currentView === "requestDoesNotExist" && (
            <>
              <Typography variant="h3" gutterBottom display="inline">
                This request no longer exists.
              </Typography>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <div
                  style={{
                    margin: "20px auto 20px auto",
                    maxWidth: 350,
                  }}
                ></div>
              </div>
              <div className="action-btns">
                <div className="view-request-btn"></div>
              </div>
            </>
          )}
          {currentView === "grantAccess" && (
            <>
              <Typography variant="h2" gutterBottom display="inline">
                Grant this user access to {userInvite.tenant_org}?
              </Typography>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <div className="user-info-block">
                  <div>{userInvite.user.full_name}</div>
                  <div>{userInvite.user.email}</div>
                  <div>{userInvite.user.org}</div>
                </div>
                <div
                  style={{
                    margin: "20px auto 20px auto",
                    maxWidth: 350,
                  }}
                ></div>
              </div>
              <div className="action-btns">
                <div className="view-request-btn">
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    style={{ marginRight: 10 }}
                    onClick={handleDenyAccess}
                  >
                    Deny Access
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleGrantAccess}
                  >
                    Grant Access
                  </Button>
                </div>
              </div>
            </>
          )}
          {currentView === "accessGranted" && (
            <>
              <Typography variant="h2" gutterBottom display="inline">
                We just granted this user access.
              </Typography>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <div className="message">
                  They will be notified by email that they now have access.
                </div>
              </div>
            </>
          )}
          {currentView === "accessDenied" && (
            <>
              <Typography variant="h2" gutterBottom display="inline">
                This user's access request has been denied.
              </Typography>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <div className="message">
                  They will not be notified that their request was denied.
                </div>
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
})`
  position: relative;
  //   padding: 80px;
  .content {
    // light box shadow
    text-align: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    margin: auto auto;
    border-radius: 5px;
    // width: 500px;
    height: auto;
    padding: 40px;
    padding-bottom: 40px;
    background-color: ${({ theme }) => theme.palette.background.alt};
    .user-info-block {
      margin: 20px auto 20px auto;
      padding: 20px 10px;
      background-color: ${({ theme }) => theme.palette.background.default};
      border-radius: 5px;
      max-width: 350px;
      font-weight: 400;
      line-height: 1.5;
      font-size: 1rem;
      color: ${({ theme }) => theme.palette.text.primary};
    }
    .message {
      color: ${({ theme }) => theme.palette.text.secondary};
      font-weight: 400;
      line-height: 1.5;
      font-size: 1rem;
      margin-top: 10px;
    }
    .action-btns {
      margin-top: 40px;
    }
    .view-request-btn {
      margin-bottom: 20px;
    }
    .new-request-box {
      margin-top: 40px;
      padding: 20px 10px;
      border-radius: 5px;
      font-weight: 400;
      line-height: 1.5;
      //   font-size: 1rem;
      color: ${({ theme }) => theme.palette.text.primary};
      background-color: ${({ theme }) => theme.palette.background.default};
    }
  }
`;

export default GrantUserAccess;
