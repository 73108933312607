import { Box, Typography, useTheme } from "@mui/material";
import OrgLogo from "./OrgLogo";

const OrgHeader = ({
  orgInfo,
  showMessage = true,
  showOrgName = true,
  showLogo = true,
}) => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: theme.sidebar.background,
        color: theme.palette.text.primary,
        padding: 10,
      }}
    >
      {showLogo && <OrgLogo orgInfo={orgInfo} width={250} />}

      {showOrgName && (
        <Typography variant="h3" gutterBottom display="inline">
          {orgInfo.org}
        </Typography>
      )}
      {showMessage && (
        <Typography color="textSecondary" variant="subtitle2">
          Submit and manage requests for digital forensic services.
        </Typography>
      )}
    </div>
  );
};

export default OrgHeader;
