import { Modal } from "@mui/material";
import { EmptyItem, Form, SimpleItem } from "devextreme-react/ui/form.js";
import { useRef } from "react";
import { useTheme } from "styled-components";
import { DevexEditors } from "../../../utils/devex-editors.js";
import ToolBarItems from "../../../components/ToolBarItems.js";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../../contexts/AuthContext.js";
import { useParams } from "react-router-dom";
import MonolithCaseService from "../../../api/Monolith/Cases/index.js";
import EditIcon from "@mui/icons-material/Edit";

const EditRequestModal = ({ handleClose, open, onSubmit, defaultFormData }) => {
  const { currentUser } = useAuth();
  const { tenantSlug } = useParams();
  const theme = useTheme();
  const form = useRef(null);

  const monolithTenant = currentUser.tenants.find(
    (tenant) => tenant.tenant_slug === tenantSlug
  );

  const { data } = useQuery({
    queryKey: ["cases:elements", { monolithTenant }],
    queryFn: () =>
      MonolithCaseService.getCaseElements({
        monolith_tenant: monolithTenant.monolith_tenant,
        include_types: true,
        include_custom_fields: true,
      }),
  });

  const handleSubmit = () => {
    if (!form.current.instance.validate().isValid) return;
    const { customInquiryFields } = data;

    let formData = { ...form.current.instance.option("formData") };

    // remove empty values from formData
    Object.keys(formData).forEach(
      (key) => !formData[key] && delete formData[key]
    );

    handleClose();

    const filteredCustomFields = Object.keys(formData).filter(
      (key) => key.includes("custom_field_") && formData[key]
    ); // filter out empty values

    const mappedCustomFields = filteredCustomFields.map((key) => {
      const id = parseInt(key.replace("custom_field_", ""));
      return {
        ...(customInquiryFields.find((field) => field.field_id === id) || {}),
        value: formData[key],
      };
    });

    if (formData.client_ref_number) {
      formData.case_number = formData.client_ref_number;
      delete formData.client_ref_number;
    }

    if (onSubmit)
      onSubmit({
        ...formData,
        custom_field_data: mappedCustomFields,
      });
  };

  if (!data) return null;

  const { caseTypes, customInquiryFields } = data;

  const customFields = customInquiryFields.filter(
    (field) => field.external_enabled
  );

  defaultFormData?.custom_field_data?.forEach((field) => {
    defaultFormData[`custom_field_${field.field_id}`] = field.value;
  });

  delete defaultFormData.custom_field_data;

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        handleClose();
      }}
      disableEnforceFocus={true}
      style={{
        userSelect: "none",
        zIndex: 1400,
      }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 700,
          maxHeight: "90vh",
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 350px)",
          overflowY: "auto",
          padding: 20,
        }}
      >
        <div style={{ marginBottom: 20 }}>
          <div
            style={{
              marginBottom: 20,
              display: "flex",
              flexDirection: "row",
              gap: 5,
              alignItems: "center",
              fontSize: "1.25rem",
              fontWeight: 600,
            }}
          >
            <EditIcon /> Edit Request
          </div>
        </div>
        <div
          style={{
            maxHeight: 700,
            overflowY: "auto",
            paddingRight: 10,
            paddingLeft: 10,
          }}
        >
          <Form
            ref={form}
            colCount={2}
            defaultFormData={{ ...defaultFormData }}
          >
            <SimpleItem
              dataField="client_ref_number"
              label={{ text: "Case/Reference Number" }}
              editorOptions={{
                placeholder: "Enter your case or reference number",
              }}
            />
            {/* <EmptyItem /> */}
            <SimpleItem
              dataField="case_type"
              label={{ text: "Case Type" }}
              editorType="dxSelectBox"
              editorOptions={{
                items: caseTypes,
                displayExpr: "case_type",
                valueExpr: "case_type",
                dropDownOptions: {
                  height: 250,
                },
              }}
            />
            {customFields &&
              customFields.map((field) => {
                return (
                  <SimpleItem
                    key={`custom_field_${field.field_id}`}
                    dataField={`custom_field_${field.field_id}`}
                    editorType={DevexEditors[field.editor_type]}
                    // isRequired={attribute.is_required === 1}
                    label={{ text: field.field_name }}
                    isRequired={field.is_required === 1}
                    editorOptions={{
                      items: JSON.parse(field.options || "[]"),
                      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
                      useMaskBehavior: true,
                      //   displayFormat: getDateFormat(false, false),
                      showClearButton: field.editor_type !== "textBox",
                      pickerType: "calendar",
                      showDropDownButton: true,
                      multiline: false,
                      showSelectionControls:
                        field.editor_type === "tagBox" ? true : false,
                    }}
                  ></SimpleItem>
                );
              })}
            {customFields.length % 2 && <EmptyItem />}
            <SimpleItem
              dataField="description"
              editorType="dxTextArea"
              colSpan={2}
              isRequired={true}
              editorOptions={{
                height: 200,
                placeholder:
                  "Please provide a DETAILED description of what you need for this case or listed evidence items.",
              }}
            />
          </Form>
          <ToolBarItems
            submitText="Edit Request"
            onSubmit={handleSubmit}
            onCancel={handleClose}
            style={{ marginTop: 20 }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditRequestModal;
