import styled from "styled-components";
import { LinearProgress, Typography, useTheme } from "@mui/material";
import { useDropzone } from "react-dropzone";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import RequestService from "../../../api/requests/index.js";
import { useAuth } from "../../../contexts/AuthContext.js";
import { useParams } from "react-router-dom";
import convertBytesToSize from "../../../utils/convertBytesToSize.js";
import ItemBox from "../../../components/ItemBox.js";
import { useNewRequest } from "./NewRequestContext.js";
import shortUUID from "short-uuid";

const FileAttachments = styled(({ className, requestID, onUploading }) => {
  const theme = useTheme();
  const { setFormData } = useNewRequest();
  const [fileAttachments, setFileAttachments] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [uploads, setUploads] = useState(0);

  const handleFileDrop = async (files, reject, event) => {
    event?.target?.classList?.remove("dragging");

    // Add uuid to files
    files.forEach((file) => {
      file.item_id = shortUUID.generate();
    });

    if (files.length > 0) {
      setUploads(files.length);
    }

    addFileAttachment(files);
  };

  const addFileAttachment = (item) => {
    //check if array
    if (Array.isArray(item)) {
      setFileAttachments((fileAttachments) => [...fileAttachments, ...item]);
    } else {
      setFileAttachments((fileAttachments) => [...fileAttachments, item]);
    }
  };

  const removeFileAttachment = (item) => {
    setFileAttachments((fileAttachments) =>
      fileAttachments.filter((e) => e.item_id !== item.item_id)
    );
  };

  const handleRejection = (items) => {
    if (items.length > 0) {
      items.forEach((item) => {
        if (item.errors.length > 0) {
          item.errors.forEach((error) => {
            enqueueSnackbar(error.message, {
              variant: "error",
            });
          });
        }
      });
    }
  };

  const handleFileUploaded = (file) => {
    // update file
    setFileAttachments((fileAttachments) =>
      fileAttachments.map((e) => {
        if (e.item_id === file.item_id) {
          e.link = file.url;
          e.md5 = file.md5;
          e.sha1 = file.sha1;
          e.sha256 = file.sha256;
          e.client_modified = new Date(e.lastModified).toISOString();
        }

        return e;
      })
    );

    setUploads((uploads) => uploads - 1);
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDragEnter: (e) => e.target.classList.add("dragging"),
    onDragLeave: (e) => e.target.classList.remove("dragging"),
    onDrop: handleFileDrop,
    onDropRejected: handleRejection,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc", ".docx"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.ms-excel": [".xls", ".xlsx"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      //Images
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      //CsV
      "text/csv": [".csv"],
    },
    maxFiles: 5,
    maxSize: 10485760,
    multiple: true,
  });

  useEffect(() => {
    onUploading(uploads > 0);
  }, [uploads]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      files: fileAttachments.map((file) => {
        return {
          filename: file.name,
          type: file.type,
          size: file.size,
          link: file.link,
          converted_size: convertBytesToSize(file.size),
          item_id: file.item_id,
          ext: file.path.split(".").pop().toLowerCase(),
          md5: file.md5,
          sha1: file.sha1,
          sha256: file.sha256,
          client_modified: file.client_modified,
        };
      }),
    }));
  }, [fileAttachments]);

  return (
    <div className={className}>
      <div style={{ margin: "auto" }}>
        <Typography variant="h3" gutterBottom display="inline">
          Attach Files
        </Typography>
        <div style={{ color: theme.palette.text.secondary, marginTop: 5 }}>
          Attach up to 5 files relevant to this request. (Search Warrant,
          Keyword List, etc.)
        </div>
        <DropZone {...getRootProps()}>
          <input {...getInputProps()} />
          <FileUploadOutlinedIcon
            className="dropzone-icon"
            style={{
              fontSize: 55,
              marginBottom: 15,
              color: theme.palette.text.secondary,
              transition: "color linear 0.2s",
            }}
          />
          {/* <DropZoneText className="dropzone-text">Upload Files</DropZoneText> */}
          <DropZoneSubText className="dropzone-subtext">
            Drag and drop or click to select files.
          </DropZoneSubText>
        </DropZone>
      </div>
      <FileAttachmentsList
        requestID={requestID}
        files={fileAttachments}
        onRemove={removeFileAttachment}
        handleFileUploaded={handleFileUploaded}
      />
    </div>
  );
})``;

const FileAttachmentsList = styled(
  ({ className, requestID, files, onRemove, handleFileUploaded }) => {
    if (!files || files.length === 0) return null;
    // <div style={{ marginTop: 10, marginBottom: 20 }}>No attachments</div>

    return (
      <div className={className} style={{ marginTop: 10, marginBottom: 20 }}>
        {files &&
          files.map((file, index) => {
            return (
              <FileItem
                key={file.item_id}
                requestID={requestID}
                file={file}
                onRemove={onRemove}
                handleFileUploaded={handleFileUploaded}
              />
            );
          })}
      </div>
    );
  }
)`
  & .view-btn {
    cursor: pointer;
    font-size: 10px;
    color: ${({ theme }) => theme.palette.text.secondary};
    display: flex;
    align-items: center;
    &:hover,
    &:hover svg,
    &:hover .view-btn-title {
      color: ${({ theme }) => theme.palette.primary.main};
      text-decoration: underline;
    }
  }
  & .view-btn-title {
    margin-right: 5px;
  }
`;

const FileItem = styled(
  ({ className, requestID, file, onRemove, handleFileUploaded }) => {
    const { currentUser } = useAuth();
    const { tenantSlug, view } = useParams();
    const [isUploading, setIsUploading] = useState(false);

    const formTenant = currentUser.tenants.find(
      (tenant) => tenant.tenant_slug === tenantSlug
    );

    const handleClick = (file) => {
      onRemove(file);
    };

    const handleFileUpload = async (file) => {
      return new Promise((resolve, reject) => {
        //Get File Data from reader
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const fileData = reader.result.split(",")[1]; // get base64 data from reader

          RequestService.uploadFileAttachment({
            tenant: formTenant.tenant_uuid,
            filePath: `${requestID}/${file.name}`,
            fileData,
          })
            .catch((err) => {
              console.log(err);
              reject(err);
            })
            .then((res) => {
              handleFileUploaded({ ...file, ...res, link: res.url });
              resolve(res);
            });
        };
      });
    };

    useEffect(() => {
      if (file.item_id) {
        setIsUploading(true);
        handleFileUpload(file).then((res) => {
          setIsUploading(false);
        });
      }
    }, []);

    return (
      <ItemBox
        title={file.name}
        subTitle={convertBytesToSize(file.size)}
        startIcon={<DescriptionOutlinedIcon />}
        endIcon={
          <div className="view-btn" onClick={() => handleClick(file)}>
            <div className="view-btn-title">Remove</div>
            <ClearOutlinedIcon />
          </div>
        }
      >
        {isUploading && (
          <div style={{ marginTop: 10 }}>
            <LinearProgress />
          </div>
        )}
      </ItemBox>
    );
  }
)`
  & .view-btn {
    cursor: pointer;
    font-size: 10px;
    color: ${({ theme }) => theme.palette.text.secondary};
    display: flex;
    align-items: center;
    &:hover,
    &:hover svg,
    &:hover .view-btn-title {
      color: ${({ theme }) => theme.palette.primary.main};
      text-decoration: underline;
    }
  }
  & .view-btn-title {
    margin-right: 5px;
  }
`;

const DropZone = styled.div`
  border: ${({ theme }) => `2px dashed ${theme.palette.divider}`};
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  cursor: pointer;
  height: 150px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: border-color linear 0.2s;
  margin-top: 20px;
  margin-bottom: 20px;
  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
    .dropzone-text,
    .dropzone-icon,
    .dropzone-subtext {
      color: ${({ theme }) => theme.palette.primary.main} !important;
    }
  }
  &.dragging {
    border-color: ${({ theme }) => theme.palette.primary.main};
    .dropzone-text,
    .dropzone-icon,
    .dropzone-subtext {
      color: ${({ theme }) => theme.palette.primary.main} !important;
    }
  }
`;

const DropZoneText = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.palette.text.secondary};
  transition: color linear 0.2s;
`;

const DropZoneSubText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.secondary};
  transition: color linear 0.2s;
`;

export default FileAttachments;
