import styled, { useTheme } from "styled-components";
import { CSSTransition } from "react-transition-group";
import { nanoid } from "nanoid";
import { useMemo } from "react";
import { useRef } from "react";
import { useState } from "react";
import { cloneElement } from "react";
import React from "react";
import { useEffect } from "react";
import Input from "./Input.js";
import { border } from "@mui/system";

const allowedMenuItems = [
  "MenuItem",
  "MultiSelectItem",
  "MenuDivider",
  "Calendar",
];

const Menu = styled(
  ({
    className,
    children,
    anchorEl,
    open = false,
    onClose = () => {},
    onItemSelect = () => {},
    dropDownStyle = {},
    dropDownTitle,
    showSearch = false,
  }) => {
    const theme = useTheme();
    const listBoxRef = useRef(null);
    const [show, setShow] = useState(open);
    const [searchValue, setSearchValue] = useState(null);

    function alignMenu() {
      const { left, right, top, width, height } =
        anchorEl.getBoundingClientRect();
      const { innerWidth, innerHeight, screenX, screenY } = window;
      const { offsetWidth, offsetHeight } = listBoxRef.current;
      const x = left + offsetWidth > innerWidth ? right - offsetWidth : left;
      const y =
        top + height + offsetHeight > innerHeight
          ? top - offsetHeight
          : top + height;
      listBoxRef.current.style.left = `${x}px`;
      listBoxRef.current.style.top = `${y}px`;
    }

    const handleSearch = (value) => {
      setSearchValue(value);
    };

    const handleClose = () => {
      onClose();
    };

    // Close dropdown when clicked outside of the dropdown
    useEffect(() => {
      function handleClickOutside(event) {
        if (listBoxRef.current && !listBoxRef.current.contains(event.target)) {
          handleClose();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    useEffect(() => {
      setTimeout(() => {
        setSearchValue(null);
      }, 100);
      setShow(open);
    }, [open]);

    // if (!children || children.length === 0) return null;

    const menuItems = useMemo(() => {
      return children?.length
        ? children
            .filter((child) => {
              if (!allowedMenuItems.includes(child?.type?.displayName))
                return false;

              if (!searchValue) return true;
              const displayField = child?.props?.displayField;
              const item = child?.props?.data;
              const value = displayField ? item?.[displayField] : item;

              if (!value) return false;

              return value.toLowerCase().includes(searchValue.toLowerCase());
            })
            .map((child) =>
              cloneElement(child, {
                key: child?.props?.key || nanoid(),
                onItemSelect: onItemSelect,
              })
            )
        : allowedMenuItems.includes(children?.type?.displayName)
        ? cloneElement(children, {
            // key: children.props.key,
            onItemSelect: onItemSelect,
          })
        : null;
    }, [children, searchValue]);

    return (
      <>
        <CSSTransition
          in={show}
          nodeRef={listBoxRef}
          timeout={300}
          classNames="select-box-options"
          unmountOnExit
          onEnter={() => alignMenu()}
          // onExited={() => setShowButton(true)}
        >
          <div className={className}>
            <div
              ref={listBoxRef}
              className={"select-box-options"}
              style={{ ...dropDownStyle }}
              onClick={(e) => e.stopPropagation()}
            >
              {dropDownTitle && dropDownTitle()}
              {showSearch && (
                <div
                  style={{
                    padding: "0px 5px",
                    marginBottom: 5,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <Input
                    defaultValue={""}
                    onChange={(e) => handleSearch(e.newValue)}
                    placeholder="Search..."
                    // autoFocus={rest.autoFocus || false}
                    autoFocus={true}
                    // style={{ marginRight: 3 }}
                    showBorder={false}
                    inputStyle={{
                      fontSize: "11px",
                      color: theme.palette.text.primary,
                      // backgroundColor: theme.palette.background.secondary,
                      backgroundColor: "transparent",
                      padding: "0px",
                      border: "1px solid transparent",
                      outline: "none",
                      height: "24.5px",
                      width: "100%",
                      hover: {
                        border: "1px solid transparent",
                      },
                      focus: {
                        border: "1px solid transparent",
                      },
                    }}
                  />
                </div>
              )}
              {menuItems}
            </div>
            <div
              className="select-box-backdrop"
              onClick={(e) => e.stopPropagation()}
            ></div>
          </div>
        </CSSTransition>
      </>
    );
  }
)`
  .select-box-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4000;
    width: 100vw;
    height: 100vh;
    cursor: default;
    // background-color: rgba(0, 0, 0, 0.5);
  }
  .select-box-options {
    position: fixed;
    min-width: 150px;
    min-height: 25px;
    max-height: 350px;
    overflow-y: auto;
    background-color: ${(props) => props.theme.palette.background.default};
    // background-color: ${(props) => props.theme.palette.input.background};
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 5px;
    z-index: 4001;
    padding: 5px;
    box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.2);
  }
  .select-box-options-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .select-box-options-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 100ms, transform 100ms;
  }
  .select-box-options-exit {
    opacity: 1;
  }
  .select-box-options-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 100ms, transform 100ms;
  }
`;

Menu.displayName = "Menu";

export const MenuItem = styled(
  ({
    className,
    children,
    data = null,
    onItemSelect = () => {},
    style = {},
  }) => {
    return (
      <div
        className={`${className} select-box-item`}
        style={{ ...style }}
        onClick={(e) => {
          e.stopPropagation();
          onItemSelect(data);
        }}
      >
        {children}
      </div>
    );
  }
)`
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  padding: 5px;
  font-weight: normal;
  font-size: 12px;
  &:hover {
    background-color: ${(props) => props.theme.palette.action.hover};
  }
`;

MenuItem.displayName = "MenuItem";

export default Menu;
