import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { styled } from "styled-components";

export default styled(
  forwardRef(({ className, items, command, displayKey, valueKey }, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const selectItem = (index) => {
      const item = items[index];

      if (item) {
        command({
          id: item[displayKey],
          "user-email": item[valueKey],
          "user-name": item[displayKey],
        });
      }
    };

    const upHandler = () => {
      setSelectedIndex((selectedIndex + items.length - 1) % items.length);
    };

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % items.length);
    };

    const enterHandler = () => {
      selectItem(selectedIndex);
    };

    useEffect(() => setSelectedIndex(0), [items]);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        if (event.key === "ArrowUp") {
          upHandler();
          return true;
        }

        if (event.key === "ArrowDown") {
          downHandler();
          return true;
        }

        if (event.key === "Enter") {
          enterHandler();
          return true;
        }

        return false;
      },
    }));

    return (
      <div className={className + " items"}>
        {items.length ? (
          items.map((item, index) => (
            <button
              className={`item ${index === selectedIndex ? "is-selected" : ""}`}
              key={index}
              onClick={() => selectItem(index)}
            >
              <span class="primary-item">{item[displayKey]}</span>
              <br />
              <span class="secondary-item">{item[valueKey]}</span>
            </button>
          ))
        ) : (
          <div className="item">No result</div>
        )}
      </div>
    );
  })
)`
  &.items {
    display: flex;
    flex-direction: column;
    gap: 3px;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    background-color: ${({ theme }) => theme.palette.background.default};
    border-radius: 5px;
    padding: 5px;
  }

  button.item {
    background: none;
    border: none;
    border-radius: 5px;
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: 600;
    font-size: 12px;
    padding: 5px 10px;
    outline: none;
    text-align: left;

    &:hover {
      background-color: ${({ theme }) => theme.palette.action.hover};
      cursor: pointer;
    }
  }

  button.item.is-selected {
    background-color: ${({ theme }) => theme.palette.divider};
  }

  span.primary-item {
  }

  span.secondary-item {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-weight: 400;
    margin-top: 3px;
  }
`;
