import ReactPerfectScrollbar from "react-perfect-scrollbar";
import styled, { useTheme } from "styled-components";
import useMediaQuery from "@mui/material/useMediaQuery";

import SidebarNavSection from "./SidebarNavSection";

import "../../vendor/perfect-scrollbar.css";

const Scrollbar = styled.div`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const Items = styled.div`
  padding: 10px 0px;
`;

const List = styled.div`
  padding-top: 10px;
`;

const SidebarNav = ({ items }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const ScrollbarComponent = matches ? PerfectScrollbar : Scrollbar;

  return (
    <ScrollbarComponent>
      <List>
        <Items>
          {items &&
            items.map((item, index) => {
              return (
                <SidebarNavSection
                  component="div"
                  key={index}
                  pages={item.pages}
                  title={item.title}
                />
              );
            })}
        </Items>
      </List>
    </ScrollbarComponent>
  );
};

export default SidebarNav;
