import { Button, CssBaseline, useTheme } from "@mui/material";
import { useRef, useEffect } from "react";
import { Form, SimpleItem, EmptyItem } from "devextreme-react/ui/form";
import ReCAPTCHA from "react-google-recaptcha";
import { AuthService } from "../../api/auth/index.js";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext.js";
import TaskButton from "../../components/UI/TaskButton.js";
import GlobalStyle from "../../components/GlobalStyle.js";
import logo from "../../monolith_logo.png"; // Tell Webpack this JS file uses this image
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";

// const ReCAPTCHA_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"; //  for testing
const ReCAPTCHA_SITE_KEY = "6Lf0jP4jAAAAAPd3iLu3aeoJ4OEBUSy_vC3Xkmk_";

const SignUp = styled(({ className }) => {
  const theme = useTheme();
  const { setSessionData } = useAuth();
  const getFormData = useRef(null);
  const validateFormData = useRef(null);
  const recaptcha = useRef(null);
  const navigate = useNavigate();
  const isOnPremise = process.env.REACT_APP_API_MODE === "on-premises";

  const handleSignUp = async () => {
    if (!validateFormData.current()) return;

    // Get slug from redirect url search params
    const searchParams = new URLSearchParams(window.location.search);
    const redirect = searchParams.get("redirect");
    const slug = searchParams.get("slug");

    const formData = getFormData.current();

    const response = await AuthService.userSignup({
      email: formData.new_email,
      password: formData.new_password,
      recaptchaToken: isOnPremise
        ? null
        : await recaptcha.current.executeAsync(),
    });

    if (response.success) {
      // do something
      setSessionData({ email: formData.new_email }).then((res) => {
        if (slug && redirect) {
          window.localStorage.setItem(
            "post-signup-data",
            JSON.stringify({
              redirect,
              slug,
            })
          );
          navigate(`/account-setup?redirect=${redirect}&slug=${slug}`, {
            replace: true,
          });
        } else {
          navigate("/account-setup", { replace: true });
        }
      });
    } else {
      if (response.message.includes("Email already exists")) {
        // navigate to login page
        navigate("/login");
      } else {
        // some other error
      }
    }
  };

  const { isLoading, data: sessionResponse } = useQuery({
    queryKey: ["check-session"],
    queryFn: () => AuthService.checkSession(),
  });

  if (isLoading) return <></>;

  if (sessionResponse?.userInfo?.email) {
    navigate("/");
    return null;
  }

  return (
    <div className={className}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "calc(100vh - 200px)",
          alignItems: "center",
        }}
      >
        <CssBaseline />
        <GlobalStyle />
        <div className="signup-container">
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 25,
                marginTop: 25,
                // color: theme.palette.text.secondary,
                // fontSize: "large",
                textAlign: "center",
              }}
            >
              <div>
                <h1>Create a Relay Account</h1>
                <div className="message">
                  Create an account to submit and manage requests for digital
                  forensics services from your forensic labs or departments.
                </div>
              </div>
            </div>
            <SignUpForm
              getFormData={getFormData}
              validateFormData={validateFormData}
            />
            {!isOnPremise && (
              <div style={{ margin: "auto" }}>
                <ReCAPTCHA
                  ref={recaptcha}
                  sitekey={ReCAPTCHA_SITE_KEY}
                  size="invisible"
                  theme="dark"
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px 0px",
              }}
            >
              <Button
                variant="contained"
                size="small"
                fullWidth
                style={{ marginTop: 20 }}
                onClick={handleSignUp}
              >
                Sign Up
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px 0px",
              }}
            >
              <TaskButton
                style={{ marginTop: 10, marginBottom: 10, padding: 8 }}
                onClick={(e) => navigate("/login")}
              >
                Back to Relay Login
              </TaskButton>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: 50,
            marginBottom: 25,
            fontSize: "small",
            position: "absolute",
            bottom: 0,
          }}
        >
          <div
            style={{
              textAlign: "center",
              color: theme.palette.text.secondary,
              marginBottom: 10,
            }}
          >
            Powered By
          </div>
          <a
            href="https://monolithforensics.com"
            target="_blank"
            rel="noreferrer"
          >
            <img src={logo} height="65" alt="Monolith Forensics Logo"></img>
          </a>
        </div>
      </div>
    </div>
  );
})`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.palette.background.default};

  .message {
    margin-bottom: 10px;
    color: ${(props) => props.theme.palette.text.secondary};
    font-size: 0.85rem;
  }

  .signup-container {
    width: 500px;
    padding: 20px 60px;
    height: auto;
    border-radius: 5px;
    background-color: ${(props) => props.theme.palette.background.alt};
    border: 1px solid ${(props) => props.theme.palette.divider};
    box-shadow: none !important;
  }
`;

const SignUpForm = ({ getFormData, validateFormData }) => {
  const form = useRef(null);

  const _getFormData = () => {
    return form.current.instance.option("formData");
  };
  const _validateFormData = () => {
    return form.current.instance.validate().isValid;
  };

  useEffect(() => {
    getFormData.current = () => _getFormData();

    validateFormData.current = () => _validateFormData();

    form.current.instance.getEditor("new_email").focus();
  }, []);

  return (
    <>
      <Form ref={form} colCount={1}>
        <SimpleItem
          dataField="new_email"
          isRequired={true}
          label={{ visible: false }}
          editorOptions={{
            placeholder: "Email",
            mode: "email",
            inputAttr: { autocomplete: "new-email" },
          }}
        />
        <SimpleItem
          dataField="new_password"
          isRequired={true}
          label={{ visible: false }}
          editorOptions={{
            placeholder: "Password",
            mode: "password",
            inputAttr: { autocomplete: "new-password" },
          }}
        />
      </Form>
    </>
  );
};

export default SignUp;
