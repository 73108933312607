import { useEffect, useState } from "react";

const imageStyle = {
  width: "100px",
  height: "100px",
  marginBottom: "10px",
  objectFit: "contain",
};

const OrgLogo = ({
  orgInfo,
  width = imageStyle.width,
  height = imageStyle.height,
}) => {
  const [storedImage, setStoredImage] = useState(null);

  useEffect(() => {
    if (orgInfo.logo) {
      let arrayBufferView = new Uint8Array(orgInfo.logo.data);
      let blob = new Blob([arrayBufferView]);
      let urlCreator = window.URL || window.webkitURL;
      let imageUrl = urlCreator.createObjectURL(blob);
      setStoredImage(imageUrl);
    } else {
      setStoredImage(null);
    }
  }, [orgInfo]);

  if (!storedImage) {
    return null;
  }

  return (
    <img
      style={{ ...imageStyle, width, height }}
      src={storedImage}
      alt="Logo"
    />
  );
};

export default OrgLogo;
