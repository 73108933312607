import { httpGet, httpPost } from "../connection";

const createFormSubmission = async (values) => httpPost(`/submissions`, values);

const uploadFileAttachment = async (fileInfo) => {
  return httpPost(`/submissions/files`, fileInfo);
};

async function getRequests(values) {
  return httpGet(`/requests`, values);
}

async function exportRequestList(values) {
  return httpPost(`/requests/export`, values);
}

async function updateRequest(values) {
  return httpPost(`/requests/update`, values);
}

async function createEvidence(values) {
  return httpPost(`/requests/evidence/create`, values);
}

async function getEvidence(values) {
  return httpGet(`/requests/evidence`, values);
}

async function updateEvidence(values) {
  return httpPost(`/requests/evidence/update`, values);
}

async function deleteEvidence(values) {
  return httpPost(`/requests/evidence/delete`, values);
}

async function createPeople(values) {
  return httpPost(`/requests/people/create`, values);
}

async function updatePeople(values) {
  return httpPost(`/requests/people/update`, values);
}

async function getPeople(values) {
  return httpGet(`/requests/people`, values);
}

async function deletePeople(values) {
  return httpPost(`/requests/people/delete`, values);
}

async function getUploadUrl(values) {
  return httpPost(`/requests/evidence/upload-url`, values);
}

async function uploadDocument(values, options = {}) {
  return httpPost(`/requests/documents/upload`, values, options);
}

async function getDocuments(values) {
  return httpGet(`/requests/documents`, values);
}

async function createDocument(values) {
  return httpPost(`/requests/documents/create`, values);
}

async function deleteDocument(values) {
  return httpPost(`/requests/documents/delete`, values);
}

async function getComments(values) {
  return httpGet(`/requests/comments`, values);
}

async function createComment(values) {
  return httpPost(`/requests/comments/create`, values);
}

const RequestService = {
  createFormSubmission,
  uploadFileAttachment,
  getRequests,
  exportRequestList,
  updateRequest,
  getEvidence,
  createEvidence,
  updateEvidence,
  deleteEvidence,
  getPeople,
  createPeople,
  updatePeople,
  deletePeople,
  getUploadUrl,
  getDocuments,
  uploadDocument,
  createDocument,
  deleteDocument,
  getComments,
  createComment,
};

export default RequestService;
