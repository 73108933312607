import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { styled } from "styled-components";
import MonolithEvidenceService from "../../../../api/Monolith/Evidence/index.js";
import ProgressBar from "../../../../Monolith-UI/ProgressBar/ProgressBar.js";
import { Button } from "@mui/material";
import AddEvidenceModal from "../../Modals/AddEvidenceModal.js";
import { useState } from "react";
import RequestService from "../../../../api/requests/index.js";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import EditEvidenceModal from "../../Modals/EditEvidenceModal.js";
import DeleteEvidenceModal from "../../Modals/DeleteEvidenceModal.js";
import { useRequest } from "../RequestView.js";
import { prepareCopyData } from "../../../../utils/prepareCopyData.js";

const MenuBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
`;

const EvidenceItemHeader = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
`;

const SubTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const ViewDetails = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  font-size: 0.75rem;
  font-weight: 500;
  color: ${(props) => props.theme.palette.text.secondary};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    font-size: 1rem;
  }

  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
    text-decoration: underline;
  }
`;

const EvidenceInnerContent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 1rem;
  flex-grow: 1;
`;

const EvidenceDescription = styled.div`
  font-size: 0.75rem;
  //   margin-bottom: 10px;
  color: ${(props) => props.theme.palette.text.secondary};
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

const ActionMenu = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0;
  margin: 20px;
  display: flex;
  align-items: center;
  align-content: center;
  gap: 10px;
`;

const ActionMenuItem = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  border-radius: 5px;
  font-size: 10px;
  color: ${(props) =>
    props.color === "primary"
      ? props.theme.palette.primary.main
      : props.color === "error"
      ? props.theme.palette.error.main
      : props.theme.palette.text.primary};
  &:hover {
    text-decoration: underline;
  }
  & svg {
    font-size: 12px;
    margin-right: 5px;
  }
`;

const ActionMenuLabel = styled.div``;

const EvidenceContent = styled(({ className, details }) => {
  const { isEditable } = useRequest();
  const { requestID, tenantSlug } = useParams();
  const [showModal, setShowModal] = useState(false);

  const queryClient = useQueryClient();

  const relayEvidence = details?.evidence || [];

  const { data: monolithEvidence, isFetched: evidenceFetched } = useQuery(
    ["evidenceItems", { uuid: requestID, tenantSlug }],
    () =>
      MonolithEvidenceService.getEvidence({
        data_only: true,
        request_id: requestID,
        tenantSlug,
        monolith_tenant: details.relay_tenant.monolith_tenant,
        uuid: relayEvidence.map((e) => e.item_id),
      }),
    {
      refetchOnWindowFocus: true,
    }
  );

  const { data: progressItems, isFetched: progressFetchd } = useQuery(
    ["evidence:progress", { monolith_tenant: details.monolith_tenant }],
    () =>
      MonolithEvidenceService.getEvidenceProgress({
        monolith_tenant: details.relay_tenant.monolith_tenant,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const addEvidence = (evidence) => {
    RequestService.createEvidence({
      ...evidence,
      request_id: requestID,
    }).then((res) => {
      queryClient.refetchQueries([
        "request",
        { request_id: requestID, data_only: true },
      ]);
    });
  };

  if (!progressFetchd || !evidenceFetched) return null;

  const evidenceItems =
    monolithEvidence?.length > 0
      ? monolithEvidence
      : relayEvidence.map((e) => ({
          ...e,
          manufacturer: e.make,
          item_type: e.evidence_type,
          uuid: e.item_id,
        })); // Adjust Relay evidence to match Monolith evidence

  return (
    <div className={className}>
      <MenuBar>
        {isEditable && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{
              fontSize: "11px",
              padding: "3px 6px",
              // fontWeight: 700,
            }}
            onClick={() => setShowModal(true)}
          >
            + Add Evidence
          </Button>
        )}
      </MenuBar>
      {evidenceItems.length === 0 && (
        <div style={{ textAlign: "center", width: "100%" }}>
          No evidence added
        </div>
      )}
      {evidenceItems.map((item) => (
        <EvidenceItem
          key={item.uuid}
          item={item}
          progressItems={progressItems}
          caseExists={details?.case_info?.uuid}
        />
      ))}
      {isEditable && (
        <AddEvidenceModal
          open={showModal}
          handleClose={() => setShowModal(false)}
          onSubmit={addEvidence}
        />
      )}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 900px;
  margin: 0 auto;
`;

const EvidenceItem = styled(
  ({ className, item, progressItems, caseExists }) => {
    const { requestID, tenantSlug } = useParams();
    const { isEditable } = useRequest();
    const [showModal, setShowModal] = useState(false);
    const [showCopyModal, setShowCopyModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const queryClient = useQueryClient();

    const addEvidence = (evidence) => {
      RequestService.createEvidence({
        ...evidence,
        request_id: requestID,
      }).then((res) => {
        queryClient.refetchQueries([
          "request",
          { request_id: requestID, data_only: true },
        ]);
      });
    };

    const editEvidence = (evidence) => {
      RequestService.updateEvidence({
        ...evidence,
        request_id: requestID,
      }).then((res) => {
        queryClient.refetchQueries(["request", { request_id: requestID }]);
      });
    };

    const deleteEvidence = (evidence) => {
      RequestService.deleteEvidence({
        ...evidence,
        request_id: requestID,
      }).then((res) => {
        queryClient.refetchQueries(["request", { request_id: requestID }]);
      });
    };

    return (
      <div className={className}>
        <EvidenceInnerContent>
          <EvidenceItemHeader>{item.item_type}</EvidenceItemHeader>
          <SubTitle>
            {item.evidence_number} | {item.manufacturer}
          </SubTitle>
          {item.case_uuid ? (
            <ProgressBar
              progressItems={progressItems}
              defaultValue={item.progress}
              enabled={false}
            />
          ) : (
            <EvidenceDescription>{item.description}</EvidenceDescription>
          )}
          {item.case_uuid ? (
            <Link to={`evidence/${item.uuid}`}>
              <ViewDetails>
                <div>View Details</div>
                <ArrowForwardOutlinedIcon />
              </ViewDetails>
            </Link>
          ) : (
            <>
              {!caseExists ? (
                <ViewDetails>Pending Case Assignment</ViewDetails>
              ) : (
                <ViewDetails>No Details</ViewDetails>
              )}
              {isEditable && (
                <ActionMenu>
                  <ActionMenuItem onClick={() => setShowCopyModal(true)}>
                    <ContentCopyIcon color="primary" />
                    <ActionMenuLabel>Copy</ActionMenuLabel>
                  </ActionMenuItem>
                  <ActionMenuItem onClick={() => setShowModal(true)}>
                    <EditIcon color="primary" />
                    <ActionMenuLabel>Edit</ActionMenuLabel>
                  </ActionMenuItem>
                  <ActionMenuItem onClick={() => setShowDeleteModal(true)}>
                    <DeleteForeverIcon color="error" />
                    <ActionMenuLabel>Delete</ActionMenuLabel>
                  </ActionMenuItem>
                </ActionMenu>
              )}
            </>
          )}
        </EvidenceInnerContent>
        {isEditable && (
          <AddEvidenceModal
            open={showCopyModal}
            handleClose={() => setShowCopyModal(false)}
            onSubmit={addEvidence}
            copyData={prepareCopyData(item)}
          />
        )}
        {isEditable && (
          <EditEvidenceModal
            open={showModal}
            handleClose={() => setShowModal(false)}
            onSubmit={editEvidence}
            defaultInfo={item}
          />
        )}
        {isEditable && (
          <DeleteEvidenceModal
            open={showDeleteModal}
            handleClose={() => setShowDeleteModal(false)}
            onSubmit={deleteEvidence}
            defaultFormData={item}
          />
        )}
      </div>
    );
  }
)`
  position: relative;
  display: flex;
  height: auto;
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 4px;

  transition: border 0.2s ease-in-out;

  &:hover {
    border: 1px solid ${(props) => props.theme.palette.primary.main};
  }
`;

export default EvidenceContent;
