import styled from "styled-components";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useTheme } from "@mui/material";
import ItemBox from "../../../../components/ItemBox.js";

const ChainOfCustodyList = styled(({ className, evidenceInfo }) => {
  const { requestID, tenantSlug, evidenceUUID } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  if (!evidenceInfo.coc_list || evidenceInfo.coc_list.length === 0)
    return (
      <div style={{ marginTop: 10, marginBottom: 20 }}>
        No chain of custody records
      </div>
    );

  return (
    <div className={className} style={{ marginTop: 10, marginBottom: 20 }}>
      {evidenceInfo.coc_list.map((item, index) => {
        return <CocItem key={index} item={item} />;
      })}
    </div>
  );
})`
  & .view-btn {
    cursor: pointer;
    font-size: 10px;
    color: ${({ theme }) => theme.palette.text.secondary};
    display: flex;
    align-items: center;
    &:hover,
    &:hover svg,
    &:hover .view-btn-title {
      color: ${({ theme }) => theme.palette.primary.main};
      text-decoration: underline;
    }
  }
  & .view-btn-title {
    margin-right: 5px;
  }
`;

const CocItem = styled(({ className, item }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(!open);

  return (
    <ItemBox
      title={item.type}
      subTitle={
        <>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 5 }}>
              {moment.utc(item.timestamp).fromNow()}
            </div>
          </div>
        </>
      }
      startIcon={<SwapHorizOutlinedIcon />}
      endIcon={
        <div className="view-btn" onClick={() => handleClick()}>
          <div className="view-btn-title">View Details</div>
          <ArrowDownwardOutlinedIcon
            style={{
              //rotate
              transform: !open ? "rotate(-90deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease-in-out",
            }}
          />
        </div>
      }
    >
      {open && <Details item={item} />}
    </ItemBox>
  );
})``;

const Details = styled(({ className, item }) => {
  const theme = useTheme();
  return (
    <div className={className}>
      <DetailItem label="Type" value={item.type} />
      <DetailItem
        label="Timestamp"
        value={moment
          .utc(item.timestamp)
          .local()
          .format("MMMM DD, YYYY HH:mm:ss Z")}
      />
      <DetailItem
        label="Custody From"
        value={
          <div>
            <div>{item.custody_from}</div>
            {item?.signature?.custody_from_sig && (
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <img
                  src={item.signature.custody_from_sig.signature}
                  style={{
                    maxHeight: 30,
                    filter: theme.name === "DARK" ? "invert(1)" : "unset",
                  }}
                  alt="signature"
                />
              </div>
            )}
          </div>
        }
      />
      <DetailItem
        label="Custody To"
        value={
          <div>
            <div>{item.custody_to}</div>
            {item?.signature?.custody_to_sig && (
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <img
                  src={item.signature.custody_to_sig.signature}
                  style={{
                    maxHeight: 30,
                    filter: theme.name === "DARK" ? "invert(1)" : "unset",
                  }}
                  alt="signature"
                />
              </div>
            )}
          </div>
        }
      />
      <DetailItem label="Description" value={item.reason} />
    </div>
  );
})`
  margin-top: 25px;
`;

export const DetailItem = styled(({ className, label, value }) => {
  return (
    <div className={className}>
      <div className="request-detail-label">{label}</div>
      <div className="request-detail-value">{value}</div>
    </div>
  );
})`
  display: flex;
  margin-bottom: 8px;
  font-size: 10px;
  .request-detail-label {
    color: ${(props) => props.theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 75px;
  }
  .request-detail-value {
    color: ${(props) => props.theme.palette.text.primary};
    white-space: pre-line;
  }
`;

export default ChainOfCustodyList;
