// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const DevExtremeMap = {
  DEFAULT: "material.purple.light",
  DARK: "material.blue.dark",
  LIGHT: "material.purple.light",
  BLUE: "material.purple.light",
  GREEN: "material.purple.light",
  INDIGO: "material.purple.light",
};
