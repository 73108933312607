import styled from "styled-components";

import SidebarNavList from "./SidebarNavList";

const Title = styled.h4`
  color: #999;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0.5rem;
  padding-left: 1.75rem;

  text-transform: uppercase;
  display: block;
  margin: 0;
`;

const SidebarNavSection = ({
  title,
  pages,
  className,
  component: Component = "nav",
  ...rest
}) => {
  return (
    <Component {...rest} style={{ marginBottom: 15 }}>
      {title && <Title variant="subtitle2">{title}</Title>}
      <SidebarNavList pages={pages} depth={0} />
    </Component>
  );
};

export default SidebarNavSection;
