import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

// For routes that can only be accessed by authenticated users
const AuthGuard = ({ children }) => {
  const { isLoading, currentUser, authState } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !currentUser) {
      if (authState === "loggingOut") return; // Prevent redirect loop when user is logging out
      // Get Current Path
      const currentPath = window.location.pathname.replace(
        "forensic-requests/",
        ""
      );

      navigate(`/login?redirect=${currentPath}`);
    } else if (!isLoading && currentUser) {
      // Check if user requires account setup
      if (!currentUser.first_name || !currentUser.last_name) {
        const signupData = JSON.parse(
          window.localStorage.getItem("post-signup-data")
        );
        if (signupData) {
          navigate(
            `/account-setup?redirect=${signupData.redirect}&slug=${signupData.slug}`
          );
        } else navigate("/account-setup");
      }
    }
  }, [isLoading, currentUser]);

  if (isLoading || !currentUser) return null;

  return <>{children}</>;
};

export default AuthGuard;
