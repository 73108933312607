import styled from "styled-components";
import { ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import MonolithEvidenceService from "../../../api/Monolith/Evidence/index.js";
import RequestService from "../../../api/requests/index.js";
import BreadCrumbs from "../../../components/BreadCrumbs/index.js";
import Loader from "../../../components/Loader.js";
import { useAuth } from "../../../contexts/AuthContext.js";
import AcquisitionList from "./components/AcquisitionList.js";
import ChainOfCustodyList from "./components/ChainOfCustodyList.js";
import ChildEvidenceList from "./components/ChildEvidenceList.js";
import EvidencePhotosList from "./components/EvidencePhotosList.js";
import ProgressBar from "../../../Monolith-UI/ProgressBar/ProgressBar.js";
import DetailItem from "../../../components/DetailItem.js";
import SectionHeader from "../../../components/SectionHeader.js";
import TaskButton from "../../../Monolith-UI/TaskButton.js";
import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";

const buttonStyle = {
  minWidth: "150px",
  padding: "2px 5px",
};

const PageHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
`;

const PageTitle = styled.div`
  margin: 0;
`;

const Title = styled.h2`
  margin: 0;
`;

const SubTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const PageContent = styled.div``;

const EvidenceView = styled(({ className }) => {
  const { requestID, tenantSlug, evidenceUUID } = useParams();

  const { currentUser } = useAuth();
  const theme = useTheme();
  const [group, setGroup] = useState("item-details");

  const monolithTenant = currentUser.tenants.find(
    (tenant) => tenant.tenant_slug === tenantSlug
  );

  const { data } = useQuery(
    ["evidence", { uuid: evidenceUUID, monolithTenant }],
    () =>
      MonolithEvidenceService.getEvidence({
        uuid: evidenceUUID,
        tenantSlug,
        monolith_tenant: monolithTenant.monolith_tenant,
        include_acquisitions: true,
        include_children: true,
        include_photos: true,
        include_coc: true,
      }),
    {
      refetchOnWindowFocus: true,
      enabled: !!currentUser,
    }
  );

  const { data: progressItems } = useQuery(
    ["evidence:progress", { monolith_tenant: monolithTenant.monolith_tenant }],
    () =>
      MonolithEvidenceService.getEvidenceProgress({
        monolith_tenant: monolithTenant.monolith_tenant,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: requestInfo } = useQuery({
    queryKey: ["request", { request_id: requestID, data_only: true }],
    queryFn: () =>
      RequestService.getRequests({ request_id: requestID, data_only: true }),
    select: (data) => data[0],
    refetchOnWindowFocus: false,
  });

  if (!progressItems || !data || !requestInfo) return <Loader />;

  const evidenceInfo = data ? data.data[0] : {};

  const progressInfo = !!progressItems ? progressItems : [];

  const isChild = evidenceInfo.is_parent !== 1;

  const navItems = [
    {
      name: "My Requests",
      path: "/my-requests",
    },
    {
      name: `${requestInfo?.request_name || requestID}`,
      path: `/requests/${tenantSlug}/${requestID}`,
    },
  ];

  if (isChild) {
    navItems.push({
      name: `Evidence ${evidenceInfo.evidence_number || evidenceInfo.uuid}`,
      path: `/requests/${tenantSlug}/${requestID}/evidence/${evidenceInfo.parent_uuid}`,
    });

    navItems.push({
      name: `Child Evidence ${
        evidenceInfo.evidence_number || evidenceInfo.uuid
      }`,
      path: `/requests/${tenantSlug}/${requestID}/evidence/${evidenceInfo.uuid}`,
    });
  } else {
    navItems.push({
      name: `Evidence ${evidenceInfo.evidence_number || evidenceInfo.uuid}`,
      path: `/requests/${tenantSlug}/${requestID}/evidence/${evidenceInfo.uuid}`,
    });
  }

  const handleChange = (event, newGroup) => {
    if (newGroup !== null) {
      setGroup(newGroup);
    }
  };

  const control = {
    value: group,
    onChange: handleChange,
    exclusive: true,
  };

  return (
    <>
      <Helmet title="Evidence Item" />
      <PageHeader>
        <Link to={-1}>
          <TaskButton title={""} variant="outlined">
            <KeyboardReturnOutlinedIcon style={{ fontSize: 20 }} />
          </TaskButton>
        </Link>
        <PageTitle>
          <Title style={{ display: "flex", alignItems: "baseline", gap: 5 }}>
            Evidence Details
            <SubTitle>
              {evidenceInfo.manufacturer ? ` ${evidenceInfo.manufacturer}` : ""}
              {evidenceInfo.item_type ? ` ${evidenceInfo.item_type}` : ""}
              {evidenceInfo.evidence_number
                ? ` (${evidenceInfo.evidence_number})`
                : ""}
            </SubTitle>
          </Title>
          <SubTitle>ID {evidenceInfo.uuid}</SubTitle>
        </PageTitle>
      </PageHeader>
      <div style={{ margin: "10px 0px" }}>
        <BreadCrumbs list={navItems} />
      </div>
      <div style={{ display: "flex", minHeight: 300 }}>
        <div
          style={{
            height: "auto",
            width: 425,
            maxWidth: 425,
            outline: `1px solid ${theme.palette.divider}`,
            borderRadius: 5,
            marginRight: 10,
            padding: 20,
          }}
        >
          <div style={{ marginBottom: 40 }}>
            <SectionHeader
              title="Evidence Number"
              description={
                "This is the evidence number assigned by the forensics team."
              }
            />
            <div style={{ marginBottom: 15 }}>
              {evidenceInfo.evidence_number}
            </div>
          </div>
          <div style={{ marginBottom: 40 }}>
            <SectionHeader
              title="Forensic Case Info"
              description="Details related to the forensic case associated with this request."
            />
            <DetailItem
              label={"Case Name"}
              value={requestInfo.case_info.case_ref}
            />
            <DetailItem
              label={"Case Number"}
              value={requestInfo.case_info.case_number}
            />
            <DetailItem
              label={"Case Lead"}
              value={
                requestInfo?.case_info?.case_lead?.full_name
                  ? `${requestInfo?.case_info?.case_lead?.full_name || ""}, ${
                      requestInfo?.case_info?.case_lead?.title || ""
                    }\n${
                      requestInfo?.case_info?.case_lead?.organization || ""
                    }\n${requestInfo?.case_info?.case_lead?.email || ""}`
                  : "No case lead assigned"
              }
            />
          </div>
          <div style={{ marginBottom: 40 }}>
            <SectionHeader
              title="Evidence Details"
              description="Details determined by the forensics team."
            />
            <>
              <DetailItem label={"Type"} value={evidenceInfo.item_type} />
              <DetailItem
                label={"Provider"}
                value={evidenceInfo.manufacturer}
              />
              <DetailItem label={"Item Name"} value={evidenceInfo.model_name} />
              <DetailItem
                label={"Model/Service"}
                value={evidenceInfo.model_number}
              />
              <DetailItem
                label={"Unique Identifier"}
                value={evidenceInfo.serial_number}
              />
              <DetailItem
                label={"Size"}
                value={
                  evidenceInfo.capacity
                    ? `${evidenceInfo.capacity} ${evidenceInfo.capacity_unit}`
                    : null
                }
              />
              <DetailItem label={"Progress"} value={evidenceInfo.progress} />
              <DetailItem label={"Evidence UUID"} value={evidenceInfo.uuid} />
              {evidenceInfo?.custom_attributes?.map((field, index) => {
                if (field.editor_type === "dateBox")
                  field.value = moment(field.value).format("MMMM, DD YYYY");

                return (
                  <DetailItem
                    key={field.field_id}
                    label={field.field_name}
                    value={field.value}
                  />
                );
              })}
            </>
          </div>
        </div>
        <div
          style={{
            minHeight: 300,
            width: 800,
            outline: `1px solid ${theme.palette.divider}`,
            borderRadius: 5,
            marginLeft: "auto",
            flexGrow: 1,
            padding: 20,
          }}
        >
          <div style={{ margin: "auto", marginBottom: 35, display: "flex" }}>
            <ToggleButtonGroup
              size="small"
              {...control}
              style={{ margin: "auto" }}
            >
              <ToggleButton
                value="item-details"
                size="small"
                disableRipple={true}
                style={buttonStyle}
              >
                <>Item Details</>
              </ToggleButton>
              <ToggleButton
                value="chain-of-custody"
                size="small"
                disableRipple={true}
                style={buttonStyle}
              >
                <>Chain of Custody</>
              </ToggleButton>
              <ToggleButton
                value="evidence-photos"
                size="small"
                disableRipple={true}
                style={buttonStyle}
              >
                <>Evidence Photos</>
              </ToggleButton>
              {/* <ToggleButton
            value="access-requests"
            key="access-requests"
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            <>Access Requests</>
          </ToggleButton> */}
              {/* <ToggleButton
                value="custom-field-options"
                key="custom-field-options"
                size="small"
                disableRipple={true}
                style={buttonStyle}
              >
                <>Custom Field Options</>
              </ToggleButton> */}
            </ToggleButtonGroup>
          </div>
          {group === "item-details" && (
            <>
              <SectionHeader
                title="Progress"
                description="The current status of this evidence item."
              />
              <div style={{ minHeight: 50, whiteSpace: "pre-line" }}>
                <ProgressBar
                  defaultValue={evidenceInfo.progress}
                  progressItems={progressInfo}
                  enabled={false}
                  // evidence={evidenceInfo}
                />
              </div>
              <SectionHeader
                title="Description"
                description="Description of the evidence determined by the forensics team."
              />
              <div style={{ minHeight: 150, whiteSpace: "pre-line" }}>
                {evidenceInfo.description}
              </div>
              <SectionHeader
                title="Child Evidence"
                description="These are evidence items that were found to be a part of the main evidence item: hard drives, MicroSD cards, etc..."
              />
              <div style={{ minHeight: 50, whiteSpace: "pre-line" }}>
                <ChildEvidenceList evidenceInfo={evidenceInfo} />
              </div>
              <SectionHeader
                title="Acquisitions"
                description="This is the forensic data that has been collected and preserved by the forensic team for this evidence item."
              />
              <AcquisitionList evidenceInfo={evidenceInfo} />
            </>
          )}
          {group === "chain-of-custody" && (
            <>
              <SectionHeader
                title="Chain of Custody"
                description="These are the chain of custody records for this evidence item."
              />
              <ChainOfCustodyList evidenceInfo={evidenceInfo} />
            </>
          )}
          {group === "evidence-photos" && (
            <>
              <SectionHeader
                title="Evidence Photos"
                description="Evidence photos taken and uploaded by the forensic team for this evidence item."
              />
              <EvidencePhotosList evidenceInfo={evidenceInfo} />
            </>
          )}
        </div>
      </div>
    </>
  );
})``;

export default EvidenceView;
