import { styled, useTheme } from "styled-components";
import { useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { TextBox } from "devextreme-react/ui/text-box.js";

//ICONS
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";

import { useAuth } from "../../contexts/AuthContext.js";
import Table, { Column, useTable } from "../../Monolith-UI/Table/Table.js";
import ColumnDefs from "../../components/Reqeusts/ColumnDefs.js";
import RequestService from "../../api/requests/index.js";
import PageHeader from "../../components/PageHeader.js";
import ComboButton from "../../Monolith-UI/ComboButton/ComboButton.js";
import PagingOptions from "../../components/PagingOptions.js";
import Pagination from "../../Monolith-UI/Pagination/Pagination.js";

const stateStoreKey = "requests:me";

const ViewRequests = styled(({ className }) => {
  const { currentUser } = useAuth();
  const theme = useTheme();
  const [pageSize, setPageSize] = useState(20);
  const [search, setSearch] = useState(null);

  const queryClient = useQueryClient();

  const table = useTable();

  const [columnState, setColumnState] = useState(
    (() => {
      const savedState = JSON.parse(localStorage.getItem(stateStoreKey));
      if (savedState?.cols) {
        return savedState.cols.map((c) => {
          const col =
            ColumnDefs.find((cd) => cd.dataField === c.dataField) || {};
          return {
            ...col,
            ...c,
          };
        });
      }
      return ColumnDefs;
    })()
  );

  const currentSort = useMemo(() => {
    let [sort] = columnState
      ?.filter((c) => c.sorting?.active)
      .map((c) => ({ field: c.dataField, sort: c.sorting?.direction }));

    return sort;
  }, [columnState]);

  const [query, setQuery] = useState({
    user_id: currentUser?.user_id,
    pageSize,
    order: currentSort,
    search,
  });

  const { data } = useQuery({
    queryKey: [stateStoreKey, { ...query, pageSize, search }],
    queryFn: () => RequestService.getRequests({ ...query, pageSize, search }),
    enabled: !!currentUser?.user_id,
    keepPreviousData: true,
  });

  // Sync column state to local storage
  useEffect(() => {
    let oldState = JSON.parse(localStorage.getItem(stateStoreKey)) || {};
    localStorage.setItem(
      stateStoreKey,
      JSON.stringify({
        ...oldState,
        cols: columnState,
      })
    );
  }, [columnState]);

  if (!data) return <div>Loading...</div>;

  const handleRefresh = () => {
    handleReload();
    // setHasInitialized(false);
    // queryClient
    //   .refetchQueries([
    //     stateStoreKey,
    //     {
    //       query: {
    //         ...query?.query,
    //         order: query?.query?.order || currentSort,
    //         pageSize,
    //         user_id: userID,
    //       },
    //     },
    //   ])
    //   .then(() => setHasInitialized(true));
  };
  const handleReload = () => {
    queryClient.refetchQueries([
      stateStoreKey,
      {
        ...query,
        order: query?.order || currentSort,
        pageSize,
      },
    ]);
  };
  const handleSort = (field) => {
    const savedColumn =
      columnState?.find((svc) => field === svc.dataField) || {};
    const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

    let newOrder = null;

    // if sorted on a different column, reset the sort
    if (query?.order?.field !== field) {
      newOrder = {
        field,
        sort: "asc",
      };
    }

    // otherwise rotate the sort options on the current column
    else {
      switch (order) {
        case "asc":
          newOrder = {
            field,
            sort: "desc",
          };
          break;
        case "desc":
          newOrder = null;
          break;
        default:
          newOrder = {
            field,
            sort: "asc",
          };
      }
    }

    let newCols = columnState?.map((c) => {
      if (c.dataField === field) {
        return {
          ...c,
          sorting: {
            active: newOrder ? true : false,
            direction: newOrder?.sort,
          },
        };
      }
      delete c.sorting;
      return c;
    });

    let newQuery = {
      ...query,
      order: newOrder,
    };

    setColumnState(newCols);

    setQuery((q) => ({ ...q, ...newQuery }));
  };
  const handleColumnReorder = (newOrder) => {
    setColumnState((cs) => {
      return newOrder.map((o) => {
        return {
          ...cs.find((c) => c.dataField === o.column),
          order: o.order,
        };
      });
    });
  };
  const handleColumnResize = (e) => {
    setColumnState((cs) => {
      return cs.map((c) => {
        const col = e.columns.find((col) => col.dataField === c.dataField);
        if (col) {
          return {
            ...c,
            width: parseInt(col.width.replace(/px/g, "")),
          };
        }
        return c;
      });
    });
  };
  const handleColumnVisibility = (column, visible) => {
    setColumnState((cs) => {
      return cs.map((c) => {
        if (c.dataField === column.dataField) {
          return {
            ...c,
            visible,
          };
        }
        return c;
      });
    });
  };
  const handleExportCaseTable = () => {
    // show snackbar
    // enqueueSnackbar("Exporting case table...", {
    //   variant: "info",
    // });
    RequestService.exportRequestList({
      query: {
        ...query,
        order: query?.order || currentSort,
      },
      user_id: currentUser?.user_id,
      type: "xlsx",
      columns: columnState
        .filter((c) => c.visible !== false)
        .sort((a, b) => a.order - b.order)
        .map((c) => {
          return { dataField: c.dataField, header: c.caption, ...c };
        }),
      date_format: "YYYY-MM-DD",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }).then((res) => {
      const { signedUrl, filename } = res;
      const el = document.createElement("a");
      el.href = signedUrl.replace(
        "http://localhost:3002",
        "http://localhost:3001"
      );
      // el.target = "_blank";
      el.download = filename;
      el.click();
      // remove snackbar
    });
  };
  const handleClearFilters = () => {
    // queryFilter.current.clear();
  };
  const onRowClick = (row) => {};

  return (
    <div className={className}>
      <PageHeader
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 0,
          alignItems: "flex-start",
        }}
      >
        <PageHeader.Title2>My Requests</PageHeader.Title2>
        <PageHeader.SubTitle>
          Requests that you have submitted to forensic partners
        </PageHeader.SubTitle>
      </PageHeader>
      <div
        style={{
          display: "flex",
          flex: "initial",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          marginBottom: 10,
          marginTop: 20,
        }}
      >
        {data && (
          <div style={{ fontWeight: 600 }}>{data.total} Total Requests</div>
        )}
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            minWidth: "fit-content",
          }}
        >
          <ComboButton
            type="dropDown"
            data={PagingOptions}
            value={PagingOptions.find((p) => p.value === pageSize)}
            title={"Set Page Size"}
            showDropdownIcon={true}
            displayField="text"
            variant="outlined"
            useSelectMode={true}
            onItemSelect={(e) => setPageSize(e.value)}
            textColor={theme.palette.text.primary}
            dropDownTitle={() => {
              return (
                <div
                  style={{
                    margin: "5px 0px",
                    padding: 3,
                    color: theme.palette.text.secondary,
                    display: "flex",
                    alignItems: "center",
                    minWidth: 200,
                  }}
                >
                  Set Page Size
                </div>
              );
            }}
          />
          <ComboButton
            type="multi-select"
            data={columnState.filter((c) => c.showInColumnChooser !== false)}
            displayField="caption"
            idField={"dataField"}
            selectedItems={columnState.filter((c) => c.visible !== false)}
            variant="outlined"
            closeOnSelect={false}
            showSearch={true}
            dropDownTitle={() => {
              return (
                <div
                  style={{
                    margin: "5px 0px",
                    padding: 3,
                    color: theme.palette.text.secondary,
                    display: "flex",
                    alignItems: "center",
                    minWidth: 200,
                  }}
                >
                  Select Columns
                </div>
              );
            }}
            onItemDeSelect={(item) => {
              handleColumnVisibility(item, false);
            }}
            onItemSelect={(item) => {
              handleColumnVisibility(item, true);
            }}
            textColor={theme.palette.text.secondary}
            title={"Select Columns"}
          >
            <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            // onItemSelect={handleAddCondition}
            textColor={theme.palette.text.secondary}
            title={"Export Table"}
            onClick={handleExportCaseTable}
          >
            <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            // onItemSelect={handleAddCondition}
            textColor={theme.palette.text.secondary}
            title={"Clear Filters"}
            onClick={handleClearFilters}
          >
            <FilterListOffIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={table.isCompact ? "Zoom In" : "Zoom Out"}
            onClick={() => table.toggleCompact()}
          >
            {table.isCompact && (
              <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
            )}
            {!table.isCompact && (
              <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
            )}
          </ComboButton>
          <ComboButton
            type="button"
            variant={"outlined"}
            textColor={
              table.isStriped
                ? theme.palette.primary.main
                : theme.palette.text.secondary
            }
            title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
            onClick={() => table.toggleStripes()}
          >
            <ReorderOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            // value="+ Add Filter"
            // displayField="name"
            variant="outlined"
            // onItemSelect={handleAddCondition}
            textColor={theme.palette.text.secondary}
            title={"Refresh Table"}
            onClick={handleRefresh}
          >
            <SyncOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <TextBox
            stylingMode="filled"
            placeholder="Search Requests"
            labelMode="static"
            height={30}
            style={{ marginLeft: "10px" }}
            onKeyUp={(e) => {
              let searchText = e.event.currentTarget.value;
              if (
                e.event.code === "Enter" ||
                e.event.code === "NumpadEnter" ||
                searchText === ""
              ) {
                setSearch(searchText);
                // setQuery((query) => ({
                //   ...query,
                //   search: searchText === "" ? null : searchText,
                //   page: 1,
                // }));
              }
            }}
          />
        </div>
      </div>
      <div>
        <div
          style={{
            width: "100%",
            overflow: "auto",
            paddingBottom: 5,
          }}
        >
          <Table
            data={data ? data.data : []}
            rowHeight={46}
            reloadFn={handleReload}
            keyValue="request_id"
            tableInstance={table}
            onHeaderClick={(col) =>
              col?.sorting?.enabled === false ? null : handleSort(col.dataField)
            }
            onColumnReorder={handleColumnReorder}
            onColumnResize={handleColumnResize}
            showActionColumn={false}
          >
            {columnState.map((col) => {
              return <Column key={col.dataField} {...col} />;
            })}
          </Table>
        </div>
        <PaginationContainer
          query={query}
          setQuery={setQuery}
          pageCount={data?.pageCount || 0}
        />
      </div>
    </div>
  );
})``;

const PaginationContainer = styled(
  ({ className, pageCount, setQuery, query }) => {
    const [activePage, setPage] = useState(query?.query?.page || 1);

    const handleChange = (newPage) => {
      setPage(newPage);
      setQuery({ ...query, page: newPage });
    };

    useEffect(() => {
      if (query?.page !== activePage) setPage(query?.page || 1);
    }, [query]);

    return (
      <div className={className}>
        <Pagination
          total={pageCount || 0}
          page={activePage}
          alignment="right"
          withControls={true}
          withEdges={true}
          boundries={2}
          siblings={2}
          onChange={handleChange}
        />
      </div>
    );
  }
)`
  margin-top: 10px;
`;

export default ViewRequests;
