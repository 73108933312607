import PDFViewer from "./PDFViewer.js";
import NullViewer from "./NullViewer.js";
import MSDocViewer from "./MSDocViewer.js";
import PlainTextViewer from "./PlainTextViewer.js";
import ImageViewer from "./ImageViewer.js";
import CodeViewer from "./CodeViewer.js";
import MediaViewer from "./MediaViewer.js";

import fileExtensionsMap from "../../utils/fileExtensionsMap.js";
import Loader from "../../components/Loader.js";

function ViewSelector({ selectedItem, handleDownload, loading }) {
  if (loading) return <Loader message="Loading Document..." />;

  const isOnPremises = process.env.REACT_APP_API_MODE === "on-premises";
  const fileExtension = (selectedItem.file_name || selectedItem.name)
    .split(".")
    .pop()
    .toLowerCase();

  // Overide size restrictions for video and audio files due to streaming capabilities
  if (
    fileExtensionsMap.get(fileExtension) === "video" ||
    fileExtensionsMap.get(fileExtension) === "audio"
  )
    return <MediaViewer file={selectedItem} />;

  return (
    <>
      {fileExtensionsMap.get(fileExtension) === "undefined" ||
      (selectedItem.file_size || selectedItem.size) > 20000000 ? (
        <NullViewer
          file={selectedItem}
          handleDownload={handleDownload}
          typeCheck={true}
        />
      ) : fileExtension === "pdf" ? (
        <PDFViewer file={selectedItem} />
      ) : fileExtensionsMap.get(fileExtension) === "office" ? (
        isOnPremises ? (
          // <DocxViewer file={file} />
          <NullViewer file={selectedItem} handleDownload={handleDownload} />
        ) : (
          <MSDocViewer file={selectedItem} />
        )
      ) : fileExtensionsMap.get(fileExtension) === "text" ? (
        <PlainTextViewer file={selectedItem} />
      ) : fileExtensionsMap.get(fileExtension) === "image" ? (
        <ImageViewer file={selectedItem} />
      ) : fileExtensionsMap.get(fileExtension) === "code" ? (
        <CodeViewer file={selectedItem} />
      ) : (
        <NullViewer file={selectedItem} handleDownload={handleDownload} />
      )}
    </>
  );
}

export default ViewSelector;
