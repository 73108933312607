import { httpGet, httpPost } from "../connection.js";

const grantUserAccess = async (values = {}) => {
  return await httpPost("/user-invites/grant", values);
};

const denyUserAccess = async (values = {}) => {
  return await httpPost("/user-invites/deny", values);
};

const getUserInvites = async (values = {}) => {
  return await httpGet("/user-invites", values);
};

const requestTenantAccess = async (values = {}) => {
  return await httpPost("/user-invites/request-tenant-access", values);
};

const UserInvitesAPI = {
  grantUserAccess,
  denyUserAccess,
  getUserInvites,
  requestTenantAccess,
};

export default UserInvitesAPI;
