import { httpGet, httpPost } from "../../connection.js";

const getEvidence = async (query = {}) =>
  await httpPost(`/monolith/evidence`, query);

const getEvidenceProgress = async (query = {}) =>
  await httpGet(`/monolith/evidence/progress`, query);

const getEvidenceElements = async (query = {}) => {
  return await httpGet(`/monolith/evidence/elements`, query);
};

const getEvidenceTypes = async (query = {}) => {
  return await httpGet(`/monolith/evidence/types`, query);
};

const MonolithEvidenceService = {
  getEvidence,
  getEvidenceElements,
  getEvidenceProgress,
  getEvidenceTypes,
};

export default MonolithEvidenceService;
