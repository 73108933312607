import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import UserInvitesAPI from "../../api/UserInvites/index.js";
import { Button, Typography } from "@mui/material";
import { EmptyItem, Form, SimpleItem } from "devextreme-react/ui/form.js";
import { useRef } from "react";
import UserService from "../../api/users/users.js";
import { useAuth } from "../../contexts/AuthContext.js";

const UserInvite = styled(({ className }) => {
  const { invite_uuid } = useParams();

  const { data } = useQuery({
    queryKey: ["user-invite", invite_uuid],
    queryFn: () => UserInvitesAPI.getUserInvites({ invite_uuid }),
    enabled: true,
  });

  if (!data) return null;

  const inviteInfo = data[0];

  return (
    <div className={className}>
      <div className="content">
        <h1>Welcome to Relay!</h1>
        <div className="message">
          You've been invited to start submitting forensic requests to{" "}
          <span>{inviteInfo.tenant_org}</span>.
        </div>
        <div className="message">
          You're signing up as <span>{inviteInfo.invite_email}</span>.
        </div>
        <div className="message">
          We need a few more details to get started.
        </div>
        <div>
          <AccountDetailsForm inviteInfo={inviteInfo} />
        </div>
      </div>
    </div>
  );
})`
  padding: 2rem;
  color: ${(props) => props.theme.palette.text.primary};
  display: flex;
  .content {
    padding: 2rem;
    padding-top: 1rem;
    padding-bottom: 3rem;
    margin: auto;
    background-color: ${(props) => props.theme.palette.background.alt};
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
  .message {
    font-weight: 400;
    line-height: 1.5;
    font-size: 1rem;
    margin-top: 10px;

    span {
      font-weight: 600;
      color: #2196f3;
    }
  }
`;

const AccountDetailsForm = styled(({ className, inviteInfo }) => {
  const { setSessionData } = useAuth();
  const passwordForm = useRef(null);
  const userForm = useRef(null);
  const orgForm = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (!passwordForm.current.instance.validate().isValid) return;
    if (!userForm.current.instance.validate().isValid) return;
    if (!orgForm.current.instance.validate().isValid) return;

    const passwordFormData = passwordForm.current.instance.option("formData");
    const userFormData = userForm.current.instance.option("formData");
    const orgFormData = orgForm.current.instance.option("formData");

    UserService.processUserInvite({
      ...passwordFormData,
      ...userFormData,
      ...orgFormData,
      email: inviteInfo.invite_email,
      invite_uuid: inviteInfo.invite_uuid,
    }).then((res) => {
      setSessionData(res.user).then(() =>
        navigate(`/${inviteInfo.tenant_slug}`)
      );
    });
  };

  return (
    <div className={className}>
      <div style={{ marginTop: 40, marginBottom: 20, textAlign: "left" }}>
        <Typography variant="h3" gutterBottom display="inline">
          Set a password for your Relay account.
        </Typography>
        <div className="sub-title">Make sure its a good, strong password!</div>
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            textAlign: "left",
          }}
        >
          <Form ref={passwordForm} colCount={2}>
            <SimpleItem
              dataField="password"
              isRequired={true}
              label={{ visible: false }}
              editorOptions={{ placeholder: "Password", mode: "password" }}
              validationRules={[
                {
                  type: "stringLength",
                  min: 8,
                  message: "Password must be at least 8 characters long.",
                },
                {
                  type: "pattern",
                  pattern:
                    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+_\\-\\.@#$%^&*])",
                  message:
                    "Password must contain at least one uppercase letter, one lowercase letter, one number, and one symbol.",
                },
              ]}
            />
          </Form>
        </div>
      </div>
      <div style={{ marginTop: 40, marginBottom: 20, textAlign: "left" }}>
        <Typography variant="h3" gutterBottom display="inline">
          Whats your name?
        </Typography>
        <div className="sub-title">This will help with making requests.</div>
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            textAlign: "left",
          }}
        >
          <Form ref={userForm} colCount={2}>
            <SimpleItem
              dataField="first_name"
              isRequired={true}
              label={{ visible: false }}
              editorOptions={{ placeholder: "First Name" }}
            />
            <SimpleItem
              dataField="last_name"
              isRequired={true}
              label={{ visible: false }}
              editorOptions={{ placeholder: "Last Name" }}
            />
          </Form>
        </div>
      </div>
      <div style={{ marginTop: 40, textAlign: "left" }}>
        <Typography variant="h3" gutterBottom display="inline">
          Tell us about your organization.
        </Typography>
        <div className="sub-title">What agency or company do you work for?</div>
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            textAlign: "left",
          }}
        >
          <Form ref={orgForm} colCount={2}>
            <SimpleItem
              dataField="org"
              isRequired={true}
              label={{ visible: false }}
              editorOptions={{ placeholder: "Organization Name" }}
            />
            <EmptyItem />
            <SimpleItem
              dataField="address"
              isRequired={true}
              colSpan={2}
              label={{ visible: false }}
              editorOptions={{ placeholder: "Address" }}
            />
            <SimpleItem
              dataField="city"
              isRequired={true}
              label={{ visible: false }}
              editorOptions={{ placeholder: "City" }}
            />
            <SimpleItem
              dataField="state"
              isRequired={true}
              label={{ visible: false }}
              editorOptions={{ placeholder: "State/Province" }}
            />
            <SimpleItem
              dataField="zipcode"
              isRequired={true}
              label={{ visible: false }}
              editorOptions={{ placeholder: "Postal Code" }}
            />
            <SimpleItem
              dataField="phone"
              isRequired={true}
              label={{ visible: false }}
              editorOptions={{ placeholder: "Phone Number" }}
            />
          </Form>
        </div>
      </div>
      <div className="action-btns">
        <div className="view-request-btn">
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSubmit}
          >
            Complete Account Setup
          </Button>
        </div>
      </div>
    </div>
  );
})`
  .sub-title {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-weight: 400;
    line-height: 1.5;
  }
  .action-btns {
    display: flex;
    margin-top: 40px;
  }
  .view-request-btn {
    margin-left: auto;
  }
`;

export default UserInvite;
