import { Navigate } from "react-router-dom";
import AuthGuard from "./components/AuthGuard";
import AuthLayout from "./Layouts/Auth";
import DashboardLayout from "./Layouts/Dashboard";
import Login from "./pages/Auth/Login";
import Page404 from "./pages/Auth/Page404";
import ResetPassword from "./pages/Auth/ResetPassword";
import MyRequests from "./pages/MyRequests/MyRequests";
import RequestForm from "./pages/Requests";
import NewRequest from "./pages/Requests/NewRequests/NewRequest";
import EvidenceView from "./pages/Requests/EvidenceView/EvidenceView.js";
import RequestView from "./pages/Requests/RequestView/RequestView";
import Settings from "./pages/Settings";
import UserProfile from "./pages/Users/UserProfile";
import RequestSubmitted from "./pages/Requests/NewRequests/RequestSubmitted.js";
import AccountSetup from "./pages/Auth/AccountSetup.js";
import GrantUserAccess from "./components/GrantUserAccess.js";
import SignUp from "./pages/Auth/SignUp.js";
import UserInvite from "./pages/Auth/UserInvite.js";
import AllRequests from "./pages/Tenant/ViewRequests/AllRequests.js";
import CaseDrives from "./pages/CaseDrives/CaseDrives.jsx";
import SSOCallback from "./pages/Auth/SSOCallback.js";
import CaseDrivesView from "./pages/CaseDrives/CaseDrivesView.jsx";
import CaseDriveContentsView from "./pages/CaseDrives/CaseDriveContentsView.jsx";

const unprotectedRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/auth/sso/callback",
    element: <SSOCallback />,
  },
  {
    path: "/user-invite/:invite_uuid",
    element: <UserInvite />,
  },
  {
    path: "/password-reset/:uuid",
    element: <ResetPassword />,
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

const protectedRoutes = [
  {
    path: "/",
    element: <Navigate to="/my-requests" />,
  },
  {
    path: "/account-setup",
    element: <AccountSetup />,
  },
  {
    path: "/my-requests",
    element: <DashboardLayout />,
    children: [
      {
        path: "/my-requests",
        element: <MyRequests />,
      },
    ],
  },
  {
    path: "/requests",
    element: <DashboardLayout />,
    children: [
      {
        path: "/requests/:tenantSlug/:requestID",
        element: <RequestView />,
      },
      {
        path: "/requests/:tenantSlug/:requestID/evidence/:evidenceUUID",
        element: <EvidenceView />,
      },
    ],
  },
  {
    path: "/:tenantSlug",
    element: <DashboardLayout />,
    children: [
      {
        path: "/:tenantSlug",
        element: <RequestForm />,
      },
      {
        path: "/:tenantSlug/all-requests",
        element: <AllRequests />,
      },
      {
        path: "/:tenantSlug/case-drives",
        element: <CaseDrives />,
        children: [
          {
            path: "",
            element: <CaseDrivesView />,
          },
          {
            path: ":case_uuid",
            element: <CaseDriveContentsView />,
          },
          {
            path: ":case_uuid/folder/:parent_object_id",
            element: <CaseDriveContentsView />,
          },
        ],
      },
      {
        path: "/:tenantSlug/grant-user-access/:inviteUUID",
        element: <GrantUserAccess />,
      },
      {
        path: "/:tenantSlug/new-request",
        element: <NewRequest />,
      },
      {
        path: "/:tenantSlug/submit-request",
        element: <Navigate to="/my-requests" />,
      },
      {
        path: "/:tenantSlug/new-request/submitted",
        element: <RequestSubmitted />,
      },
    ],
  },
  {
    path: "/users",
    element: <DashboardLayout />,
    children: [
      {
        path: "/users/:user_email/user-details",
        element: <UserProfile />,
      },
    ],
  },
  {
    path: "/settings",
    element: <DashboardLayout />,
    children: [
      {
        path: "/settings",
        element: <Settings />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
].map((route) => {
  route.element = <AuthGuard>{route.element}</AuthGuard>;
  return route;
});

const routes = [...unprotectedRoutes, ...protectedRoutes];

export default routes;
