import Mention from "@tiptap/extension-mention";

const CustomMention = Mention.extend({
  addAttributes() {
    return {
      ...this.parent(),
      "user-email": {
        default: "",
      },
      "user-name": {
        default: "",
      },
    };
  },
});

export default CustomMention;
