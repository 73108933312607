import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthService } from "../../api/auth/index";
import { useAuth } from "../../contexts/AuthContext";
// import LoggingAPI from "../../api/logging/index.js";

const SSOCallback = () => {
  const { setSessionData } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");
  const samlToken = searchParams.get("samlToken");

  useEffect(() => {
    if (!code && !samlToken) navigate("/login"); // If no code or samlToken, redirect to login

    AuthService.ssoLogin({ code, samlToken }).then(({ userInfo }) => {
      if (!userInfo) return navigate("/login");

      // LoggingAPI.logCommonActivity({
      //   user_email: userInfo.email,
      //   message: "User logged in with Single Sign On",
      // });

      setSessionData(userInfo);
      navigate("/");
    });
  }, [code, samlToken, navigate, setSessionData]);
};

export default SSOCallback;
