import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import CaseDrivesTable from "./CaseDrivesTable.jsx";

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const CaseDrivesView = styled(({ className }) => {
  const { tenantSlug } = useParams();
  const navigate = useNavigate();

  const handleOpenDrive = (data) => {
    navigate(`/${tenantSlug}/case-drives/${data.case_uuid}`);
  };

  return (
    <div className={className}>
      <Helmet title={`Case Drives`} />
      <Title className="title">Case Drives</Title>
      <CaseDrivesTable onDriveSelect={handleOpenDrive} />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
`;

export default CaseDrivesView;
