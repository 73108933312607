import moment from "moment";

export function getDateFormat(isMoment = true, includeTime = true) {
  let dateFormat = window.localStorage.getItem("dateFormat.date");
  let timeFormat = window.localStorage.getItem("dateFormat.time");

  if (!dateFormat) dateFormat = "YYYY-MM-DD";
  if (!timeFormat) timeFormat = "HH:mm:ss";

  let userSetting = dateFormat + " " + timeFormat;

  if (isMoment) return dateFormat + (includeTime ? ` ${timeFormat}` : "");
  else {
    switch (dateFormat) {
      case "YYYY-MM-DD":
        userSetting = "yyyy-MM-dd";
        break;
      case "DD/MM/YYYY":
        userSetting = "dd/MM/yyyy";
        break;
      case "MM/DD/YYYY":
        userSetting = "MM/dd/yyyy";
        break;
      default:
        userSetting = "yyyy-MM-dd";
    }

    if (includeTime === true) {
      return userSetting + " " + timeFormat.replace("A", "a");
    } else {
      return userSetting;
    }
  }
}

//convert from UTC to local time - for displaying times in Monolith
export function monolithMoment(options) {
  let format = window.localStorage.getItem("dateFormat.date");
  let timeFormat = window.localStorage.getItem("dateFormat.time");

  if (!timeFormat) timeFormat = "HH:mm";
  if (!format) format = "YYYY-MM-DD";

  if (options === undefined) options = {};
  if (options.timestamp === undefined) options.timestamp = false;
  if (options.includeTime === undefined) options.includeTime = false;
  if (options.includeZone === undefined) options.includeZone = false;
  if (options.format !== undefined) {
    format = options.format;
  } else {
    if (options.includeTime) format += " " + timeFormat;
    if (options.includeZone) format += " ZZ";
  }

  if (options.timestamp == false) return moment().format(format);
  else return moment.utc(options.timestamp).local().format(format);
}

//convert to UTC - used to store timestamps in db
export function db_timestamp(timestamp, isISO = false) {
  if (timestamp === undefined) timestamp = false;

  if (isISO === true)
    return timestamp ? moment(timestamp).toISOString() : moment().toISOString();
  else
    return timestamp
      ? moment(timestamp).utc().format("YYYY-MM-DD HH:mm:ss")
      : moment().utc().format("YYYY-MM-DD HH:mm:ss");
}

export function validateTimestamp(timestamp) {
  if (timestamp.value)
    return parseInt(moment(timestamp.value).format("YYYY")) > 1900;

  return true;
}
