import styled from "styled-components";
import { Button, Divider, Typography, useTheme } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { useSnackbar } from "notistack";
import { useRef } from "react";
import UserService from "../../api/users/users.js";
import Loader from "../../components/Loader.js";
import { useAuth } from "../../contexts/AuthContext.js";

const UserProfile = () => {
  const { currentUser } = useAuth();

  const { isLoading, data } = useQuery({
    queryKey: ["user", { email: currentUser.email }],
    queryFn: () => UserService.getUsers({ email: currentUser.email }),
  });

  if (isLoading) return <Loader />;

  const [{ first_name, last_name, org, address, city, state, zipcode, phone }] =
    data;

  return (
    <>
      <UserView
        user={{
          first_name,
          last_name,
          org,
          address,
          city,
          state,
          zipcode,
          phone,
        }}
      />
    </>
  );
};

const UserView = styled(({ className, user }) => {
  const theme = useTheme();
  const userForm = useRef(null);
  const { currentUser, setSessionData } = useAuth();
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    if (!userForm.current.instance.validate().isValid) return;

    const userFormData = userForm.current.instance.option("formData");

    UserService.updateUser({
      ...userFormData,
      email: currentUser.email,
    })
      .then((res) => {
        queryClient.setQueryData(
          ["user", { email: currentUser.email }],
          [
            {
              ...userFormData,
              email: currentUser.email,
            },
          ]
        );
        enqueueSnackbar("Profile updated successfully", { variant: "success" });
        setSessionData({ ...userFormData, email: currentUser.email });
      })
      .catch((err) => {});
  };

  return (
    <div className={className}>
      <div style={{ marginTop: 20 }}>
        <Typography variant="h3" display="inline">
          Edit Your Profile
        </Typography>
        <div style={{ color: theme.palette.text.secondary, marginTop: 5 }}>
          Edit your profile information.
        </div>
        <>
          <div style={{ marginTop: 20, textAlign: "left" }}>
            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
                textAlign: "left",
                maxWidth: 500,
              }}
            >
              <Form ref={userForm} colCount={2}>
                <SimpleItem
                  dataField="first_name"
                  isRequired={true}
                  label={{ visible: false }}
                  editorOptions={{
                    placeholder: "First Name",
                    value: user.first_name,
                  }}
                />
                <SimpleItem
                  dataField="last_name"
                  isRequired={true}
                  label={{ visible: false }}
                  editorOptions={{
                    placeholder: "Last Name",
                    value: user.last_name,
                  }}
                />
                <SimpleItem
                  dataField="org"
                  colSpan={2}
                  isRequired={true}
                  label={{ visible: false }}
                  editorOptions={{
                    placeholder: "Organization Name",
                    value: user.org,
                  }}
                />
                <SimpleItem
                  dataField="address"
                  isRequired={true}
                  colSpan={2}
                  label={{ visible: false }}
                  editorOptions={{
                    placeholder: "Address",
                    value: user.address,
                  }}
                />
                <SimpleItem
                  dataField="city"
                  isRequired={true}
                  label={{ visible: false }}
                  editorOptions={{ placeholder: "City", value: user.city }}
                />
                <SimpleItem
                  dataField="state"
                  isRequired={true}
                  label={{ visible: false }}
                  editorOptions={{
                    placeholder: "State/Province",
                    value: user.state,
                  }}
                />
                <SimpleItem
                  dataField="zipcode"
                  isRequired={true}
                  label={{ visible: false }}
                  editorOptions={{
                    placeholder: "Postal Code",
                    value: user.zipcode,
                  }}
                />
                <SimpleItem
                  dataField="phone"
                  isRequired={true}
                  label={{ visible: false }}
                  editorOptions={{
                    placeholder: "Phone Number",
                    value: user.phone,
                  }}
                />
              </Form>
            </div>
          </div>
          <div className="action-btns">
            <div className="view-request-btn">
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleSubmit}
              >
                Update Profile
              </Button>
            </div>
          </div>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        </>
      </div>
    </div>
  );
})`
  padding: 20px;
  .bc-container {
    margin-bottom: 20px;
  }
`;

export default UserProfile;
