import { httpGet, httpPost } from "../connection.js";

/// Auth Services
const getStatus = async () => {
  return await httpGet(`/monolith-api-test`);
};

const getAccessToken = async (values = {}) =>
  await httpPost(`/auth/token`, values);

const userLogin = async (values = {}) => {
  return await httpPost(`/auth/login`, values);
};

const ssoLogin = async (values = {}) => {
  return await httpPost(`/auth/sso/callback`, values);
};

const checkSession = async (values = {}) => {
  return await httpGet(`/check-session`, values);
};

const userLogout = async (values = {}) => {
  return await httpPost(`/auth/logout`, values);
};

const resetPasswordRequest = async (values = {}) => {
  return await httpPost(`/auth/password-reset`, values);
};

const updatePassword = async (values = {}) => {
  return await httpPost(`/auth/update-password`, values);
};

const checkPasswordReset = async (values = {}) => {
  return await httpGet(`/auth/check-password-reset`, values);
};

const userSignup = async (values = {}) => {
  return await httpPost(`/auth/signup`, values);
};

export const AuthService = {
  getStatus,
  userLogin,
  ssoLogin,
  userSignup,
  checkSession,
  userLogout,
  resetPasswordRequest,
  updatePassword,
  checkPasswordReset,
  getAccessToken,
};
