import styled from "styled-components";

// Icons
import ViewSelector from "./ViewSelector.js";
import { Button } from "@monolith-forensics/monolith-ui";
import { DownloadIcon, XIcon } from "lucide-react";

const FileViewer = styled(
  ({
    className,
    file,
    open,
    handleClose = () => {},
    handleFileDownload,
    loading,
  }) => {
    const handleBackgroundClick = (e) => {
      if (e.target === e.currentTarget) {
        handleClose();
      }
    };
    if (!open) return null;

    return (
      <div className={className} onClick={handleBackgroundClick}>
        <div className="display-area" onClick={handleBackgroundClick}>
          <div className="fileviewer-nav">
            <span>{file.file_name}</span>
            <div className="fileviewer-right-menu">
              <Button
                variant="text"
                size="xs"
                onClick={() => handleFileDownload()}
                leftSection={<DownloadIcon size={14} />}
                color="primary"
              >
                Download
              </Button>
              <Button
                variant="text"
                size="xs"
                onClick={() => handleClose()}
                leftSection={<XIcon size={14} />}
              >
                Close
              </Button>
            </div>
          </div>
          <ViewSelector
            loading={loading}
            selectedItem={file}
            handleDownload={handleFileDownload}
          />
        </div>
      </div>
    );
  }
)`
  // Full Screen
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  // padding: 1rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.75);

  & .display-area {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
  }

  & .fileviewer-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding: 10px 0px 10px 0px;
  }

  & .fileviewer-right-menu {
    position: fixed;
    right: 0px;
    top: 0px;

    display: flex;
    flex-direction: row;
    gap: 10px;

    padding: 15px;
  }

  & .filename {
    display: flex;
    font-size: 15px;
    margin: auto;
    font-weight: 500;
  }
`;

export default FileViewer;
