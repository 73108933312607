import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
`;
export const CalendarContainer = styled.div`
  font-size: 5px;
  width: 250px;
  // border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 4px;
  overflow: hidden;
`;
export const CalendarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const CalendarGrid = styled.div`
  display: grid;
  grid-template: repeat(7, auto) / repeat(7, auto);
`;
export const CalendarMonth = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.primary};
  text-align: center;
  padding: 0.5em 0.25em;
  word-spacing: 5px;
  user-select: none;
`;
export const CalendarCell = styled.div`
  text-align: center;
  align-self: center;
  letter-spacing: 0.1rem;
  padding: 7px;
  user-select: none;
  border-radius: 5px;
  border: 1px solid transparent;
  grid-column: ${(props) => (props.index % 7) + 1} / span 1;
`;
export const CalendarDay = styled(CalendarCell)`
  font-weight: bold;
  font-size: 10px;
  border-radius: 0px;
  color: ${(props) => props.theme.palette.text.secondary};
  //   border-top: 1px solid ${(props) => props.theme.palette.divider};
  //   border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  border-right: ${(props) =>
    (props.index % 7) + 1 === 7
      ? `none`
      : `2px solid ${(props) => props.theme.palette.divider}`};
`;
export const CalendarDate = styled(CalendarCell)`
  font-weight: ${(props) => (props.inMonth ? 500 : 300)};
  font-size: 10px;
  cursor: pointer;
  border-bottom: ${(props) =>
    (props.index + 1) / 7 <= 5
      ? `1px solid ${(props) => props.theme.palette.divider}`
      : `1px solid transparent`};
  border-right: ${(props) =>
    (props.index % 7) + 1 === 7
      ? `1px solid transparent`
      : `1px solid ${(props) => props.theme.palette.divider}`};
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
  color: ${(props) =>
    props.inMonth
      ? props.theme.palette.text.primary
      : props.theme.palette.text.secondary};
  grid-row: ${(props) => Math.floor(props.index / 7) + 2} / span 1;
  transition: all 0.2s ease-out;
  border-radius: 5px;
  :hover {
    // color: ${(props) => props.theme.palette.primary.main};
    background: ${(props) => props.theme.palette.action.hover};
  }
`;
export const HighlightedCalendarDate = styled(CalendarDate)`
  //   color: ${(props) => props.theme.palette.primary.main} !important;
  color: white !important;
  background: ${(props) => props.theme.palette.primary.main} !important;
  border: 1px solid ${(props) => props.theme.palette.primary.main} !important;
  position: relative;
  ::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    // border: 2px solid #06c;
  }
`;
export const TodayCalendarDate = styled(HighlightedCalendarDate)`
  color: ${(props) => props.theme.palette.text.primary} !important;
  background: transparent !important;
  ::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    border-bottom: 0.75em solid #06c;
    border-left: 0.75em solid transparent;
    border-top: 0.75em solid transparent;
  }
  :hover {
    color: ${(props) => props.theme.palette.text.primary} !important;
    background: ${(props) => props.theme.palette.action.hover} !important;
  }
`;
export const TimeContainer = styled.div`
  font-size: 12px;
  width: 150px;
  height: 100%;
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-left: none;
  border-radius: 4px;
  overflow: hidden;
`;
export const TimePickerContainer = styled.div`
  display: flex;
  height: 100%;
`;
export const TimeHeader = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.primary};
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  text-align: center;
  padding: 5px;
  line-height: 2.3;
  height: 40px;
  //   word-spacing: 5px;
  user-select: none;
`;
export const TimeHourSelect = styled.div`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
  width: 50%;
  height: 250px;
  overflow-y: auto;
`;
export const TimeMinuteSelect = styled.div`
  width: 50%;
  height: 250px;
  overflow-y: auto;
`;
export const TimeItem = styled.div`
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.palette.text.primary};
  text-align: center;
  padding: 5px;
  //   word-spacing: 5px;
  user-select: none;
  cursor: pointer;
  transition: all 0.2s ease-out;
  :hover {
    background: ${(props) => props.theme.palette.action.hover};
  }
`;
export const TimeItemActive = styled(TimeItem)`
  background: ${(props) => props.theme.palette.primary.main};
  color: white;
  &:hover {
    background: ${(props) => props.theme.palette.primary.main};
  }
`;

export const NoValueButton = styled.button`
  font-weight: normal;
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.secondary};
  text-align: center;
  padding: 5px;
  user-select: none;
  cursor: pointer;
  transition: all 0.2s ease-out;
  border: none;
  border-radius: 5px;
  background: transparent;
  :hover {
    background: ${(props) => props.theme.palette.action.hover};
  }
`;
