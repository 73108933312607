import styled from "styled-components";
import { CircularProgress } from "@mui/material";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

function Loader({ message, style }) {
  return (
    <Root>
      <CircularProgress color="primary" style={style} />
      {message && <div style={{ margin: "10px 0px" }}>{message}</div>}
    </Root>
  );
}

export default Loader;
