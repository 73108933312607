import { Button } from "@mui/material";
import { useState } from "react";
import { styled } from "styled-components";

import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import useSuggestion from "./Suggestion.js";
import CustomMention from "./CustomMention.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  white-space: pre-wrap;
  background-color: ${({ theme }) => theme.palette.background.alt};
  min-height: 150px;
  height: 150px;

  transition: border-color 0.2s ease-in-out;

  &.is-focused {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  & > :first-child {
    height: 100%;
  }

  .ProseMirror {
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    min-height: 65px;

    font-size: 12px;
    font-family: inherit;
    font-weight: 600;
    color: inherit;
    background-color: transparent;

    &:focus {
      outline: none;
      border: none;
    }

    p {
      margin: 0;
    }

    // All Headers
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }

    /* Placeholder (at the top) */
    p.is-editor-empty:first-child::before {
      color: #888;
      font-weight: normal;
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }

    .mention {
      background-color: ${({ theme }) => theme.palette.background.default};
      cursor: pointer;
      margin-right: 1px;
      margin-left: 1px;
      color: ${({ theme }) => theme.palette.primary.main};

      &:hover {
        background-color: ${({ theme }) => theme.palette.background.alt};
        color: ${({ theme }) => theme.palette.text.primary};
        text-decoration: underline;
      }
    }
  }
`;

const MenuBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  // margin-top: auto;
  //   margin-bottom: 10px;
  // border-top: 1px solid ${({ theme }) => theme.palette.divider};
`;

const CommentEditor = ({
  onSubmit,
  defaultValue = "",
  placeholder = "Leave a comment | @mention someone...",
  readOnly = false,
  mentionItems = [
    { full_name: "Matt Danner", email: "matt@monolithforensics.com" },
  ],
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const suggestion = useSuggestion({
    items: mentionItems,
    displayKey: "full_name",
    valueKey: "email",
  });

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: placeholder,
      }),
      CustomMention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        suggestion,
      }),
    ],
    content: defaultValue,
    editable: !readOnly,
    onFocus: () => {
      setIsFocused(true);
    },
    onBlur: () => {
      setIsFocused(false);
    },
  });

  const handleSubmit = () => {
    const html = editor.getHTML();
    // Get mentions from HTML
    const mentionStrings = html.match(/<span data-type="mention".*?<\/span>/g);

    const mentions = mentionStrings?.map((mention) => {
      const email = mention.match(/user-email="(.*?)"/)[1];
      const full_name = mention.match(/user-name="(.*?)"/)[1];
      return { email, full_name };
    });

    onSubmit({ html, mentions });
    editor.commands.setContent("");
  };

  return (
    <Container className={isFocused ? "is-focused" : ""}>
      <EditorContent editor={editor} />
      <MenuBar>
        <Button
          variant="contained"
          color="primary"
          size="small"
          component="span"
          style={{
            minWidth: "fit-content",
            fontSize: 11,
            padding: "3px 6px",
          }}
          onClick={() => handleSubmit()}
        >
          Comment
        </Button>
      </MenuBar>
    </Container>
  );
};

export default CommentEditor;
