import moment from "moment";
import styled from "styled-components";
import { nanoid } from "nanoid";
import React, { useEffect, useMemo, useState } from "react";
import { useRef } from "react";
import { useTheme } from "@mui/material";
import CheckBox from "../CheckBox";

//Icons
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";

const enableResizeClass = (dataField) => {
  document
    .querySelectorAll(`.resizer.column-${dataField}`)
    .forEach((resizer) => {
      resizer.classList.add("isResizing");
    });
};

const disableResizeClass = (dataField) => {
  document
    .querySelectorAll(`.resizer.column-${dataField}`)
    .forEach((resizer) => {
      resizer.classList.remove("isResizing");
    });
};

const resizeHandler = ({
  event,
  columnId,
  columnProps,
  onResize,
  onResizeFinished,
}) => {
  let col = event.target.parentElement;
  let newColumns = [];
  let x = 0;
  let w = 0;

  const mouseMoveHandler = function (e) {
    const table = col.parentElement.parentElement.parentElement;
    const cols = table.querySelectorAll(".col");
    const colCount = cols.length;
    const selectedColumn = document.getElementById(`col-${columnId}`);
    const selectedColumnCurrentWidth = selectedColumn.offsetWidth;

    const dx = e.clientX - x;
    selectedColumn.style.width = `${w + dx}px`;

    // const isWidthDecreasing =
    //   selectedColumnCurrentWidth >= selectedColumn.offsetWidth;

    // console.log(
    //   "isWidthDecreasing",
    //   isWidthDecreasing,
    //   selectedColumnCurrentWidth,
    //   selectedColumn.offsetWidth
    // );

    newColumns = Array.from(cols).map((col, index) => {
      // const newWidth =
      //   index === colCount - 1
      //     ? isWidthDecreasing
      //       ? "auto"
      //       : col.offsetWidth <= 100
      //       ? "100px"
      //       : col.offsetWidth + "px"
      //     : col.offsetWidth + "px";

      const newWidth =
        index === colCount - 1
          ? col.offsetWidth <= 100
            ? "100px"
            : "auto"
          : col.offsetWidth + "px";

      col.style.width = newWidth;

      return {
        dataField: col.dataset.field,
        // columnId: col.dataset.dataField,
        width: newWidth,
      };
    });

    onResize({
      columns: newColumns,
    });
  };

  const mouseUpHandler = function () {
    disableResizeClass(columnId);
    document.removeEventListener("mousemove", mouseMoveHandler);
    document.removeEventListener("mouseup", mouseUpHandler);
    onResizeFinished?.({
      column: columnProps,
      columnId: columnId,
      columns: newColumns,
      targetColumn: document.getElementById(`col-${columnId}`),
      newWidth: document.getElementById(`col-${columnId}`).style.width,
    });
  };

  x = event.clientX;

  const styles = window.getComputedStyle(col);
  w = parseInt(styles.width, 10);

  document.addEventListener("mousemove", mouseMoveHandler);
  document.addEventListener("mouseup", mouseUpHandler);

  enableResizeClass(columnId);
};

const setColumnMinWidths = (table) => {
  const cols = table.querySelectorAll("th");

  cols.forEach((col) => {
    col.style.minWidth = col.offsetWidth + "px";
    col.style.width = col.offsetWidth + "px";
  });
};

const updateTableWidth = (table) => {
  const cols = table.querySelectorAll("th");

  table.style.width =
    [].reduce.call(cols, (sum, col) => sum + parseInt(col.style.width), 0) +
    "px";
};

export const useTable = () => {
  const [tableInstance, setTableInstance] = useState(null);

  return {
    setTableInstance,
    toggleCompact: () => tableInstance?.toggleCompact(),
    toggleStripes: () => tableInstance?.toggleStripes(),
    isCompact: tableInstance?.isCompact || false,
    isStriped: tableInstance?.isStriped || false,
    getVirtualSelectionTotal: () =>
      tableInstance?.getVirtualSelectionTotal() || 0,
    getSelectedRows: () => tableInstance?.getSelectedRows() || [],
    getExcludedRows: () => tableInstance?.getExcludedRows() || [],
    getSelectionState: () => tableInstance?.getSelectionState() || {},
    clearSelections: () => tableInstance?.clearSelections(),
    onColumnResize: (e) => tableInstance?.onColumnResize(e),
  };
};

const Table = styled(
  ({
    className,
    children,
    data,
    totalRecords = 0,
    onHeaderClick = () => {},
    onRowClick = () => {},
    onColumnReorder = () => {},
    onColumnResize = () => {},
    showActionColumn = false,
    onActionButtonClick = () => {},
    showSelection = false,
    focusedRow = null,
    onSelectionChanged = () => {},
    reloadFn = () => {},
    keyValue = "id",
    queryKey = null,
    tableInstance = null,
    compact = false,
    rowHeight = null,
    rowTitle = null,
    ...others
  }) => {
    const theme = useTheme();
    const dragColumn = useRef(null);
    const dropColumn = useRef(null);
    const [_compact, setCompact] = useState(compact);
    const [stripes, setStripes] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [virtualSelectionTotal, setVirtualSelectionTotal] = useState(0);
    const [excludedRows, setExcludedRows] = useState([]);
    const [selectionState, setSelectionState] = useState("none");
    const resizeState = useRef([]);

    let columns = useMemo(
      () =>
        children
          .filter((child) => child.type.displayName === "Column")
          // Set unique ID for each column
          .map((column) => {
            return React.cloneElement(column, {
              columnId: nanoid(),
            });
          }),
      [children]
    );

    const [columnOrder, setColumnOrder] = useState(
      columns.map((col, index) => {
        return { column: col.props.dataField, order: col.props.order || index };
      })
    );

    const handleColumnReorder = (dragColumn, dropColumn) => {
      const dropColumnOrder = columnOrder.find(
        (col) => col.column === dropColumn.dataField
      )?.order;

      const newColumnOrder = columnOrder.map((col) => {
        if (col.column === dragColumn.dataField) {
          return { column: col.column, order: dropColumnOrder + 1 };
        } else {
          return col.order > dropColumnOrder
            ? { ...col, order: col.order + 1 }
            : col;
        }
      });
      onColumnReorder(newColumnOrder);
      setColumnOrder(newColumnOrder);
    };

    const handleSelectAll = () => {
      let newSelectionState = null;
      let newVirtualSelectionTotal = null;

      if (selectionState === "all") {
        // clear current selections
        setSelectedRows([]);

        // turn off all rows selected
        newSelectionState = "none";
        setSelectionState("none");

        // clear excluded rows
        setExcludedRows([]);

        newVirtualSelectionTotal = 0;
        setVirtualSelectionTotal(newVirtualSelectionTotal);
      } else {
        // clear current selections
        // setSelectedRows([]);

        // turn on all rows selected
        newSelectionState = "all";
        setSelectionState("all");

        // clear excluded rows
        setExcludedRows([]);

        newVirtualSelectionTotal = totalRecords;
        setVirtualSelectionTotal(newVirtualSelectionTotal);
      }

      onSelectionChanged({
        selectedRows: [],
        excludedRows: [],
        selectionState: newSelectionState,
        virtualSelectionTotal: newVirtualSelectionTotal,
      });
    };

    const handleSelectRow = ({ rowData, checked }) => {
      let newSelectedRows = [];
      let newExcludedRows = [];
      let newSelectionState = null;
      let newVirtualSelectionTotal = null;

      if (selectionState === "all") {
        newExcludedRows = [...excludedRows, rowData];
        newSelectionState = "someExcluded";
        newVirtualSelectionTotal = totalRecords - 1;
      } else if (selectionState === "none") {
        newSelectedRows = [rowData];
        newSelectionState = "someIncluded";
        newVirtualSelectionTotal = 1;
      } else if (selectionState === "someExcluded") {
        newSelectionState = "someExcluded";
        newSelectedRows = selectedRows;

        if (checked) {
          // remove from excluded rows
          newExcludedRows = excludedRows.filter(
            (row) => row[keyValue] !== rowData[keyValue]
          );
          newVirtualSelectionTotal = virtualSelectionTotal + 1;
        } else {
          // add to excluded rows
          newExcludedRows = [...excludedRows, rowData];
          newVirtualSelectionTotal = virtualSelectionTotal - 1;
        }
      } else if (selectionState === "someIncluded") {
        newSelectionState = "someIncluded";
        newExcludedRows = excludedRows;

        if (checked) {
          // add to selected rows
          newSelectedRows = [...selectedRows, rowData];
          newVirtualSelectionTotal = virtualSelectionTotal + 1;
        } else {
          // remove from selected rows
          newSelectedRows = selectedRows.filter(
            (row) => row[keyValue] !== rowData[keyValue]
          );
          newVirtualSelectionTotal = virtualSelectionTotal - 1;
        }
      }

      if (newVirtualSelectionTotal === totalRecords) {
        newSelectionState = "all";
      } else if (newVirtualSelectionTotal === 0) {
        newSelectionState = "none";
      }

      setSelectedRows(newSelectedRows);
      setExcludedRows(newExcludedRows);
      setVirtualSelectionTotal(newVirtualSelectionTotal);
      setSelectionState(newSelectionState);
      onSelectionChanged({
        selectedRows: newSelectedRows,
        excludedRows: newExcludedRows,
        selectionState: newSelectionState,
        virtualSelectionTotal: newVirtualSelectionTotal,
      });
    };

    // sort columns
    columns = useMemo(
      () =>
        columns.sort((a, b) => {
          const aOrder = columnOrder.find(
            (col) => col.column === a.props.dataField
          )?.order;
          const bOrder = columnOrder.find(
            (col) => col.column === b.props.dataField
          )?.order;
          return aOrder - bOrder;
        }),
      [columnOrder, columns]
    );

    const hiddenColumns = columns
      .filter((col) => col.props.visible === false)
      .map((col) => col.props.dataField);

    // insert action column as first column
    showActionColumn &&
      (columns = [
        <Column
          key={nanoid()}
          dataField="action"
          allowSorting={false}
          allowReorder={false}
          caption=""
          width={showSelection ? 55 : 35}
          render={(rowData) => (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                padding: 4,
                // justifyContent: "space-between",
                color: theme.palette.text.secondary,
              }}
            >
              {showSelection && (
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    color: theme.palette.text.secondary,
                    marginRight: 5,
                  }}
                >
                  <CheckBox
                    checked={
                      selectionState === "all"
                        ? true
                        : selectionState === "someIncluded"
                        ? !!selectedRows.find(
                            (row) => row[keyValue] === rowData[keyValue]
                          )
                        : selectionState === "someExcluded"
                        ? !excludedRows.find(
                            (row) => row[keyValue] === rowData[keyValue]
                          )
                        : false
                    }
                    onChange={(e) =>
                      handleSelectRow({ rowData, checked: e.newValue })
                    }
                  />
                </div>
              )}
              <div
                className="action-button"
                title="Show More Details"
                style={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  color: theme.palette.primary.main,
                  marginRight: 5,
                }}
                onClick={(e) => {
                  onActionButtonClick(rowData);
                }}
              >
                <OpenInFullIcon style={{ fontSize: _compact ? 8 : 12 }} />
              </div>
            </div>
          )}
        />,
        ...columns,
      ]);

    const headerRow = columns
      .filter((col) => !hiddenColumns.includes(col.props.dataField)) // remove hidden columns
      .map((column) => {
        return (
          <TableHeader
            key={nanoid()}
            {...(others.columnProps || {})}
            {...column.props}
            compact={_compact}
            onClick={(e) => {
              if (column.props.allowSorting !== false)
                onHeaderClick(column.props);
            }}
            draggable={column.props.allowReorder !== false ? true : false}
            onDragOver={(e) => {
              e.preventDefault();
              e.dataTransfer.dropEffect = "move";
            }}
            onDragEnter={(e) => {
              e.target.classList.add("dragover");
              dropColumn.current = column.props;
            }}
            onDragLeave={(e) => {
              e.target.classList.remove("dragover");
            }}
            onDrop={(e) => {
              e.preventDefault();
              e.target.classList.remove("dragover");
              handleColumnReorder(dragColumn.current, dropColumn.current);
            }}
            onDragStart={(e) => {
              //set dragging class
              dragColumn.current = column.props;
              e.target.classList.add("dragging");
            }}
            onDragEnd={(e) => {
              e.target.classList.remove("dragging");
            }}
          >
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                pointerEvents:
                  column.props.dataField === "action" ? "all" : "none",
              }}
            >
              {showSelection && column.props.dataField === "action" ? (
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    color: theme.palette.text.secondary,
                    marginRight: 5,
                    marginLeft: 3,
                  }}
                >
                  <CheckBox
                    checked={selectionState === "all"}
                    partialCheck={selectionState.includes("some")}
                    onChange={handleSelectAll}
                  />
                </div>
              ) : (
                <div>{column.props.caption}</div>
              )}
              {column.props?.sorting?.active && (
                <div style={{ marginLeft: 5 }}>
                  {column.props.sorting.direction === "asc" ? (
                    <NorthIcon style={{ fontSize: 12 }} />
                  ) : (
                    <SouthIcon style={{ fontSize: 12 }} />
                  )}
                </div>
              )}
            </div>
            <Resizer
              resizeHandler={resizeHandler}
              draggable={true}
              column={column}
              onResize={(e) => {
                resizeState.current = e;
              }}
              onResizeFinished={(e) => {
                onColumnResize?.(e);
              }}
            />
          </TableHeader>
        );
      });

    const dataRows = data?.map((item) => {
      const isRowFocused = focusedRow
        ? focusedRow[keyValue] === item[keyValue]
        : false;
      const row = columns
        .filter((col) => !hiddenColumns.includes(col.props.dataField)) // remove hidden columns
        .map((column) => {
          let value = null;
          // parse datafield dot syntax
          if (column.props.dataField.includes(".")) {
            const fields = column.props.dataField.split(".");

            value = fields.reduce((acc, field) => {
              return acc[field];
            }, item);
          } else value = item[column.props.dataField];

          // format date values
          if (
            column.props.dataType === "date" &&
            column?.props?.format?.type &&
            value
          ) {
            value = moment(value).format(column.props.format.type);
          }

          if (column.props.render) {
            return (
              <TableCell
                key={nanoid()}
                className="td"
                rowHeight={rowHeight}
                title={
                  column?.props?.titleRender
                    ? column?.props?.titleRender(item)
                    : null
                }
                compact={_compact}
                style={{
                  padding: `${_compact ? "0px 3px" : "0px 8px"}`,
                }}
              >
                <Resizer column={column} />
                {column.props.render(item, {
                  queryKey: queryKey || "",
                  reload: reloadFn,
                })}
              </TableCell>
            );
          }

          return (
            <TableCell
              key={nanoid()}
              className="td"
              rowHeight={rowHeight}
              compact={_compact}
              title={
                column?.props?.titleRender
                  ? column?.props?.titleRender(item)
                  : null
              }
            >
              <Resizer column={column} />
              {value}
            </TableCell>
          );
        });

      return (
        <TableRow
          className={`tr ${isRowFocused ? "focused" : ""}`}
          onClick={(e) => {
            if (onRowClick) onRowClick(item);
          }}
          key={nanoid()}
          title={rowTitle ? rowTitle(item) : null}
        >
          {row}
        </TableRow>
      );
    });

    useEffect(() => {
      if (tableInstance) {
        tableInstance.setTableInstance({
          getSelectedRows: () => selectedRows,
          getExcludedRows: () => excludedRows,
          getVirtualSelectionTotal: () => virtualSelectionTotal,
          getSelectionState: () => selectionState,
          clearSelections: () => {
            setSelectedRows([]);
            setVirtualSelectionTotal(0);
            setSelectionState("none");
            setExcludedRows([]);
          },
          toggleCompact: () => setCompact(!_compact),
          isCompact: _compact,
          toggleStripes: () => setStripes(!stripes),
          isStriped: stripes,
          onColumnResize: (e) => {},
        });
      }
    }, [
      selectedRows,
      excludedRows,
      virtualSelectionTotal,
      selectionState,
      _compact,
      stripes,
    ]);

    return (
      <>
        {useMemo(() => {
          return (
            <div className={className + " mui-table"} {...others}>
              {dataRows && (
                <>
                  <ColGroup className="colgroup">
                    {columns
                      .filter(
                        (col) => !hiddenColumns.includes(col.props.dataField)
                      )
                      .map((column) => {
                        const savedColSize =
                          resizeState?.current?.columns?.find(
                            (col) => col.dataField === column.props.dataField
                          );

                        return (
                          <Col
                            id={`col-${column.props.columnId}`}
                            className="col"
                            key={nanoid()}
                            data-field={column.props.dataField}
                            // data-column-id={column.props.columnId}
                            style={{
                              width: savedColSize
                                ? savedColSize.width
                                : column.props.width
                                ? column.props.width
                                : "auto",
                            }}
                          />
                        );
                      })}
                  </ColGroup>
                  <TableHead>
                    <TableRow>{headerRow}</TableRow>
                  </TableHead>
                  <TableBody stripes={stripes}>{dataRows}</TableBody>
                </>
              )}
            </div>
          );
        }, [
          dataRows,
          headerRow,
          hiddenColumns,
          stripes,
          className,
          others,
          columns,
        ])}
      </>
    );
  }
)`
  width: 100%;
  // min-width: 100%;
  //   max-width: none;
  // width: ${({ width }) => width}px;
  display: table;
  table-layout: fixed;
  // border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px;
  overflow: hidden;
  //   font-size: 12px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    // box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    // border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

Table.displayName = "Table";

const Resizer = styled(
  ({
    className,
    resizeHandler,
    onResize = () => {},
    onResizeFinished,
    ...others
  }) => {
    return (
      <div
        className={`${className} resizer column-${others.column.props.columnId}`}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          e.preventDefault();
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          e.preventDefault();
          if (resizeHandler)
            resizeHandler({
              event: e,
              columnId: others.column.props.columnId,
              columnProps: others.column.props,
              onResize: onResize,
              onResizeFinished: onResizeFinished,
            });
        }}
      ></div>
    );
  }
)`
  &.resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background-color: ${(props) => props.theme.palette.divider};
    cursor: col-resize;
    user-select: none;
    touch-action: none;
    opacity: 0;
    &.isResizing {
      opacity: 1;
      background: ${(props) => props.theme.palette.primary.main};
    }
    // dragover css
    &.dragover {
      opacity: 1;
      background: ${(props) => props.theme.palette.primary.main};
    }
    // &:hover {
    //   opacity: 1;
    // }
  }
`;

export const Column = ({
  dataField,
  caption,
  columnId = nanoid(),
  sorting = null,
  render = null,
}) => {
  return null;
};

Column.displayName = "Column";

const ColGroup = styled.div`
  display: table-column-group;
`;

ColGroup.displayName = "ColGroup";

const Col = styled.div`
  display: table-column;
  // width: ${({ width }) => (width ? width + "px" : "auto")};
`;

Col.displayName = "Col";

const TableHead = styled.div`
  display: table-header-group;
  width: 100%;
  background-color: ${({ theme }) => theme.mfTable.header.background};
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: bold;
  font-size: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
`;

TableHead.displayName = "TableHead";

const TableBody = styled.div`
  display: table-row-group;
  // background-color: ${({ theme }) => theme.palette.background.paper};
  // background-color: ${({ theme }) => theme.palette.background.paper};
  & .tr:nth-child(even) {
    background-color: ${({ stripes, theme }) =>
      stripes ? theme.palette.background.secondary : "transparent"};
  }
  & .tr:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
  // select last tr element
  & .tr:last-child .td {
    border-bottom: none;
  }
`;

TableBody.displayName = "TableBody";

const TableRow = styled.div`
  display: table-row;
  & .action-button {
    opacity: 0;
    border-radius: 4px;
    // padding: 3px;
  }
  // & .action-button:hover {
  //   background-color: ${({ theme }) => theme.palette.action.hover};
  // }
  * .custom-action-button {
    opacity: 0;
  }
  &:hover .action-button,
  &:hover .custom-action-button {
    opacity: 1;
  }
  &.focused .td {
    border-top: 1px solid ${({ theme }) => theme.palette.primary.main};
    border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main} !important;
    transition: border 0.3s ease;
  }
  &.focused .td:first-child {
    border-left: 1px solid ${({ theme }) => theme.palette.primary.main};
  }
  &.focused .td:last-child {
    border-right: 1px solid ${({ theme }) => theme.palette.primary.main};
  }
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
`;

TableRow.displayName = "TableRow";

const TableHeader = styled.div`
  display: table-cell;
  vertical-align: middle;
  user-select: none;
  position: relative;
  padding: ${({ compact }) => (compact ? "3px" : "8px")};
  font-size: ${({ compact }) => (compact ? "10px" : "12px")};
  text-align: left;
  font-weight: bold;
  height: ${({ compact }) => (compact ? "35px" : "45px")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // min-width: ${({ minWidth }) => minWidth || "100"}px;
  // width: ${({ width }) => width + "px" || "100px"};
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
    cursor: pointer;
  }
  &.dragging:active {
    cursor: grabbing;
  }
  &.dragover .resizer {
    opacity: 1;
    background: ${(props) => props.theme.palette.primary.main};
    cursor: grabbing;
  }
`;

TableHeader.displayName = "TableHeader";

const TableCell = styled.div`
  display: table-cell;
  position: relative;
  height: ${({ compact, rowHeight }) =>
    compact
      ? "25px"
      : rowHeight
      ? typeof rowHeight === String
        ? rowHeight
        : `${rowHeight}px`
      : "36px"};
  padding: ${({ compact }) => (compact ? "3px" : "8px")};
  font-size: ${({ compact }) => (compact ? "10px" : "12px")};
  vertical-align: middle;
  text-align: left;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  border-top: 1px solid transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  & .resizer {
    cursor: context-menu;
  }
`;

TableCell.displayName = "TableCell";

export default Table;
