import styled from "styled-components";

const ItemBox = styled(
  ({ className, children, title, subTitle, startIcon, endIcon }) => {
    return (
      <div className={className}>
        <div className="inner-container">
          <div className="startIcon">{startIcon}</div>
          <div className="info-container">
            <div className="title">{title}</div>
            <div className="subTitle">{subTitle}</div>
          </div>
          <div className="endIcon">{endIcon}</div>
        </div>
        {children && <div className="lower-container">{children}</div>}
      </div>
    );
  }
)`
  padding: 15px;
  outline: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 5px;
  margin-bottom: 10px;
  .inner-container {
    display: flex;
    align-items: center;
    align-content: center;
    width: 100%;
  }
  .lower-container {
    margin-top: 10px;
    margin-left: 33px;
    margin-right: 15px;
  }
  .startIcon {
    margin-right: 5px;
  }
  .startIcon svg {
    font-size: 16px;
    color: ${(props) => props.theme.palette.text.secondary};
  }
  .endIcon {
    margin-left: auto;
    margin-right: 15px;
    user-select: none;
  }
  .endIcon svg {
    font-size: 14px;
    user-select: none;
    color: ${(props) => props.theme.palette.text.secondary};
  }
  .info-container {
    margin-left: 13px;
  }
  .title {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .subTitle {
    color: ${(props) => props.theme.palette.text.secondary};
    font-size: 10px;
  }
  transition: all 0.1s ease-in-out;
  &:hover {
    background-color: ${(props) => props.theme.palette.action.hover};
  }
`;

export default ItemBox;
