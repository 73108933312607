import { Modal } from "@mui/material";
import { Form, SimpleItem } from "devextreme-react/ui/form.js";
import { useRef } from "react";
import { useTheme } from "styled-components";
import ToolBarItems from "../../../components/ToolBarItems.js";
import AddIcon from "@mui/icons-material/Add";
import shortUUID from "short-uuid";

const AddPeopleModal = ({ handleClose, open, onSubmit }) => {
  const theme = useTheme();
  const form = useRef(null);

  const handleSubmit = () => {
    if (!form.current.instance.validate().isValid) return;
    handleClose();
    const uuid = shortUUID.generate();
    if (onSubmit)
      onSubmit({
        ...form.current.instance.option("formData"),
        item_id: uuid,
      });
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        handleClose();
      }}
      disableEnforceFocus={true}
      style={{
        userSelect: "none",
        zIndex: 1400,
      }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 700,
          maxHeight: "90vh",
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 350px)",
          overflowY: "auto",
          padding: 20,
        }}
      >
        <div style={{ marginBottom: 20 }}>
          <div
            style={{
              marginBottom: 20,
              display: "flex",
              flexDirection: "row",
              gap: 5,
              alignItems: "center",
              fontSize: "1.25rem",
              fontWeight: 600,
            }}
          >
            <AddIcon color="primary" /> Add Contact
          </div>
        </div>
        <div
          style={{
            maxHeight: 700,
            overflowY: "auto",
            paddingRight: 10,
            paddingLeft: 10,
          }}
        >
          <Form ref={form} colCount={2}>
            <SimpleItem
              dataField="person_name"
              label={{ text: "Name" }}
              isRequired={true}
              editorOptions={{
                placeholder: "Enter this person's name",
              }}
            />
            <SimpleItem
              dataField="person_type"
              label={{ text: "Type" }}
              isRequired={true}
              editorType="dxSelectBox"
              editorOptions={{
                items: [
                  "Suspect",
                  "Victim",
                  "Witness",
                  "Employee",
                  "Contractor",
                  "Attorney",
                  "Prosecutor",
                  "Other",
                ],
                dropDownOptions: {
                  height: 250,
                },
              }}
            />
            <SimpleItem
              dataField="unique_id"
              label={{ text: "Unqiue Identifier" }}
              editorOptions={{
                placeholder: "Employee ID, DOB, email, etc.",
              }}
            />
            <SimpleItem
              dataField="person_email"
              label={{ text: "Email" }}
              editorOptions={{
                placeholder: "Enter email address",
              }}
            />
            <SimpleItem
              dataField="description"
              editorType="dxTextArea"
              colSpan={2}
              editorOptions={{
                height: 150,
                placeholder:
                  "Provide a brief description of this person and any relevant details.",
              }}
            />
          </Form>
          <ToolBarItems
            submitText="Add Contact"
            onSubmit={handleSubmit}
            onCancel={handleClose}
            style={{ marginTop: 20 }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddPeopleModal;
