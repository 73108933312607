import { Link } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import CaseDrivesAPI from "../../api/CaseDrives/CaseDrives.js";
import BreadCrumbs from "../../components/BreadCrumbs/index.js";
import { Column, DropDownMenu, Table } from "@monolith-forensics/monolith-ui";
import {
  DownloadIcon,
  EyeIcon,
  FolderOpenIcon,
  InfoIcon,
  MoreVerticalIcon,
} from "lucide-react";
import { getIconForFile } from "vscode-icons-js";
import convertBytesToSize from "../../utils/convertBytesToSize.js";
import moment from "moment";

const FolderComponent = ({ data, linkTo }) => {
  return (
    <Link to={linkTo}>
      <div className="data-cell" title={data?.shared_item?.name}>
        <img
          src={`${process.env.PUBLIC_URL}/static/icons/default_folder.svg`}
          alt="icon"
          style={{ height: "1.5rem" }}
        />
        {data?.shared_item?.name}
      </div>
    </Link>
  );
};

const FileComponent = styled(({ className, data, onClick }) => {
  const fileIcon = getIconForFile(data?.shared_item?.name?.toLowerCase());
  return (
    <div
      className={className + " data-cell file"}
      title={data?.shared_item?.name}
      onClick={onClick}
      data-status={data?.shared_item?.status}
    >
      <img
        src={`${process.env.PUBLIC_URL}/static/icons/${fileIcon}`}
        alt="icon"
        style={{ height: "1.5rem" }}
      />
      {data?.shared_item?.name}
    </div>
  );
})`
  &.data-cell.file:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.primary.main};
    cursor: pointer;
  }

  &[data-status="archived"] {
    opacity: 0.5;
  }
`;

const CaseDriveContentTable = styled(
  ({
    className,
    showBreadCrumbs = true,
    onFileOpen,
    onFolderOpen,
    onFileDownload,
    defaultQuery,
    tenantSlug,
  }) => {
    const { case_uuid } = defaultQuery;

    const { data } = useQuery({
      queryKey: ["shared-items", "list", defaultQuery],
      queryFn: ({ queryKey }) => CaseDrivesAPI.getSharedItems(queryKey[2]),
      placeholderData: (data) => data || { data: [] },
      refetchOnWindowFocus: true,
      keepPreviousData: true,
    });

    const handleOpenItem = (item) => {
      if (item?.object_type === "folder") {
        onFolderOpen?.(item);
      } else {
        onFileOpen?.(item);
      }
    };

    const handleDropDownAction = (action, item) => {
      switch (action.value) {
        case "item:open":
          onFolderOpen?.(item);
          break;
        case "item:preview":
          onFileOpen?.(item);
          break;
        case "item:download":
          onFileDownload?.(item);
          break;
        default:
          break;
      }
    };

    const sharedItems = data?.data || [];
    const pathSegments = data?.parent_path_segments;
    const caseInfo = data?.case_info || {};

    const navItems = [
      {
        name: "Case Drives",
        path: `/${tenantSlug}/case-drives`,
      },
      {
        name: caseInfo?.case_number,
        path: `/${tenantSlug}/case-drives/${case_uuid}`,
      },
      ...(pathSegments?.map((segment, index) => {
        return {
          name: segment.name,
          path: `/${tenantSlug}/case-drives/${case_uuid}/folder/${segment.uuid}`,
        };
      }) || []),
    ];

    return (
      <div className={className}>
        {showBreadCrumbs && <BreadCrumbs list={navItems} />}
        <Table
          data={sharedItems}
          keyValue="case_uuid"
          onRowDoubleClick={handleOpenItem}
        >
          <Column
            dataField="custom-action-1"
            width={50}
            render={(rowData) => {
              const sharedItem = rowData?.shared_item;
              const isFolder = rowData?.object_type === "folder";
              return (
                <div
                  className="data-cell"
                  style={{ maxWidth: 60, justifyContent: "center" }}
                >
                  <DropDownMenu
                    data={[
                      {
                        label: "Open",
                        value: "item:open",
                        icon: FolderOpenIcon,
                        visible: isFolder,
                      },
                      {
                        label: "Preview",
                        value: "item:preview",
                        icon: EyeIcon,
                        visible: !isFolder,
                      },
                      {
                        label: "Download",
                        value: "item:download",
                        icon: DownloadIcon,
                        visible: !isFolder,
                      },
                      //   {
                      //     label: "Item Information",
                      //     icon: InfoIcon,
                      //     visible: true,
                      //   },
                    ].filter((item) => item.visible)}
                    buttonProps={{
                      title: "Case Drive Actions",
                      size: "xxs",
                      style: { padding: "0px 4px" },
                      variant: "subtle",
                    }}
                    onItemSelect={(action) =>
                      handleDropDownAction(action, rowData)
                    }
                    dropDownProps={{
                      style: { width: 175, maxWidth: 400 },
                    }}
                  >
                    <MoreVerticalIcon size={14} />
                  </DropDownMenu>
                </div>
              );
            }}
          />
          <Column
            dataField="name"
            caption="Name"
            width={350}
            render={(rowData) => {
              return rowData?.object_type === "folder" ? (
                <FolderComponent
                  data={rowData}
                  linkTo={`/${tenantSlug}/case-drives/${case_uuid}/folder/${rowData.object_id}`}
                />
              ) : (
                <FileComponent
                  data={rowData}
                  onClick={() => handleOpenItem(rowData)}
                />
              );
            }}
          />
          <Column
            dataField="type"
            caption="Type"
            width={75}
            render={(rowData) => (
              <span style={{ textTransform: "capitalize" }}>
                {rowData?.object_type}
              </span>
            )}
          />
          <Column
            dataField="size"
            caption="Size"
            width={75}
            render={(rowData) => convertBytesToSize(rowData?.shared_item?.size)}
          />
          <Column
            dataField="shared_on"
            caption="Shared On"
            width={200}
            render={(rowData) =>
              moment(rowData.shared_on).format("MMMM DD, YYYY hh:mm A")
            }
          />
          <Column
            dataField="shared_by"
            caption="Shared By"
            render={(rowData) => (
              <span title={rowData?.shared_by?.email}>
                {`${rowData?.shared_by?.first_name} ${rowData?.shared_by?.last_name}`}
              </span>
            )}
          />
        </Table>
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;

  cursor: default;
  user-select: none;
  font-size: 12px;

  .data-cell {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.primary.main};
    cursor: pointer;
  }
`;

export default CaseDriveContentTable;
