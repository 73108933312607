import { styled } from "styled-components";

import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import useSuggestion from "./Suggestion.js";
import CustomMention from "./CustomMention.js";

const Container = styled.div`
  border: none;
  background-color: transparent;

  .ProseMirror {
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    // min-height: 65px;

    font-size: 12px;
    font-family: inherit;
    // font-weight: 600;
    color: inherit;
    background-color: transparent;

    &:focus {
      outline: none;
      border: none;
    }

    p {
      margin: 0;
    }

    // All Headers
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }

    /* Placeholder (at the top) */
    p.is-editor-empty:first-child::before {
      color: #888;
      font-weight: normal;
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }

    .mention {
      background-color: ${({ theme }) => theme.palette.background.default};
      margin-right: 0px;
      margin-left: 0px;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;
      color: ${({ theme }) => theme.palette.primary.main};

      &:hover {
        background-color: ${({ theme }) => theme.palette.background.alt};
        color: ${({ theme }) => theme.palette.text.primary};
        text-decoration: underline;
      }
    }
  }
`;

const TiptapViewer = ({
  defaultValue = "",
  placeholder = "",
  mentionItems = [
    { full_name: "Matt Danner", email: "matt@monolithforensics.com" },
  ],
}) => {
  const suggestion = useSuggestion({
    items: mentionItems,
    displayKey: "full_name",
    valueKey: "email",
  });

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: placeholder,
      }),
      CustomMention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        suggestion,
      }),
    ],
    content: defaultValue,
    editable: false,
  });

  return (
    <Container>
      <EditorContent editor={editor} />
    </Container>
  );
};

export default TiptapViewer;
