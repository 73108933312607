import styled from "styled-components";
import { Button, Typography, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import MonolithEvidenceService from "../../../api/Monolith/Evidence/index.js";
import { useAuth } from "../../../contexts/AuthContext.js";
import { useState, useEffect } from "react";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useNewRequest } from "./NewRequestContext.js";
import DropdownMenu from "../../../components/UI/DropdownMenu.js";
import { ContentCopy, Edit } from "@mui/icons-material";
import { prepareCopyData } from "../../../utils/prepareCopyData.js";
import AddRequestEvidence from "../Modals/AddRequestEvidence.js";
import EditRequestEvidence from "../Modals/EditRequestEvidence.js";

const EvidenceItems = styled(({ className, requestID }) => {
  const theme = useTheme();
  const { setFormData } = useNewRequest();
  const [evidenceList, setEvidenceList] = useState([]);

  const { currentUser } = useAuth();
  const { tenantSlug } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const addEvidence = (item) => {
    const newEvidenceList = [...evidenceList, item];
    setEvidenceList(newEvidenceList);
  };

  const editEvidence = (item) => {
    // replace current evidence item with updated item
    setEvidenceList((evidenceList) =>
      evidenceList.map((e) => (e.item_id === item.item_id ? item : e))
    );
  };

  const handleEdit = (item) => {
    setShowEditModal(!showEditModal);
    if (item.custom_fields) {
      item.custom_fields.forEach((field) => {
        item[`custom_field_${field.field_id}`] = field.value;
      });
      setCurrentItem(item);
    }
  };
  const handleCopy = (item) => {
    setShowCopyModal(!showCopyModal);
    const preparedItem = prepareCopyData(item);
    setCurrentItem(preparedItem);
  };

  const handleRemove = (item) => {
    setEvidenceList((evidenceList) =>
      evidenceList.filter((e) => e.item_id !== item.item_id)
    );
  };

  const modelEvidenceList = (evidenceList) => {
    return evidenceList.map((evidence) => {
      Object.keys(evidence).forEach((key) => {
        if (key.startsWith("custom_field_")) {
          delete evidence[key];
        }
      });

      evidence.link = `${window.origin}${
        process.env.API_MODE === "on-premises" ? "/forensic-requests" : ""
      }/requests/${tenantSlug}/${requestID}/evidence/${evidence.item_id}`;

      return evidence;
    });
  };

  const monolithTenant = currentUser.tenants.find(
    (tenant) => tenant.tenant_slug === tenantSlug
  );

  const { isLoading, data } = useQuery({
    queryKey: [
      "evidence:elements",
      {
        monolith_tenant: monolithTenant.monolith_tenant,
        include_types: true,
        include_custom_fields: true,
      },
    ],
    queryFn: () =>
      MonolithEvidenceService.getEvidenceElements({
        monolith_tenant: monolithTenant.monolith_tenant,
        include_types: true,
        include_custom_fields: true,
      }),
    // enabled: !!currentUser,
  });

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      evidence: modelEvidenceList(evidenceList),
    }));
  }, [evidenceList]);

  // if (isLoading) return null;

  return (
    <div className={className}>
      <AddRequestEvidence
        open={showModal}
        handleClose={() => setShowModal(false)}
        onSubmit={addEvidence}
      />

      <div style={{ margin: "auto" }}>
        <Typography variant="h3" gutterBottom display="inline">
          Evidence Items
        </Typography>
        <div style={{ color: theme.palette.text.secondary, marginTop: 5 }}>
          Add evidence items that you will provide for examination.
        </div>
        <div style={{ marginTop: 10, marginBottom: 20 }}>
          <div>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: 10 }}
              size="small"
              onClick={() => setShowModal(true)}
            >
              + Add Evidence
            </Button>
          </div>
        </div>
        <EvidenceList
          evidenceList={evidenceList}
          onRemove={handleRemove}
          onEdit={handleEdit}
          onCopy={handleCopy}
        />
      </div>
      {currentItem && (
        <>
          <EditRequestEvidence
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            onSubmit={editEvidence}
            defaultFormData={currentItem}
          />
          <AddRequestEvidence
            open={showCopyModal}
            handleClose={() => setShowCopyModal(false)}
            onSubmit={addEvidence}
            copyData={currentItem}
          />
        </>
      )}
    </div>
  );
})``;

const EvidenceItem = styled(
  ({ className, evidence, onRemove, onEdit, onCopy }) => {
    const { formData, setFormData } = useNewRequest();
    // const [linkedContact, setLinkedContact] = useState(null);

    const handleSelect = (item) => {
      // setLinkedContact(item);

      setFormData((prevData) => ({
        ...prevData,
        evidence: prevData.evidence.map((evidenceItem) => {
          if (evidenceItem.item_id === evidence.item_id) {
            evidenceItem.linked_contact = item;
          }
          return evidenceItem;
        }),
      }));
    };

    const menuItems =
      formData?.people?.map((person) => ({
        label: person.person_name,
        onClick: () => handleSelect(person),
      })) || [];

    const linkedContact = formData?.evidence?.find(
      (evidenceItem) => evidenceItem.item_id === evidence.item_id
    )?.linked_contact;

    const shouldFlash = !linkedContact && menuItems.length > 0;

    return (
      <div className={className}>
        <div className="left-section">
          <DevicesOutlinedIcon />
        </div>
        <div className="middle-section">
          <div className="title">{evidence.evidence_type}</div>
          <div className="sub-title">
            {evidence.evidence_number ? `#${evidence.evidence_number} | ` : ""}
            {evidence.make}
          </div>
        </div>
        <div className="right-section">
          {menuItems.length > 0 && (
            <div
              className={`linked-contact-select${shouldFlash ? " flash" : ""}`}
            >
              <div className="select-title">Linked Contact: </div>
              <DropdownMenu menuItems={menuItems} arrow>
                {linkedContact?.person_name || "Select..."}
              </DropdownMenu>
            </div>
          )}
          <div className="copy-btn" onClick={() => onCopy(evidence)}>
            <div className="btn-title">Copy Item</div>
            <ContentCopy color="primary" />
          </div>
          <div className="edit-btn" onClick={() => onEdit(evidence)}>
            <div className="btn-title">Edit Item</div>
            <Edit color="primary" />
          </div>
          <div className="remove-btn" onClick={() => onRemove(evidence)}>
            <div className="btn-title">Remove Item</div>
            <ClearOutlinedIcon />
          </div>
        </div>
      </div>
    );
  }
)`
  padding: 15px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 5px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;

  .left-section {
    display: flex;
  }

  .middle-section {
    .title {
      font-size: 12px;
      font-weight: 600;
    }
    .sub-title {
      color: ${(props) => props.theme.palette.text.secondary};
      font-size: 12px;
      font-weight: 600;
    }
  }

  .linked-contact-select {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-left: auto;

    padding: 5px;
    border: 1px solid transparent;
    border-radius: 5px;

    &.flash {
      // create flashing border
      animation: 0.75s ease-in-out 0s infinite alternate none running flash;
    }

    @keyframes flash {
      0% {
        border-color: transparent;
      }
      100% {
        border-color: ${(props) => props.theme.palette.primary.secondary};
      }
    }

    .select-title {
      font-size: 12px;
      font-weight: 600;
      color: ${(props) => props.theme.palette.text.secondary};
    }
  }

  .right-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
    margin-left: auto;
    user-select: none;

    svg {
      font-size: 14px;
    }

    .remove-btn {
      cursor: pointer;
      font-size: 10px;
      font-weight: 600;
      color: orangered;
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;

      &:hover svg,
      &:hover .btn-title {
        color: orangered;
        text-decoration: underline;
      }
    }
    .edit-btn {
      cursor: pointer;
      font-size: 10px;
      font-weight: 600;
      color: ${({ theme }) => theme.palette.text.primary};
      display: flex;
      align-items: center;
      gap: 5px;

      &:hover svg,
      &:hover .btn-title {
        color: ${({ theme }) => theme.palette.primary.main};
        text-decoration: underline;
      }
    }
    .copy-btn {
      cursor: pointer;
      font-size: 10px;
      font-weight: 600;
      color: ${({ theme }) => theme.palette.text.primary};
      display: flex;
      align-items: center;
      gap: 5px;

      &:hover svg,
      &:hover .btn-title {
        color: ${({ theme }) => theme.palette.primary.main};
        text-decoration: underline;
      }
    }
  }

  transition: all 0.1s ease-in-out;
  &:hover {
    border: 1px solid ${(props) => props.theme.palette.primary.main};
  }
`;

const EvidenceList = styled(
  ({ className, evidenceList, onRemove, onEdit, onCopy }) => {
    return (
      <div className={className}>
        {/* <EvidenceItem
        evidence={{
          item_id: "123456",
          evidence_type: "Mobile Device",
          evidence_number: "123456",
          make: "Apple",
        }}
        onRemove={onRemove}
      /> */}
        {evidenceList.map((item, index) => {
          return (
            <EvidenceItem
              key={item.item_id + index}
              evidence={item}
              onRemove={onRemove}
              onEdit={onEdit}
              onCopy={onCopy}
            />
          );
        })}
      </div>
    );
  }
)`
  & .view-btn {
    cursor: pointer;
    font-size: 10px;
    color: ${({ theme }) => theme.palette.text.secondary};
    display: flex;
    align-items: center;
    &:hover,
    &:hover svg,
    &:hover .view-btn-title {
      color: ${({ theme }) => theme.palette.primary.main};
      text-decoration: underline;
    }
  }
  & .view-btn-title {
    margin-right: 5px;
  }
`;

export default EvidenceItems;
