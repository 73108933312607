import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../../contexts/AuthContext.js";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import CaseDrivesAPI from "../../api/CaseDrives/CaseDrives.js";
import LoggingAPI from "../../api/logging/index.js";
import { Helmet } from "react-helmet-async";
import CaseDriveContentTable from "./CaseDriveContentTable.jsx";
import FileViewerComponent from "./FileViewerComponent.jsx";

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const CaseDriveContentsView = styled(({ className }) => {
  const { tenantSlug, case_uuid, parent_object_id } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [previewedFile, setPreviewedFile] = useState(null);

  const monolith_tenant = currentUser.tenants.find(
    (tenant) => tenant.tenant_slug === tenantSlug
  )?.monolith_tenant;

  const { data } = useQuery({
    queryKey: [
      "case-drives",
      "list",
      {
        case_uuid,
        monolith_tenant: currentUser.tenants.find(
          (tenant) => tenant.tenant_slug === tenantSlug
        )?.monolith_tenant,
      },
    ],
    queryFn: ({ queryKey }) => CaseDrivesAPI.getCaseDrives(queryKey[2]),
    placeholderData: (data) => data || { data: [] },
    refetchOnWindowFocus: true,
  });

  const handleFileOpen = (item) => {
    LoggingAPI.createLog({
      case_uuid: item.case_uuid,
      message: `Viewed file "${item.shared_item.name}"`,
      event: "view",
      object_type: "file",
      object_uuid: item.object_id,
      monolith_tenant,
    });
    setPreviewedFile(item);
    setShowFilePreview(true);
  };

  const handleFolderOpen = (item) => {
    navigate(
      `/${tenantSlug}/case-drives/${case_uuid}/folder/${item.object_id}`
    );
  };

  const handleCloseFilePreview = () => {
    setShowFilePreview(false);
    setPreviewedFile(null);
  };

  const handleFileDownload = (item) => {
    const fileInfo = {
      uuid: item.object_id,
      monolith_tenant,
    };

    CaseDrivesAPI.downloadFile({
      // key: b64,
      ...fileInfo,
    }).then((res) => {
      if (!res || res.success === false) {
        return;
      }
      const el = document.createElement("a");
      el.href = res.link.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );
      el.download = res.name;
      el.click();

      LoggingAPI.createLog({
        case_uuid: item.case_uuid,
        message: `Downloaded file "${item.shared_item.name}"`,
        event: "download",
        object_type: "file",
        object_uuid: item.object_id,
        monolith_tenant,
      });
    });
  };

  const caseDrive = data?.data?.[0] || {};

  useEffect(() => {
    if (parent_object_id) {
      LoggingAPI.createLog({
        case_uuid,
        message: `Accessed folder`,
        event: "folder:access",
        object_type: "folder",
        object_uuid: parent_object_id,
        monolith_tenant,
      });
    }
  }, [parent_object_id, case_uuid, monolith_tenant]);

  return (
    <div className={className}>
      <Helmet title={`Case Drive - ${caseDrive?.case_number}`} />
      <Title className="title">
        {caseDrive?.case_number} - Case Drive Files
      </Title>
      <CaseDriveContentTable
        tenantSlug={tenantSlug}
        defaultQuery={{
          email: currentUser.email,
          direct_share: true,
          parent_object_id,
          case_uuid,
          sort: null,
          monolith_tenant,
        }}
        onFileOpen={handleFileOpen}
        onFolderOpen={handleFolderOpen}
        onFileDownload={handleFileDownload}
      />
      <FileViewerComponent
        uuid={previewedFile?.object_id}
        open={showFilePreview}
        handleClose={handleCloseFilePreview}
        handleFileDownload={() => handleFileDownload(previewedFile)}
      />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
`;

export default CaseDriveContentsView;
