import styled from "styled-components";

const TaskButton = styled(
  ({ className, children, style = {}, onClick = () => {}, title = "" }) => {
    const _onClick = (e) => {
      // e.stopPropagation();
      onClick(e);
    };

    return (
      <div
        className={className}
        onClick={_onClick}
        style={{ ...style }}
        title={title}
      >
        {children}
      </div>
    );
  }
)`
  cursor: pointer;
  display: flex;
  alignitems: center;
  margin-right: 3px;
  border-radius: 4px;
  width: fit-content;
  padding: 2px;
  color: ${(props) => props.theme.palette.text.secondary};
  &:hover {
    background-color: ${(props) => props.theme.palette.action.hover};
  }
`;

export default TaskButton;
