import { httpGet, httpPost } from "../connection";
import SettingsService from "../settings";

const getOrgInfo = async (params) => httpGet(`/organizations`, params);

const getAgencies = async (params) => httpGet(`/agencies`, params);

const getOrgDetails = async (tenantUuid) =>
  httpGet(`/org-details/${tenantUuid}`);

const updateOrgDetails = async (data) => {
  return await httpPost(`/org-details`, data);
};

const TenantService = {
  getOrgInfo,
  getAgencies,
  getHowTo: SettingsService.getHowTo,
  getOrgDetails,
  updateOrgDetails,
  getEvidenceTypes: SettingsService.getEvidenceTypes,
  getCaseTypes: SettingsService.getCaseTypes,
};

export default TenantService;
