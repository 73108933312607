import { httpGet, httpPost } from "../../connection.js";

const getCases = async (query = {}) => await httpPost(`/monolith/cases`, query);

const getCaseElements = async (query = {}) => {
  return await httpGet(`/monolith/cases/elements`, query);
};

const MonolithCaseService = {
  getCases,
  getCaseElements,
};

export default MonolithCaseService;
