import { useQuery } from "@tanstack/react-query";
import TenantService from "../../../api/tenants";

export const defaultHTML = {
  html: `<h3>Please use these guidelines when submitting your request:</h3>
  <p></p>
  <ol class="">
    <li class="">
      <p>
        Enter a name for your Relay request - this will help identify your
        request later.
      </p>
    </li>
    <li class="">
      <p>
        Enter as much detail as you can including your case number and
        description of your request.
      </p>
    </li>
    <li class=""><p>Attach any documents relevant to this request.</p></li>
    <li class="">
      <p>
        Add any evidence items that you want the forensics team to work on.
      </p>
    </li>
    <li class="">
      <p>
        Add any people associated with this request which may include
        subjects, witnesses, custodians, or victims.
      </p>
    </li>
    <li class="">
      <p>Submit your request once you have completed the form.</p>
    </li>
  </ol>
  <p></p>
  <p>
    Once your request is submitted your can start tracking its progress and
    using the comment system to communicate with the forensics team.
  </p>
  <p></p>
  <p>Thanks!</p>`,
};

const HowToPresenter = ({ orgInfo }) => {
  const { isLoading, data: text } = useQuery({
    queryKey: ["howTo", orgInfo.uuid],
    queryFn: () => TenantService.getHowTo(orgInfo.uuid),
    select: (res) => {
      const howTo = res?.length ? res[0] : defaultHTML;
      if (typeof howTo?.html === "string" && howTo?.html.length >= 0) {
        return howTo?.html;
      }
    },
  });

  if (!text) return null;

  return (
    <div
      style={{
        // border: "1px solid",
        marginTop: "16px",
        padding: "20px",
        textAlign: "start",
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default HowToPresenter;
