import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { AuthService } from "../../api/auth/index.js";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { Button, CssBaseline, useTheme } from "@mui/material";
import logo from "../../monolith_logo.png"; // Tell Webpack this JS file uses this image
import GlobalStyle from "../../components/GlobalStyle.js";
import TaskButton from "../../components/UI/TaskButton.js";
import { useQuery } from "@tanstack/react-query";

const ResetPassword = ({}) => {
  const theme = useTheme();
  const { uuid } = useParams();
  const form = useRef(null);
  const navigate = useNavigate();

  const handleReset = () => {
    if (form.current.instance.validate().isValid) {
      const { password } = { ...form.current.instance.option().formData };
      AuthService.updatePassword({ password, uuid });
      navigate(`/login`);
    }
  };

  const { isLoading, data: isExpired } = useQuery({
    queryKey: ["checkPasswordReset", uuid],
    queryFn: () => AuthService.checkPasswordReset({ uuid }),
    select: (data) => data.expired,
  });

  if (isLoading) return <></>;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "calc(100vh - 200px)",
        alignItems: "center",
      }}
    >
      <CssBaseline />
      <GlobalStyle />
      <div
        style={{
          width: 500,
          height: "auto",
          padding: 20,
          borderRadius: 5,
          backgroundColor: "transparent",
          boxShadow: "none !important",
        }}
      >
        {isExpired && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px 0px",
              }}
            >
              This password reset request has expired.
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px 0px",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TaskButton
                style={{ marginTop: 10, marginBottom: 5, padding: 8 }}
                onClick={() => navigate("/login")}
              >
                Go to Relay Login
              </TaskButton>
            </div>
          </>
        )}
        {!isExpired && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 25,
              }}
            >
              <img src={logo} height="85" alt="Monolith Forensics Logo"></img>
            </div>
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 25,
                  color: theme.palette.text.secondary,
                  fontSize: "large",
                }}
              >
                <div>Update Your Password</div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0px",
                }}
              >
                <Form colCount={1} ref={form} width="100%">
                  <SimpleItem
                    dataField="password"
                    label={{ text: "Password", visible: false }}
                    editorOptions={{
                      mode: "password",
                      maxLength: 100,
                      placeholder: "New Password",
                    }}
                    validationRules={[
                      {
                        type: "required",
                      },
                      {
                        type: "stringLength",
                        message: "Password must be at least 8 characters",
                        min: 8,
                        max: 100,
                      },
                      {
                        type: "pattern",
                        pattern:
                          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+_\\-\\.@#$%^&*])",
                        message:
                          "Must contain upper, lower, special, & numeric.",
                      },
                    ]}
                  />
                </Form>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0px",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  fullWidth
                  style={{ marginTop: 5 }}
                  onClick={handleReset}
                >
                  Reset Password
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0px",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <TaskButton
                  style={{ marginTop: 10, marginBottom: 5, padding: 8 }}
                  onClick={() => navigate("/login")}
                >
                  Go to Relay Login
                </TaskButton>
              </div>
            </>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
