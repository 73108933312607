import styled from "styled-components";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { useNavigate, useParams } from "react-router-dom";
import ItemBox from "../../../../components/ItemBox.js";

const ChildEvidenceList = styled(({ className, evidenceInfo }) => {
  const { requestID, tenantSlug, evidenceUUID } = useParams();
  const navigate = useNavigate();

  const handleClick = (uuid) => {
    navigate(`/requests/${tenantSlug}/${requestID}/evidence/${uuid}`);
  };

  if (!evidenceInfo.child_list || evidenceInfo.child_list.length === 0)
    return (
      <div style={{ marginTop: 10, marginBottom: 20 }}>No child evidence</div>
    );

  return (
    <div className={className} style={{ marginTop: 10, marginBottom: 20 }}>
      {evidenceInfo.child_list.map((item, index) => {
        return (
          <ItemBox
            key={index}
            title={item.item_type}
            subTitle={
              <>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 5 }}>{item.manufacturer}</div>
                  <>
                    <span>|</span>
                    <div style={{ marginLeft: 5 }}>{item.progress}</div>
                  </>
                </div>
              </>
            }
            startIcon={<DevicesOutlinedIcon />}
            endIcon={
              <div className="view-btn" onClick={() => handleClick(item.uuid)}>
                <div className="view-btn-title">View Details</div>
                <ArrowForwardOutlinedIcon />
              </div>
            }
          >
            {/* {evidenceItems?.progress && (
                    <ProgressBar
                      progress={item.progressPercent}
                      progressItems={evidenceItems.progress}
                    />
                  )} */}
          </ItemBox>
        );
      })}
    </div>
  );
})`
  & .view-btn {
    cursor: pointer;
    font-size: 10px;
    color: ${({ theme }) => theme.palette.text.secondary};
    display: flex;
    align-items: center;
    &:hover,
    &:hover svg,
    &:hover .view-btn-title {
      color: ${({ theme }) => theme.palette.primary.main};
      text-decoration: underline;
    }
  }
  & .view-btn-title {
    margin-right: 5px;
  }
`;

export default ChildEvidenceList;
