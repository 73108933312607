import { Drawer as MuiDrawer, Switch, useTheme } from "@mui/material";

import { THEMES } from "../../constants";
import useSiteTheme from "../../hooks/useTheme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import styled from "styled-components";
import SidebarNav from "./SidebarNav";
import SidebarFooter from "./SidebarFooter";
import UpdatesModal from "../UpdatesModal/UpdatesModal.js";
import { useState } from "react";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled.div`
  padding: 15px;
  background-color: ${(props) => props.theme.sidebar.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);

  .relay {
    font-size: 1.5rem;
    font-weight: 600;
    color: #eee;
  }

  .by-monolith-forensics {
    font-size: 0.8rem;
    font-weight: 400;
    color: #bbb;
  }
`;

const LatestUpdates = styled.div`
  background-color: ${(props) => props.theme.sidebar.background};
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: 600;
  user-select: none;

  text-align: center;
  padding-bottom: 10px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Sidebar = ({ items, showFooter = true, ...rest }) => {
  const { settingsState, setSettingsState } = rest;
  const [showUpdatesModal, setShowUpdatesModal] = useState(false);
  const themeColors = useTheme();
  const { theme, setTheme } = useSiteTheme();

  return (
    <Drawer variant="permanent" {...rest}>
      <Brand>
        <div className="relay">Relay</div>
        <div className="by-monolith-forensics">By Monolith Forensics</div>
      </Brand>
      <SidebarNav items={items}></SidebarNav>
      <LatestUpdates onClick={() => setShowUpdatesModal(true)}>
        Latest Updates
      </LatestUpdates>
      <UpdatesModal
        open={showUpdatesModal}
        handleClose={() => setShowUpdatesModal(false)}
        showCheckbox={false}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: themeColors.sidebar.background,
          color: "#888",
          padding: 10,
          fontWeight: 600,
          paddingBottom: 20,
        }}
      >
        <div>Need Help or Training?</div>
        <div>Contact us at</div>
        <div>
          <a href="mailto:support@monolithforensics.com?subject=[Relay Support]">
            support@monolithforensics.com
          </a>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: themeColors.sidebar.background,
          color: "white",
          padding: 10,
        }}
      >
        <div>
          <LightModeOutlinedIcon fontSize="small" />
        </div>
        <Switch
          sx={{ mx: 2 }}
          size="small"
          defaultChecked={theme === "DARK"}
          onChange={(event) => {
            event.target.checked
              ? setTheme(THEMES.DARK)
              : setTheme(THEMES.DEFAULT);
          }}
        />
        <div>
          <DarkModeOutlinedIcon fontSize="small" />
        </div>
      </div>
      {!!showFooter && (
        <SidebarFooter
          settingsState={settingsState}
          setSettingsState={setSettingsState}
        />
      )}
    </Drawer>
  );
};

export default Sidebar;
