import { httpGet } from "../connection.js";

async function getSharedItems(query = {}) {
  return httpGet("/case-drives/shared-items", query);
}

async function getCaseDrives(query = {}) {
  return httpGet("/case-drives", query);
}

const downloadFile = async (values = {}) => {
  return httpGet(`/case-drives/shared-items/download-file`, values);
};

const CaseDrivesAPI = {
  getSharedItems,
  downloadFile,
  getCaseDrives,
};

export default CaseDrivesAPI;
