import { styled } from "styled-components";

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const Title1 = styled.h1`
  margin: 0;
`;

const Title2 = styled.h2`
  margin: 0;
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

PageHeader.Title1 = Title1;
PageHeader.Title2 = Title2;
PageHeader.SubTitle = SubTitle;

export default PageHeader;
