import merge from "deepmerge";
import { green, grey, indigo, red } from "@mui/material/colors";
import { THEMES } from "../constants";

const customBlue = {
  50: "#e9f0fb",
  100: "#c8daf4",
  200: "#a3c1ed",
  300: "#7ea8e5",
  400: "#6395e0",
  500: "#4782da",
  600: "#407ad6",
  700: "#376fd0",
  800: "#2f65cb",
  900: "#2052c2 ",
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: customBlue[700],
      contrastText: "#FFF",
    },
    secondary: {
      main: "#FFF",
      contrastText: "#000",
    },
    third: {
      main: "#000",
      contrastText: "#FFF",
    },
    fourth: {
      main: "#ff4500",
      contrastText: "#FFF",
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFF",
      alt: "#fbfbfb",
      secondary: "#f0f0f0",
      gradient: "linear-gradient(#ccdcf9 0%, #FFFFFF 50%)",
    },
    menu: {
      background: "#FFF",
    },
    signature: {
      penColor: "#000000",
      borderColor: "#444444",
      backgroundColor: "",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.95)",
      secondary: "rgba(0, 0, 0, 0.75)",
    },
    input: {
      background: "#f1f1f4",
      border: "rgba(0, 0, 0, 0.2)",
      borderHover: "#BDBDBD",
      borderFocus: "#BDBDBD",
      borderError: "#F44336",
      borderDisabled: "#E0E0E0",
      text: "#000",
      textDisabled: "#9E9E9E",
      placeholder: "#9E9E9E",
    },
    textArea: {
      background: "#f1f1f4",
      border: "rgba(0, 0, 0, 0.2)",
      borderHover: "#BDBDBD",
      borderFocus: "#BDBDBD",
      borderError: "#F44336",
      borderDisabled: "#E0E0E0",
      text: "#000",
      textDisabled: "#9E9E9E",
      placeholder: "#9E9E9E",
    },
    divider: "rgba(0, 0, 0, 0.2)",
  },
  mfTable: {
    header: { background: "#f1f1f1" },
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: grey[200],
    background: "#212121",
    active: "#455466",
    header: {
      color: grey[200],
      background: "#212121",
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: grey[200],
      background: "#333",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: customBlue[500],
    },
  },
  scrollbar: {
    track: "#f1f1f1",
    thumb: "#DDD",
    thumbHover: "#BBB",
  },
  mfBorder: {
    primary: "#f1f1f1",
    secondary: "#DDD",
  },
  zIndex: {
    snackbar: 999999,
  },
};

const darkVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    mode: "dark",
    primary: {
      main: customBlue[500],
      contrastText: "#FFF",
    },
    secondary: {
      main: "#3f3f3f",
      contrastText: "#FFF",
    },
    background: {
      default: "#222222",
      paper: "#333333",
      alt: "#1e1e1e",
      secondary: "#303030",
      gradient: "linear-gradient(#333333 0%, #1e1e1e 50%)",
    },
    menu: {
      background: "#222222",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.6)",
    },
    input: {
      background: "#292929",
      border: "rgba(255, 255, 255, 0.2)",
      borderHover: "#BDBDBD",
      borderFocus: "#BDBDBD",
      borderError: "#F44336",
      borderDisabled: "#E0E0E0",
      text: "#000",
      textDisabled: "#9E9E9E",
      placeholder: "#9E9E9E",
    },
    textArea: {
      background: "#292929",
      border: "rgba(255, 255, 255, 0.2)",
      borderHover: "#BDBDBD",
      borderFocus: "#BDBDBD",
      borderError: "#F44336",
      borderDisabled: "#E0E0E0",
      text: "#000",
      textDisabled: "#9E9E9E",
      placeholder: "#9E9E9E",
    },
    signature: {
      penColor: "#eeeeee",
      borderColor: "#999999",
      backgroundColor: "rgba(60,60,60,1)",
    },
    divider: "rgba(255, 255, 255, 0.15)",
  },
  mfTable: {
    header: {
      background: "#1e1e1e",
    },
  },
  header: {
    color: grey[300],
    background: "#1B2635",
    search: {
      color: grey[200],
    },
  },
  footer: {
    color: grey[300],
    background: "#222222",
  },
  sidebar: {
    color: "rgba(255, 255, 255, 0.95)",
    background: "#181818",
    active: "#313131",
    header: {
      color: "#FFF",
      background: "#181818",
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "rgba(255, 255, 255, 0.95)",
      background: "#333333",
      online: {
        background: green[500],
      },
    },
  },
  scrollbar: {
    thumb: "#3f3f3f",
    track: "#252525",
    thumbHover: "#151515",
  },
  mfBorder: {
    primary: "#3f3f3f",
    secondary: "#252525",
  },
});

const variants = [defaultVariant, darkVariant];

export default variants;
