import { HelmetProvider, Helmet } from "react-helmet-async";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { ThemeProvider } from "styled-components";
import { MonolithUIProvider } from "@monolith-forensics/monolith-ui";
import useTheme from "./hooks/useTheme";
import { useRoutes } from "react-router-dom";
import createTheme from "./theme";
import routes from "./routes";
import themes from "devextreme/ui/themes";
import { DevExtremeMap } from "./constants";
import { useEffect } from "react";

function App() {
  const content = useRoutes(routes);
  const { theme } = useTheme();

  useEffect(() => {
    themes.current(DevExtremeMap[theme]);
  }, [theme]);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | Relay"
        defaultTitle="Relay - Digital Forensics Request Platform"
      />
      <MuiThemeProvider theme={createTheme(theme)}>
        <ThemeProvider theme={createTheme(theme)}>
          <MonolithUIProvider colorScheme={theme}>
            <>{content}</>
          </MonolithUIProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </HelmetProvider>
  );
}

export default App;
