import styled from "styled-components";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useState } from "react";
import { useEffect } from "react";

const CheckBox = styled(
  ({
    className,
    checked = true,
    partialCheck = false,
    onChange = () => {},
    style = {},
  }) => {
    const [checkedState, setCheckedState] = useState(checked);

    const handleChange = (e) => {
      e.stopPropagation();
      setCheckedState(!checkedState);
      onChange({ newValue: !checkedState, previousValue: checkedState });
    };

    useEffect(() => {
      setCheckedState(checked);
    }, [checked]);

    return (
      <div className={className} onClick={handleChange}>
        {partialCheck === true ? (
          <IndeterminateCheckBoxIcon className="checkbox partial" />
        ) : checkedState === true ? (
          <CheckBoxIcon className="checkbox checked" />
        ) : (
          <CheckBoxOutlineBlankOutlinedIcon className="checkbox unchecked" />
        )}
      </div>
    );
  }
)((props) => ({
  display: "flex",
  alignItems: "center",
  width: 20,
  cursor: "pointer",
  ".checkbox": {
    color: props.theme.palette.primary.main,
    ...props.style,
    fontSize: (() => {
      if (props.size === "small") return 16;
      if (props.size === "medium") return 20;
      if (props.size === "large") return 24;
      return 16;
    })(),
    // padding: 5,
  },
}));

export default CheckBox;
