import styled from "styled-components";
import { Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import TenantService from "../../../api/tenants/index.js";
import { useAuth } from "../../../contexts/AuthContext.js";
import OrgHeader from "../components/OrgHeader.js";

const RequestSubmitted = styled(({ className }) => {
  const { currentUser } = useAuth();
  const { tenantSlug } = useParams();
  // get search params
  const searchParams = new URLSearchParams(window.location.search);
  const requestID = searchParams.get("requestID");

  const navigate = useNavigate();

  const { isLoading, data } = useQuery({
    queryKey: ["orgInfo", tenantSlug],
    queryFn: () => TenantService.getOrgInfo({ slug: tenantSlug }),
  });

  const [orgInfo] = data || [];

  const handleViewRequest = () => {
    navigate(`/requests/${tenantSlug}/${requestID}`);
  };

  const handleSubmitAnotherRequest = () => {
    navigate(`/${tenantSlug}/new-request`);
  };

  if (!orgInfo) return null;

  return (
    <div className={className}>
      <div className="content">
        {orgInfo && (
          <OrgHeader
            orgInfo={orgInfo}
            showMessage={false}
            showOrgName={false}
          />
        )}
        <h1>Thanks for your request!</h1>
        <div className="message">
          Someone from our team will review your request and reach out with next
          steps.
        </div>
        <div className="action-btns">
          <div className="view-request-btn">
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleViewRequest}
            >
              View Request
            </Button>
          </div>
        </div>
        <div className="new-request-box">
          <div>Want to make another Request?</div>
          <Button
            variant="text"
            color="primary"
            size="small"
            onClick={handleSubmitAnotherRequest}
            style={{ marginTop: 10 }}
          >
            New Request
          </Button>
        </div>
      </div>
    </div>
  );
})`
  position: relative;
  padding-top: 100px;
  .content {
    // light box shadow
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    margin: auto auto;
    border-radius: 5px;
    width: 500px;
    height: auto;
    padding: 20px;
    padding-bottom: 40px;
    text-align: center;
    background-color: ${({ theme }) => theme.palette.background.alt};
    .message {
      color: ${({ theme }) => theme.palette.text.secondary};
      font-weight: 400;
      line-height: 1.5;
      font-size: 1rem;
    }
    .action-btns {
      margin-top: 40px;
    }
    .view-request-btn {
      margin-bottom: 20px;
    }
    .new-request-box {
      margin-top: 40px;
      padding: 20px 10px;
      border-radius: 5px;
      font-weight: 400;
      line-height: 1.5;
      //   font-size: 1rem;
      color: ${({ theme }) => theme.palette.text.primary};
      background-color: ${({ theme }) => theme.palette.background.default};
    }
  }
`;

export default RequestSubmitted;
