import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const testCrumbs = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Requests",
    path: "/requests",
  },
  {
    name: "Request 1",
    path: "/requests/1",
  },
  {
    name: "Request 2",
    path: "/requests/2",
  },
];

const BreadCrumbsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  //   margin: 10px;
  //   outline: 1px solid ${({ theme }) => theme.palette.divider};
`;

const BreadCrumbItem = styled.div`
  cursor: pointer;
  padding: 4px 16px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  border-radius: 6px;
  margin-bottom: 5px;
  clip-path: polygon(
    0 0,
    calc(100% - 8px) 0,
    100% 50%,
    calc(100% - 8px) 100%,
    0 100%,
    8px 50%
  );
  margin-right: -5px;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: #36a2ef33;
  &:last-child {
    border-radius: 0 6px 6px 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 8px 50%);
    background-color: ${({ theme }) => theme.palette.divider};
    color: ${({ theme }) => theme.palette.text.primary};
    cursor: default;
    &:hover {
      text-decoration: none;
    }
  }
  &:first-child {
    clip-path: polygon(
      0 0,
      calc(100% - 8px) 0,
      100% 50%,
      calc(100% - 8px) 100%,
      0 100%
    );
  }
  &:only-child {
    border-radius: 6px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  &:hover {
    text-decoration: underline;
  }
`;

const BreadCrumbs = styled(({ className, list, onItemSelect }) => {
  const navigate = useNavigate();

  const handleClick = (item) => {
    if (onItemSelect) {
      onItemSelect(item);
    }
    navigate(item.path);
  };

  return (
    <BreadCrumbsContainer className={className}>
      {list.map((item) => (
        <BreadCrumbItem
          className="bread-crumb-item"
          onClick={() => handleClick(item)}
        >
          {item.name}
        </BreadCrumbItem>
      ))}
    </BreadCrumbsContainer>
  );
})``;

export default BreadCrumbs;
