import styled from "styled-components";

const ProgressBar = styled(
  ({
    className,
    progressItems,
    defaultValue,
    onSelect = () => {},
    enabled,
  }) => {
    const index = progressItems.findIndex(
      (item) => item.progress_name === defaultValue
    );

    const percentage = (
      (parseInt(index + 1) / parseInt(progressItems.length)) *
      100
    ).toFixed(0);

    const handleBarClick = (e) => {
      const bar = e.target.closest(".progress-bar");
      const barWidth = bar.offsetWidth;
      const barLeft = bar.getBoundingClientRect().left;
      const clickPosition = e.clientX - barLeft;
      const percentage = (clickPosition / barWidth) * 100;
      const itemIndex =
        Math.min(
          Math.ceil((percentage / 100) * progressItems.length),
          progressItems.length
        ) - 1;

      onSelect(progressItems[itemIndex]);
    };

    return (
      <div className={className}>
        <div className="progress-bar" onClick={handleBarClick}>
          <div
            className="progress-bar-fill"
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
        <div className="progress-legend">
          {progressItems.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  "progress-legend-item" +
                  (defaultValue
                    ? defaultValue === item.progress_name
                      ? " active"
                      : ""
                    : index === 0 && " active")
                }
                style={{ width: `${100 / progressItems.length}%` }}
                onClick={() => enabled && onSelect(item)}
                title={item.progress_name}
              >
                {item.progress_name}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
)`
  border-radius: 5px;
  min-width: ${({ progressItems }) => progressItems.length * 75}px;
  & .progress-bar {
    height: 5px;
    width: 100%;
    background-color: ${({ theme }) => theme.palette.divider};
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
  }
  & .progress-bar-fill {
    height: 100%;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    transition: width 0.2s ease;
  }
  & .progress-legend {
    display: flex;
    margin-top: 5px;
    font-size: 10px;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
  & .progress-legend-item {
    height: 18px;
    padding: 0 5px;
    border-left: 1px solid ${({ theme }) => theme.palette.divider};
    overflow: hidden;
    cursor: pointer;
    transition: font-size 0.1s ease, font-weight 0.1s ease;
    min-width: 75px;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      font-weight: bold;
      font-size: 12px;
    }
  }
  & .progress-legend-item.active {
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: bold;
    font-size: 12px;
  }
`;

export default ProgressBar;
