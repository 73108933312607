import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { Hidden, CssBaseline, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import GlobalStyle from "../components/GlobalStyle";
import dashboardItems from "../components/Sidebar/dashboardItems";
import Sidebar from "../components/Sidebar/Sidebar";
import Footer from "../components/Footer";
import { useAuth } from "../contexts/AuthContext";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import UpdatesModal, {
  UpdateSerialNumber,
} from "../components/UpdatesModal/UpdatesModal.js";
const drawerWidth = 258;

const Root = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
      }}
    >
      {children}
    </div>
  );
};

const Drawer = ({ children }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        width: theme.breakpoints.up("md") ? `${drawerWidth}px` : "0px",
        flexShrink: theme.breakpoints.up("md") ? "0" : "unset",
      }}
    >
      {children}
    </div>
  );
};

const AppContent = ({ children }) => {
  const theme = useTheme();

  let maxWidth = null;
  if (theme.breakpoints.up("md")) maxWidth = `calc(100% - ${drawerWidth}px)`;
  if (theme.breakpoints.down("lg")) maxWidth = `100%`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        maxWidth,
      }}
    >
      {children}
    </div>
  );
};

const MainContent = ({ children }) => {
  const theme = useTheme();

  return (
    <Paper
      style={{
        padding: 25,
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        height: 0,
        background: theme.palette.background.default,
        boxShadow: "none",
      }}
    >
      {children}
    </Paper>
  );
};

const DashboardLayout = ({ children }) => {
  const { currentUser } = useAuth();
  const [showUpdatesModal, setShowUpdatesModal] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  dashboardItems[1].pages = [];

  // Populate the sidebar with the tenants the user has access to
  currentUser.tenants
    .filter((tenant) => tenant.active === 1)
    .forEach((tenant) => {
      const links = [];

      // Don't show unless admin user
      if (tenant.is_admin === 1) {
        links.push({
          title: "All Requests",
          href: `/${tenant.tenant_slug}/all-requests`,
        });
      }

      links.push(
        {
          title: "Case Drives",
          href: `/${tenant.tenant_slug}/case-drives`,
        },
        {
          title: "Submit a Request",
          href: `/${tenant.tenant_slug}`,
        }
      );

      dashboardItems[1].pages.push({
        title: tenant.tenant_org,
        icon: ImportantDevicesIcon,
        children: links,
      });
    });

  useEffect(() => {
    const showUpdates = localStorage.getItem("showUpdates");
    if (!showUpdates) {
      setShowUpdatesModal(true);
    } else {
      const parsed = JSON.parse(showUpdates);
      if (
        parsed.UpdateSerialNumber === UpdateSerialNumber &&
        parsed.showUpdates
      ) {
        setShowUpdatesModal(true);
      } else if (parsed.UpdateSerialNumber !== UpdateSerialNumber) {
        setShowUpdatesModal(true);
      }
    }
  }, []);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Hidden lgUp implementation="js">
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={dashboardItems}
          />
        </Hidden>
        <Hidden mdDown implementation="css">
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={dashboardItems}
          />
        </Hidden>
      </Drawer>
      <AppContent>
        {/* <Navbar onDrawerToggle={handleDrawerToggle} /> */}
        <UpdatesModal
          open={showUpdatesModal}
          handleClose={() => setShowUpdatesModal(false)}
        />
        <MainContent p={isLgUp ? 12 : 5}>
          {children}
          <Outlet />
        </MainContent>
        {/* <Footer /> */}
      </AppContent>
    </Root>
  );
};

export default DashboardLayout;
