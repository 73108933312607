import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import styled from "styled-components";

import { ChevronRightIcon } from "lucide-react";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";

const StyledButton = styled.button`
  font-size: 0.75rem;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 3px;
  border-radius: 4px;
  width: fit-content;
  min-width: max-content;
  padding: 3px 5px;
  color: ${(props) => props.theme.palette.text.primary};
  transition: all 0.15s ease-in-out;
  border: 1px solid
    ${(props) => {
      if (props.variant === "outlined") return props.theme.palette.divider;
      if (props.variant === "text") return "transparent";
      if (props.variant === "contained") return "transparent";
      return "transparent";
    }};
  background-color: ${(props) => {
    if (props.variant === "contained")
      return props.theme.palette.background.secondary;
    if (props.variant === "outlined") return "transparent";
    if (props.variant === "text") return "transparent";
    return "transparent";
  }};
  ${(props) =>
    props.enabled === undefined || props.enabled
      ? `&:hover {
    border: 1px solid ${props.theme.palette.primary.main};
  }`
      : ""}
  outline: none;
`;

const StyledMenuContent = styled(RadixDropdownMenu.Content)`
  min-width: 100px;
  background-color: ${(props) => props.theme.palette.background.default};
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  overflow: hidden;
  padding: 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.85),
    0px 10px 20px -15px rgba(22, 23, 24, 0.65);

  z-index: 100000;

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-side="top"] {
    animation-name: slideDownAndFade;
  }
  &[data-side="right"] {
    animation-name: slideLeftAndFade;
  }
  &[data-side="bottom"] {
    animation-name: slideUpAndFade;
  }
  &[data-side="left"] {
    animation-name: slideRightAndFade;
  }

  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const StyledSubMenuContent = styled(RadixDropdownMenu.SubContent)`
  min-width: 175px;
  max-height: 300px;
  overflow-y: auto;
  background-color: ${(props) => props.theme.palette.background.default};
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  padding: 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  z-index: 100000;

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-side="top"] {
    animation-name: slideDownAndFade;
  }
  &[data-side="right"] {
    animation-name: slideLeftAndFade;
  }
  &[data-side="bottom"] {
    animation-name: slideUpAndFade;
  }
  &[data-side="left"] {
    animation-name: slideRightAndFade;
  }

  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const StyledMenuItem = styled(RadixDropdownMenu.Item)`
  font-size: 12px;
  line-height: 1;
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 3px;
  display: flex;
  align-items: center;
  min-height: 25px;
  padding: 0 5px;
  position: relative;
  user-select: none;
  outline: none;

  &[data-disabled] {
    color: ${(props) => props.theme.palette.text.secondary};
    pointer-events: "none";
  }

  &[data-highlighted] {
    background-color: ${(props) => props.theme.palette.action.hover};
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const StyledSubTrigger = styled(RadixDropdownMenu.SubTrigger)`
  font-size: 12px;
  line-height: 1;
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 3px;
  display: flex;
  align-items: center;
  min-height: 25px;
  padding: 0 5px;
  position: relative;
  user-select: none;
  outline: none;

  &[data-disabled] {
    color: ${(props) => props.theme.palette.text.secondary};
    pointer-events: "none";
  }

  &[data-highlighted] {
    background-color: ${(props) => props.theme.palette.action.hover};
    color: ${(props) => props.theme.palette.text.primary};
  }

  .RightSlot {
    margin-left: auto;
    padding-left: 20px;
    color: ${(props) => props.theme.palette.text.primary};
  }
  [data-highlighted] > .RightSlot {
    color: ${(props) => props.theme.palette.text.primary};
  }
  [data-disabled] .RightSlot {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

const StyledSeparator = styled(RadixDropdownMenu.Separator)`
  height: 1px;
  background-color: ${(props) => props.theme.palette.divider};
  margin: 5px;
`;

const StyledLabel = styled(RadixDropdownMenu.Label)`
  font-size: 12px;
  line-height: 1;
  color: ${(props) => props.theme.palette.text.secondary};
  padding: 5px;
`;

const StyledRadioItem = styled(RadixDropdownMenu.RadioItem)`
  font-size: 12px;
  line-height: 1;
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 3px;
  display: flex;
  align-items: center;
  min-height: 25px;
  padding: 0 5px;
  padding-left: 25px;
  position: relative;
  user-select: none;
  outline: none;

  &[data-disabled] {
    color: ${(props) => props.theme.palette.text.secondary};
    pointer-events: "none";
  }

  &[data-highlighted] {
    background-color: ${(props) => props.theme.palette.action.hover};
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const StyledCheckboxItem = styled(RadixDropdownMenu.CheckboxItem)`
  font-size: 12px;
  line-height: 1;
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 3px;
  display: flex;
  align-items: center;
  min-height: 25px;
  padding: 0 5px;
  padding-left: 25px;
  position: relative;
  user-select: none;
  outline: none;

  &[data-disabled] {
    color: ${(props) => props.theme.palette.text.secondary};
    pointer-events: "none";
  }

  &[data-highlighted] {
    background-color: ${(props) => props.theme.palette.action.hover};
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const StyledItemIndicator = styled(RadixDropdownMenu.ItemIndicator)`
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const DropdownMenu = ({
  children,
  menuItems,
  arrow = false,
  variant = "outlined",
}) => {
  const handleSelect = (item) => {
    item?.onClick?.(item);
  };

  const Label = ({ item, placeholder }) => {
    if (item.component) {
      const Component = item.component;
      return <Component data={item} />;
    }
    if (item.render) {
      return item.render(item);
    }
    return <>{item?.label || placeholder}</>;
  };

  return (
    <RadixDropdownMenu.Root>
      <RadixDropdownMenu.Trigger asChild>
        <StyledButton variant={variant}>
          {children || "Menu"}
          {arrow && (
            <ArrowDropDownOutlinedIcon
              style={{ fontSize: 16, marginLeft: 5 }}
            />
          )}
        </StyledButton>
      </RadixDropdownMenu.Trigger>
      <RadixDropdownMenu.Portal>
        <StyledMenuContent sideOffset={2} align="start">
          {(menuItems || []).map((item, idx) => {
            if (item.separator) return <StyledSeparator key={idx} />;
            if (item.header)
              return (
                <StyledLabel key={idx}>
                  {item?.label || "Section Header"}
                </StyledLabel>
              );
            if (item.checkbox) {
              return (
                <StyledCheckboxItem
                  key={idx}
                  onSelect={() => handleSelect(item)}
                >
                  <StyledItemIndicator>{"✓"}</StyledItemIndicator>
                  <Label item={item} placeholder={"Menu Item"} />
                </StyledCheckboxItem>
              );
            }
            if (item.radioGroup) {
              return (
                <RadixDropdownMenu.RadioGroup key={idx} value={item?.value}>
                  {(item?.items || []).map((item, idx) => {
                    return (
                      <StyledRadioItem
                        key={idx}
                        value={item?.value}
                        onSelect={() => handleSelect(item)}
                      >
                        <StyledItemIndicator>{"✓"}</StyledItemIndicator>
                        <Label item={item} placeholder={"Menu Item"} />
                      </StyledRadioItem>
                    );
                  })}
                </RadixDropdownMenu.RadioGroup>
              );
            }
            if (item.items) {
              return (
                <RadixDropdownMenu.Sub key={idx}>
                  <StyledSubTrigger>
                    <Label item={item} placeholder={"Menu Item"} />
                    <div className="RightSlot">
                      <ChevronRightIcon size={16} />
                    </div>
                  </StyledSubTrigger>
                  <RadixDropdownMenu.Portal>
                    <StyledSubMenuContent sideOffset={2} alignOffset={-5}>
                      {(item?.items || []).map((item, subIdx) => {
                        if (item?.separator)
                          return <StyledSeparator key={subIdx} />;
                        if (item.header)
                          return (
                            <StyledLabel key={subIdx}>
                              {item?.label || "Section Header"}
                            </StyledLabel>
                          );
                        if (item.checkbox)
                          return (
                            <StyledCheckboxItem
                              key={subIdx}
                              checked={item?.checked}
                              onSelect={(e) => {
                                // e.preventDefault();
                                // e.stopPropagation();
                                handleSelect(item);
                              }}
                            >
                              <StyledItemIndicator>{"✓"}</StyledItemIndicator>
                              <Label item={item} placeholder={"Menu Item"} />
                            </StyledCheckboxItem>
                          );
                        if (item.radioGroup) {
                          return (
                            <RadixDropdownMenu.RadioGroup
                              key={idx}
                              value={item?.value}
                            >
                              {(item?.items || []).map((item, idx) => {
                                return (
                                  <StyledRadioItem
                                    key={idx}
                                    value={item?.value}
                                    onSelect={() => handleSelect(item)}
                                  >
                                    <StyledItemIndicator>
                                      {"✓"}
                                    </StyledItemIndicator>
                                    <Label
                                      item={item}
                                      placeholder={"Menu Item"}
                                    />
                                  </StyledRadioItem>
                                );
                              })}
                            </RadixDropdownMenu.RadioGroup>
                          );
                        }
                        return (
                          <StyledMenuItem
                            key={subIdx}
                            onSelect={() => handleSelect(item)}
                          >
                            <Label item={item} placeholder={"Sub Menu Item"} />
                          </StyledMenuItem>
                        );
                      })}
                    </StyledSubMenuContent>
                  </RadixDropdownMenu.Portal>
                </RadixDropdownMenu.Sub>
              );
            }
            return (
              <StyledMenuItem key={idx} onSelect={() => handleSelect(item)}>
                <Label item={item} placeholder={"Menu Item"} />
              </StyledMenuItem>
            );
          })}
        </StyledMenuContent>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  );
};

export default DropdownMenu;
