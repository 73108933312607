import { lazy, Suspense } from "react";
import styled from "styled-components";

const ReactPlayer = lazy(() => import("react-player"));

const MediaViewer = styled(({ className, file }) => {
  return (
    <div className={className}>
      <Suspense fallback={<div>Loading...</div>}>
        <ReactPlayer
          url={file.url}
          height={"85%"}
          width={"85%"}
          controls={true}
          playing={true}
        />
      </Suspense>
    </div>
  );
})`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: auto;
  overflow: auto;
  margin: 10vh auto;
`;

export default MediaViewer;
